import React, {Component} from 'react'
import api from '../../services/api'
import util from '../../classes/util'
import semImagem from '../../img/products/no_image.jpg'
import './styles.css'
import { requirePropFactory } from '@material-ui/core'
import {Link, useHistory} from 'react-router-dom'
import {CAMINHO_PROMOCOES} from '../../config.js'
import DeCron from '../decron'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faHandSpock } from '@fortawesome/fontawesome-free-solid'
import {faHatCowboy, faCheckSquare, faCoffee} from '@fortawesome/free-solid-svg-icons';

import moment from 'moment'

library.add(faCheckSquare)

const estadoInicial = {
    promocoes: [],
}

class Promocoes extends Component {
    
    state = {
        ...estadoInicial
    }

    componentDidMount = async () => {
        await this.carregaPromocoes()
    }
   
    carregaPromocoes = async () => {
        await api.get(`promocoes`, {
            }).then(response => {
            this.setState({promocoes: response.data})
            }
        )
    }

    carregarImagem = (imagem) => {

        const caminhoAbs = `${CAMINHO_PROMOCOES}${imagem}.png`
        if(caminhoAbs){
          //Alert.alert("Teste", `${caminhoAbs}`)
            return caminhoAbs
        }else{
            return 'http://192.168.15.150/severo/assets/produtos/sem_imagem.png'
        }  
    }

    render(){
        return (
            <section className="latest-products spad">
                 <div className="product-filter">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                <div className="section-title">
                                    <h1 className="text-center mb-2">Promoções</h1>
                                    
                        <nav id="filter_top" className="navbar navbar-expand-lg navbar-dark bg-darked py-2 mb-3">
                            <div className="container-fluid text-light">
                            <h5 className="mb-0 ficabranco"><i class="fas fa-filter"></i>Filtrar:</h5>
                            <ul className="navbar-nav me-auto ms-2 d-table-row">
                                <li className="nav-item dropdown d-table-cell">
                                <a className="nav-link dropdown-toggle ms-2 me-3" href="#" id="categoria" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fas fa-bookmark"></i> Categoria
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="categoria">
                                    <li><a className="dropdown-item" href="#">Carros</a></li>
                                    <li><a className="dropdown-item" href="#">Imóveis</a></li>
                                    <li><a className="dropdown-item" href="#">Gastronomia</a></li>
                                    <li><a className="dropdown-item" href="#">Eletrônicos</a></li>
                                </ul>
                                </li>
                                <li className="nav-item dropdown d-table-cell">
                                <a className="nav-link dropdown-toggle" href="#" id="ordenar" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fas fa-sort-amount-down"></i> Ordenar por
                                </a>
                                <ul className="dropdown-menu ordenar" aria-labelledby="ordenar">
                                    <li><a className="dropdown-item" href="#">Menor Preço</a></li>
                                    <li><a className="dropdown-item" href="#">Maior Preço</a></li>
                                    <li><a className="dropdown-item" href="#">Avaliação</a></li>
                                </ul>
                                </li>
                            </ul>
                            </div>
                        </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="container">
                   

            <div className="row">
                
          
                        {this.state.promocoes.map(promocao => (
                            <div key={promocao.chave} className="col-12 col-sm-6 col-md-4 col-lg-3 rounded mb-3">
                               <div className="text-align-center">
                                    <img className="img-fluid padronizar" src={util.urlPromocaoExists(promocao.url)} alt={promocao.descricao} />
                               </div>
                               <div className="bg-card p-3">
                                    <h5 style={{color: 'white'}}  className="pt-1">{promocao.descricao}</h5>
                                                
                                    <div className="d-flex flex-row mb-2">
                                        <div className="ratings mr-2"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fas fa-star-half-alt"></i></div><span>{promocao.quantidade}</span>
                                    </div>

                                    <div className="border-top mt-1">
                                        <div className="mt-2 d-flex flex-row align-items-center">
                                            <h4 style={{color: 'white'}} className="mr-1">{promocao.valor_promocional.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</h4>
                                        </div>
                                        <div className="d-flex flex-row align-items-center">
                                            <span className="strike-text">{promocao.valor_original.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} </span>
                                        </div>
                                        <h6 className="text-success mt-1"><i className="fas fa-hourglass-end"></i> Válido até {moment(promocao.fim).format('DD/MM/YYYY HH:mm')}</h6>
                                        <div className="d-flex flex-column mt-4">
                                            <button className="btn btn-primary btn-sm" type="button">Detalhes</button>
                                            <button className="btn btn-outline-primary btn-sm mt-2 mb-2" type="button">Adicionar ao Carrinho</button>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>                               
                        ))} 
                    </div>    
                </div>
            </section> 
        )
    }
}

/*
const mapStateToProps = ({ empresa }) => {
    return {
        //total: produtos.totalProdutos,
        empresa: empresa.empresa
    }
}

<FontAwesome
					className="super-crazy-colors"
					name="rocket" />

<div className="product-filter">
                        <div className="row">
                            <OwlCarousel
                                className="hero-slider"
                                margin={20}
                                nav
                                items={2}
                            >
                                {this.state.promocoes.map(promocao => (
                                    <div key={promocao.chave} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center">
                                        <h2>{promocao.descricao}</h2> 
                                        <DeCron qtd={promocao.quantidade} fim={promocao.fim}/>                        
                                    </div>
                                ))} 
                            </OwlCarousel>      
                        </div>
                    </div>

                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                            <img className="img-fluid d-block w-100" src={require('../../img/add.jpg')} alt="First slide" />
                            </div>
                            <div className="carousel-item">
                            <img className="img-fluid d-block w-100" src={require('../../img/anamodas.jpg')} alt="Second slide" />
                            </div>
                            <div className="carousel-item">
                            <img className="img-fluid d-block w-100" src={require('../../img/f-box-1.jpg')} alt="Third slide" />
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Anterior</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Próximo</span>
                        </a>
                    </div>

                    <section className="comBorda">
                               
                            
                                <h2 style={{textAlign: "center"}}>{promocao.descricao}</h2>
                                <div className="row" style={{marginTop: 10, marginBottom: 10, backgroundColor: '#eee'}}>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                        <FontAwesomeIcon 
                                            icon={faClock} 
                                            size="3x"
                                            color="blue"                  
                                        />
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                                        <DeCron fim={promocao.fim}/>
                                    </div>
                                </div>
                                <div style={{backgroundColor: '#11e'}}>
                                    <p className="fonteBranca">De: {promocao.valor_original.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} Por: {promocao.valor_promocional.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} Desconto de {parseInt(100 - (100 * (promocao.valor_promocional ? promocao.valor_promocional : 0.1) / (promocao.valor_original ? promocao.valor_original : 0.1)))}%</p>
                                    <p className="fonteBranca">Ainda restam {promocao.quantidade} unidades</p>
                                </div>
                                </section>
*/
export default Promocoes
