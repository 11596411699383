import React, {Component} from 'react'
import api from '../../services/api'
import HeaderLoja from '../../components/headerLoja'
import Rodape from '../../components/rodape'
import './styles.css'
import {connect} from 'react-redux'
import Logo from '../../img/logos/acervo_08.png'

const estadoInicial = {
    promocoes: [],
    produtos: [],
    empresa: null
}

class EmBreve extends Component {

    state = {
        ...estadoInicial
    }

    componentWillMount = async () => {
        await this.carregaEmpresa()
    }
    componentDidMount = async () => {
        //await this.carregaEmpresa()
    }

    carregaEmpresa = async () => {
       await this.setState({empresa: this.props.match.params.id})
       //alert(this.state.empresa)
    }

    passaEmpresa = () => {
        //alert(this.state.empresa)
        var e = this.state.empresa
        //alert(e)
        return e
        
    }

    carregaProdutos = async () => {
        const empresa = this.state.empresa ? this.state.empresa : 0
        let url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/10000/0` : `produtos/${empresa}/10000/0`
        await api.get(url, {
        }).then(resp => {
            this.setState({tamanho: resp.data.length})
            this.carregaPaginas()
        })
        url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/${this.state.porPagina}/${this.state.paginacao}` : `produtos/${empresa}/${this.state.porPagina}/${this.state.paginacao}`
        await api.get(url, {
            }).then(response => {
            this.setState({produtos: []})    
            this.setState({produtos: response.data})
            }
        )
    }

    render(){
        return (
            <div className='bghome'>
                <HeaderLoja />
                <div className="text-center">
                    <h1 style={{marginBottom: 100}}>Em Breve</h1>
                    <img src={Logo} />
                </div>
                <Rodape/>
            </div>
        )
    }
}

const mapStateToProps = ({ empresa }) => {
    return {
        //total: produtos.totalProdutos,
        empresa: empresa.empresa
    }
}

export default connect(mapStateToProps, null)(EmBreve)

