import React, {Component} from 'react'
import './styles.css'
import api from '../../services/api'
import Header from '../header'
import HeaderLoja from '../headerLoja'
import Rodape from '../rodape'
import axios from 'axios'
import {server, showError} from '../../common'
import util from '../../classes/util'
import CEP from 'cep-promise'
import {Formik, Field, Form} from 'formik' 
import {connect} from 'react-redux'
import moment from 'moment'
import {mensagemErro} from '../../styles/estilos'
import ComponentSkeleton from '../skeleton'
import { Redirect } from 'react-router-dom'

const initialValues = {
    nome: '',
    login: '',
    senha: '',
    confirmaSenha: '',
    cnpjcpf: '',
    endereco: '',
    cep: '',
    bairro: '',
    complemento: '',
    numero: '',
    cidade: '',
    uf: '',
    codigo: null
}
class Cadastro extends Component {
    

    state = {
        ...initialValues,
        produtos: [],
        teste: 1234,
        empresa: [],
        enderecoSite: '',
        numeroSite: 0,
        cidadeSite: '',
        ufSite: '',
        foneSite: '',
        emailSite: '',
        pessoa: null,
        pessoaEndereco: null,
        atualizaCadastro: false,
        podeCadastrar: true,
        renderLoading: false,
        redirect: false
    }

    async componentDidMount(){
        window.scrollTo(0, 0)
        await this.carregaControle()
        await this.setState({enderecoSite: this.state.empresa[0].Endereco})
        await this.setState({numeroSite: this.state.empresa[0].Numero})
        await this.setState({cidadeSite: this.state.empresa[0].Cidade})
        await this.setState({ufSite: this.state.empresa[0].UF})
        await this.setState({foneSite: this.state.empresa[0].Fone})
        //await this.setState({redirect: true})
    }

    carregaControle = async () => {
        try{
            const res = await axios.get(`${server}/empresas`)
            await this.setState({empresa: res.data})
        }catch(e){
            showError(e)
        }
        
    }

    teste = () => {
        const teste = 'Teste categoria'
        return teste
    }

    buscarCpf = async () => {
        const res = await axios.post(`${server}/buscacpf`, {
            cpf: this.state.cnpjcpf
        })
        console.log(JSON.stringify(res.data))
        if(res.data.Codigo){
            if(res.data.login && res.data.login != ''){
                alert('Usuário já cadastrado no site!')
                await this.setState({podeCadastrar: false})
            }else{
                await this.setState({
                    pessoa: res.data.Chave,
                    pessoaEndereco: res.data.chave_endereco,
                    nome: res.data.Nome,
                    cep: res.data.cep,
                    endereco: res.data.endereco,
                    cidade: res.data.cidade_descricao,
                    numero: res.data.numero,
                    atualizaCadastro: true,
                    podeCadastrar: true
                })
                
            }
            
        }
    }

    signup = async () => { 
        try{
            await this.setState({renderLoading: true})
            if(!this.state.podeCadastrar){
                alert("Falha no cadastro, já existe um cadastro com este login ou cpf/cnpj!")
            }else{
                if(this.state.atualizaCadastro){
                    await axios.put(`${server}/atualizacadastro`, {
                        pessoa: this.state.pessoa,
                        pessoaEndereco: this.state.pessoaEndereco,
                        Nome: this.state.nome,
                        login: this.state.login,
                        Cnpj_Cpf: this.state.cnpjcpf,
                        senha: this.state.senha,
                        confirmPassword: this.state.confirmaSenha,    
                        endereco: this.state.endereco,
                        numero: this.state.numero,
                        complemento: this.state.complemento,
                        cidade: this.state.cidade,
                        uf: this.state.uf,
                        bairro: this.state.bairro,
                        cep: this.state.cep
                    })
                   // alert('Entrou aqui')
                    await axios.post(`${server}/email`, {
                        email: this.state.login,
                        usuario: this.state.login,
                        senha: this.state.senha
                    })
                    alert('Usuário cadastrado!')
                    this.setState({...initialValues, redirect: true})
        
                }else{
                    //await this.setState({codigo: proximo})
                    await axios.post(`${server}/signup`, {
                        Nome: this.state.nome,
                        login: this.state.login,
                        Cnpj_Cpf: this.state.cnpjcpf,
                        senha: this.state.senha,
                        confirmPassword: this.state.confirmaSenha,    
                        endereco: this.state.endereco,
                        numero: this.state.numero,
                        complemento: this.state.complemento,
                        cidade: this.state.cidade,
                        uf: this.state.uf,
                        bairro: this.state.bairro,
                        cep: this.state.cep,
                        inclusao: moment().format()
                    })
                    const resPes = await axios.get(`${server}/maxpessoa`)
                    let proximo = resPes.data.chave
                    await axios.put(`${server}/signup`, {
                        codigo: proximo,
                        chave: proximo
                    })
                    await axios.post(`${server}/email`, {
                        email: this.state.login,
                        usuario: this.state.login,
                        senha: this.state.senha
                    })
                    alert('Usuário cadastrado!')
                    this.setState({...initialValues, redirect: true})
                    
                }
            }
            await this.setState({renderLoading: false})
        }catch(e){
            showError(e)
        }
    }

    buscarDados = async (cep) => {
        try{  
            var obj = await CEP(cep)
            var bairro = obj.neighborhood
            var endereco = obj.street
            var cidade = obj.city
            var uf = obj.state
            await this.setState({bairro, endereco, cidade, uf})
          }catch(e){
            return alert("CEP não encontrado")
          }
        
    }

    
    enviarDados = async () => {
        alert("Entrou aqui")
    }
    
    render(){

        const validations = []
        
        validations.push(this.state.login && this.state.login.includes('@'))
        validations.push(this.state.nome && this.state.nome.trim().length >= 3)
        validations.push(this.state.senha && this.state.senha.length >= 6)
        validations.push(this.state.senha === this.state.confirmaSenha)
        validations.push(this.state.cnpjcpf && util.testaCPF(this.state.cnpjcpf))
        validations.push(this.state.cep && this.state.cep.trim().length == 8)
        validations.push(this.state.cidade && this.state.cidade.trim().length >= 3)
        validations.push(this.state.endereco && this.state.endereco.trim().length >= 4)
        validations.push(this.state.uf && this.state.uf.trim().length === 2)
        validations.push(this.state.bairro && this.state.bairro.trim().length >= 2)
        validations.push(this.state.numero && toString(this.state.numero).trim().length >= 0)

        //o formulário só será válido se todas as validações forem verdadeiras, com este reduce implementado
        const validForm = validations.reduce((t, a) => t && a)

        const validationsCpf = []
        validationsCpf.push(this.state.cnpjcpf && util.testaCPF(this.state.cnpjcpf))
        const validFormCpf = validationsCpf.reduce((t, a) => t && a)

        const validationsNome = []
        validationsNome.push(this.state.nome && this.state.nome.trim().length >= 3)
        const validFormNome = validationsNome.reduce((t, a) => t && a)

        const validationsSenha = []
        validationsSenha.push(this.state.senha && this.state.senha.length >= 6)
        const validFormSenha = validationsSenha.reduce((t, a) => t && a)
        
        const validationsSenhasIguais = []
        validationsSenhasIguais.push(this.state.senha === this.state.confirmaSenha)
        const validFormSenhasIguais = validationsSenhasIguais.reduce((t, a) => t && a)
        
        const validationsLogin = []
        validationsLogin.push(this.state.login && this.state.login.includes('@'))       
        const validFormLogin = validationsLogin.reduce((t, a) => t && a)
        
        const validationsCep = []
        validationsCep.push(this.state.cep && this.state.cep.trim().length == 8)
        const validFormCep = validationsCep.reduce((t, a) => t && a)

        const validationsCidade = []
        validationsCidade.push(this.state.cidade && this.state.cidade.trim().length >= 3)
        const validFormCidade = validationsCidade.reduce((t, a) => t && a)
        
        const validationsEndereco = []
        validationsEndereco.push(this.state.endereco && this.state.endereco.trim().length >= 4)
        const validFormEndereco = validationsEndereco.reduce((t, a) => t && a)
        
        const validationsNumero = []
        validationsNumero.push(this.state.numero && toString(this.state.numero).trim().length >= 0)
        const validFormNumero = validationsNumero.reduce((t, a) => t && a)
        
        const validationsUF = []
        validationsUF.push(this.state.uf && this.state.uf.trim().length === 2)
        const validFormUF = validationsUF.reduce((t, a) => t && a)
        
        const validationsBairro = []
        validationsBairro.push(this.state.bairro && this.state.bairro.trim().length >= 2)
        const validFormBairro = validationsBairro.reduce((t, a) => t && a)
        
        return(
            <div>
                <HeaderLoja />
                {this.state.redirect &&
                    <Redirect to={{pathname: '/lojas'}} />
                }
                <section className="page-add">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="page-breadcrumb">
                                    <h2>Cadastre-se<span>.</span></h2>
                                    <a href="#">Início</a>
                                    <a href="#">Cadastro</a>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <img src="img/add.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.renderLoading && <ComponentSkeleton />}
                {!this.state.renderLoading &&
                <div className="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <Formik 
                                initialValues={{
                                    nome: '',
                                    login: '',
                                    senha: '',
                                    confirmaSenha: '',
                                    cnpjcpf: '',
                                    endereco: '',
                                    cep: '',
                                    bairro: '',
                                    complemento: '',
                                    numero: '',
                                    cidade: '',
                                    uf: ''

                                }}
                                onSubmit={async values => {
                                    await new Promise(r => setTimeout(r, 500))
                                    this.signup()
                                }}
                                >
                                    <Form className="contact-form">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>
                                                <Field 
                                                    className="form-control" 
                                                    value={this.state.cnpjcpf} 
                                                    onChange={e => { this.setState({cnpjcpf: e.currentTarget.value})}} 
                                                    onBlur={() => this.buscarCpf()}
                                                    id="cnpjcpf" 
                                                    name="cnpjcpf" 
                                                    type="text" 
                                                    placeholder="CPF/CNPJ" 
                                                />
                                                <span style={mensagemErro} className={!validFormCpf ? "error": "d-none"}>Digite um CPF válido!</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field 
                                                    className="form-control" 
                                                    value={this.state.nome} 
                                                    onChange={e => { this.setState({nome: e.currentTarget.value})}} 
                                                    id="nome" 
                                                    name="nome" 
                                                    type="text" 
                                                    placeholder="Nome"
                                                />
                                                <span style={mensagemErro} className={!validFormNome ? "error": "d-none"}>Insira o seu nome!</span>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field 
                                                    className="form-control" 
                                                    value={this.state.senha}
                                                    onChange={e => { this.setState({senha: e.currentTarget.value})}} 
                                                    id="senha" 
                                                    name="senha" 
                                                    type="password" 
                                                    placeholder="Senha" 
                                                />
                                                <span style={mensagemErro} className={!validFormSenha ? "error": "d-none"}>Sua senha deve conter pelo menos 6 caracteres</span>

                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field className="form-control" value={this.state.confirmaSenha} onChange={e => { this.setState({confirmaSenha: e.currentTarget.value})}} id="confirmasenha" name="confirmasenha" type="password" placeholder="Confirma senha" />
                                                <span style={mensagemErro} className={!validFormSenhasIguais ? "error": "d-none"}>As senhas não conferem!</span>

                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field 
                                                    className="form-control" 
                                                    value={this.state.login} 
                                                    name="login" 
                                                    id="login" 
                                                    type="email" 
                                                    placeholder="E-mail" 
                                                    onChange={e => { this.setState({login: e.currentTarget.value})}}
                                                />
                                                <span style={mensagemErro} className={!validFormLogin ? "error": "d-none"}>Digite um email válido!</span>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field className="form-control" value={this.state.cep} onChange={e => {this.setState({cep: e.currentTarget.value})}} name="cep" id="cep" type="text" placeholder="CEP" onBlur={e => {
                                                    this.buscarDados(e.currentTarget.value)
                                                    }
                                                }/>
                                                <span style={mensagemErro} className={!validFormCep? "error": "d-none"}>Digite um CEP válido!</span>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field className="form-control" value={this.state.endereco} onChange={e => { this.setState({endereco: e.currentTarget.value})}} name="endereco" id="endereco" type="text" placeholder="Logradouro" />
                                                <span style={mensagemErro} className={!validFormEndereco? "error": "d-none"}>Digite o seu endereço!</span>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field 
                                                    className="form-control"
                                                    value={this.state.numero} 
                                                    name="numero" 
                                                    id="numero" 
                                                    type="tel" 
                                                    placeholder="Número" 
                                                    onChange={e => { 
                                                        this.setState({numero: e.currentTarget.value})}
                                                    }
                                                />
                                                <span style={mensagemErro} className={!validFormNumero? "error": "d-none"}>Insira o número da residência(caso não tenha, digite 0)!</span>

                                            </div>

                                            <Field className="form-control" value={this.state.complemento} name="complemento" id="complemento" type="text" placeholder="Complemento" onChange={e => { this.setState({complemento: e.currentTarget.value})}}/>
                                            
                                        </div>

                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field className="form-control" value={this.state.bairro} name="bairro" id="bairro" type="text" placeholder="Bairro" onChange={e => { this.setState({bairro: e.currentTarget.value})}}/>
                                                <span style={mensagemErro} className={!validFormBairro? "error": "d-none"}>Informe o bairro!</span>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>



                                                <Field className="form-control" value={this.state.cidade} name="cidade" id="cidade" type="text" placeholder="Cidade" onChange={e => { this.setState({cidade: e.currentTarget.value})}}/>
                                                <span style={mensagemErro} className={!validFormCidade? "error": "d-none"}>Informne a cidade!</span>

                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12">
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column',  marginBottom: 20, marginTop:20}}>

                                                <Field className="form-control" value={this.state.uf} name="uf" id="uf" type="text" placeholder="UF" onChange={e => { this.setState({uf: e.currentTarget.value})}}/>
                                                <span style={mensagemErro} className={!validFormUF? "error": "d-none"}>Informe a UF!</span>

                                           </div>
                                        </div>
                                        <div className="col-lg-12 text-right">
                                            <button disabled={!validForm} type="submit" style={validForm ? {} : {backgroundColor: '#eee', opacity: 0.3} } >Cadastrar</button>
                                        </div>
                                    </div>
                                    </Form>
                                </Formik>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="contact-widget">
                                    <div className="cw-item">
                                        <h5>Localização</h5>
                                        <ul>
                                            <li>{this.props.nome}</li>
                                            <li>{this.state.enderecoSite}, {this.state.numeroSite}</li>
                                            <li>{this.state.cidadeSite}, {this.state.ufSite}</li>
                                        </ul>
                                    </div>
                                    <div className="cw-item">
                                        <h5>Telefone</h5>
                                        <ul>
                                            <li>{this.state.foneSite}</li>
                                            <li>+55 53 98125-7786</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <Rodape />
            </div>
            )
        }
}

const mapStateToProps = ({user}) => {
    return{
        nome: user.name
    }
}
export default connect(mapStateToProps, null)(Cadastro)
//export default Cadastro

/*
                                    <div className="cw-item">
                                        <h5>E-mail</h5>
                                        <ul>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                        */