import React, {Component} from 'react'
import './styles.css'
import api from '../../services/api'
import Header from '../../components/header'
import Skeleton from '../../components/skeleton'
import {Link, useHistory} from 'react-router-dom'

const estadoInicial = {
    name: '',
    promocoes: [],
    loading: true,
    pesquisa: ''
}

class Promocoes extends Component {
    
    state = {
        ...estadoInicial
    }

    componentDidMount = () => {
        this.getPromocoes()
    }

    /*
    componentDidUpdate = async (prevProps, prevState) => {
        if(this.state.pesquisa !== prevState.pesquisa){ 
            await this.getFamiliasPesquisa(this.state.pesquisa)
        }
    }
    */

    getFamiliasPesquisa = async (pesquisa) => {
        await this.setState({familias: []})
        await api.get(`familiasadmin/${pesquisa}`, {
        }).then(response => {
            this.setState({familias: response.data})
        })
    }

    getPromocoes = async () => {
        await api.get(`promocoesadmin`, {
        }).then(response => {
            this.setState({promocoes: response.data})
        })
        this.setState({loading: false})
    }

    render(){
        
        return (
            <div>
                
                {this.state.loading && 
                    <Skeleton />
                }
                {!this.state.loading &&
                <div>
                    <section className="page-add">
                        <Header />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="page-breadcrumb">
                                        <h2 style={{marginBottom: '3%', marginTop: '3%', textAlign: 'center'}}>Promoções</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="contact-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-right">
                                    <input type="text" className="form-control" placeholder="pesquise aqui" value={this.state.pesquisa} name="pesquisa" id="pesquisa" onChange = {e => { this.setState({pesquisa: e.currentTarget.value})}} />
                                </div>  
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-right">
                                    <Link to={{pathname: `/admin/addpromocao/0`}}><button className="btn btn-success" > Adicionar</button></Link>
                                </div>                            
                            </div>
                        </div>
                    </div>

                    <div className="row" id="product-list">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mix all dresses bags">
                            <div className="single-product-item">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center">
                                        <h5>Código</h5>   
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center">
                                        <h5>Descrição</h5>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center">
                                        <h5>Quantidade</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="product-list">
                            {this.state.promocoes.map(familia => (
                                <div key={familia.chave} className="col-lg-12 col-md-12 col-sm-12 mix all dresses bags">
                                    <div className="single-product-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-4 text-center">
                                                <Link to={{pathname: `/admin/addpromocao/${familia.chave}`, state: familia.chave}}>
                                                    <p>{familia.chave}</p>
                                                </Link>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 text-center">
                                                <h6>{familia.descricao}</h6>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 text-center">
                                                <h6>{familia.quantidade}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                }                
                
            </div>
        )
        
    }
}

export default Promocoes

/*
                {this.state.loading &&
                    <Skeleton />
                }
                {!this.state.loading &&
                <div>
*/
