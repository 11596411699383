import React, { Component } from 'react'
import './styles.css'
import api from '../../services/api'

import HeaderLoja from '../../components/headerLoja'
import Header from '../../components/header'
import Rodape from '../../components/rodape'
import axios from 'axios'
import { CAMINHO_FOTOS } from '../../config'
import { server, showError } from '../../common'
import Util from '../../classes/util'
import CEP from 'cep-promise'
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, Field, Form } from 'formik'
import ReactLoading from 'react-loading';
import { connect } from 'react-redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import e from 'cors'
import ModalAlert from '../../components/ModalAlert'


const initialValues = {
    senha: '',
    confirmaSenha: '',
}
class ResetPassword extends Component {


    state = {
        ...initialValues,
        alertAberto: false,
        textoErro: "",
        redireciona: false,
        token: "",
    }

    componentDidMount = async () => {
        await this.setState({ token: this.props.match.params.token });
        this.validarToken();
    }

    validarToken = async () => {
        const currentTime = new Date().getTime();
        const tokenTime = this.state.token.substring(60);

        try {
            const res = await api.post(`/validaToken`, {
                token: this.state.token
            })
            if (!res.data) {
                this.openAlert("Sua chave de acesso é inválida!");
                setTimeout(() => this.setState({ redireciona: true }), 3000)
            }

        } catch (e) {
            //console.log(e.response)
        }

        if (currentTime > tokenTime) {
            this.openAlert("Sua chave de acesso expirou!");
            setTimeout(() => this.setState({ redireciona: true }), 3000)
        }
    }

    trocarsenha = async () => {
        api.post('/novaSenha', {
            senha: this.state.senha,
            token: this.state.token
        }).then((res) => {
            this.openAlert(JSON.stringify(res.data))
            this.openAlert('Alteração de senha realizada com sucesso')
            setTimeout(() => this.setState({ redireciona: true }), 3000)
        })
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    redirecionar = async () => {
        await this.setState({ redireciona: false })
    }

    render() {


        const validations = []



        validations.push(this.state.senha == this.state.confirmaSenha)

        validations.push(this.state.senha.trim().length > 5)

        const validForm = validations.reduce((t, a) => t && a)

        return (


            < div className='recSenhaMestre'>
                {this.state.redirect && <Redirect to={'/'} />}
                < HeaderLoja />
                <div className='recSenha'>

                    <div>
                        <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                    </div>
                    <div className='titulo'>
                        <p>Alterar sua senha:</p>
                    </div>
                    <div className='RecSenhacasedadosusuario'>
                        <span>Nova Senha:</span>
                        <input type='password' onChange={async (e) => await this.setState({ senha: e.target.value })} value={this.state.senha} />
                    </div>
                    <div className='RecSenhacasedadosusuario'>
                        <span>Confirmar Senha:</span>
                        <input type='password' onChange={async (e) => await this.setState({ confirmaSenha: e.target.value })} value={this.state.confirmaSenha} />
                    </div>
                    <div className={validForm ? 'butaotrocarsenha' : 'butaotrocarsenhad'} onClick={validForm ? () => this.trocarsenha() : () => { }} > Confirmar troca de senha</div>
                    <div className='col-0 col-md-1 col-lg-1' />
                </div>
                {this.state.redireciona &&
                    <Redirect to='http://ciapecaspel.com.br' />
                }
                <div className='rodape'>
                    <Rodape />

                </div>
            </div >


        )
    }
}

const mapStateToProps = ({ user }) => {
    return {
        nome: user.name,
        chave: user.chave
    }
}
export default connect(mapStateToProps, null)(ResetPassword)

//<textarea placeholder="Menssagem"></textarea>
