var Cartao = function(cardholder_name) {
    this.number_token = ''
    this.brand = 'Mastercard'
    this.cardholder_name = cardholder_name
    this.expiration_month = 12
    this.expiration_year = 20
    this.security_code = 123
    console.log(`Bem vindo, ${cardholder_name}`)
}

Cartao.prototype.setNumberToken = function(number_token){
    this.number_token = number_token
}

Cartao.prototype.getNumberToken = function(){
    return this.number_token
}

Cartao.prototype.setBrand = function(brand){
    this.brand = brand
}

Cartao.prototype.getBrand = function(){
    return this.brand
}

Cartao.prototype.setCardholderName = function(cardholder_name){
    this.cardholder_name = cardholder_name
}

Cartao.prototype.getCardholderName = function(){
    return this.cardholder_name
}

Cartao.prototype.setMonth = function(month){
    this.expiration_month = month
}

Cartao.prototype.getMonth = function(){
    return this.expiration_month
}

Cartao.prototype.setYear = function(year){
    this.expiration_year = year
}

Cartao.prototype.getYear = function(){
    return this.expiration_year
}

Cartao.prototype.setSecurityCode = function(code){
    this.security_code = code
}

Cartao.prototype.getSecurityCode = function(){
    return this.security_code
}

export default Cartao
