import axios from 'axios'

const apigetneth = axios.create({
    baseURL: 'https://api-homologacao.getnet.com.br/',
    headers:  {
        "Content-Type": `application/x-www-form-urlencoded`,
        "Authorization": 'Basic Y2xpZW50LTAxOnNlY3JldC1rZXktMDI=' 
    },
    timeout: 5000 
})

export default apigetneth