import React, {Component} from 'react'
import './styles.css'
import {Formik, Field, Form, isInteger} from 'formik'
import Logo from '../../img/logos/ea.png'
import api from '../../services/api'
import util from '../../classes/util'
import InputMask from 'react-input-mask'
import Skeleton from '../../components/skeleton'
import Header from '../../components/header'
import moment from 'moment'

const estadoInicial = {
    fotos: [],
    chave: null,
    chave_produto: 0,
    codigo: null,
    descricao: '',
    quantidade: null,
    valor_original: 0,
    valor_promocional: 0,
    inicio: null,
    fim: null,
    produto_web: false,
    empresa: 0,
    id: null,
    loading: true,
    empresas: []
}

class AddPromocao extends Component {
    
    state = {
        ...estadoInicial
    }

    componentWillMount = async () => {
        window.scrollTo(0, 0)
        var id = this.props.match.params.id
        await this.setState({id})
        await this.loadData(id)
        await this.carregaEmpresas()
        await this.setState({loading: false})
    }

    carregaEmpresas = async () => {
        await api.get(`empresas`, {
        }).then(response => {
            this.setState({empresas: response.data})
        })
        
    }

    loadData = async (produto) => {
        if(produto > 0){
            try{
                await api.get(`promocoes/${produto}`, {
                }).then(async res => {
                    await this.setState({chave: res.data.chave, chave_produto: res.data.chave_produto, descricao: res.data.descricao, url: res.data.url, quantidade: res.data.quantidade, valor_original: res.data.valor_original.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}), valor_promocional: res.data.valor_promocional.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}), inicio: moment(res.data.inicio).format('yyyy-MM-DDTh:mm'), fim: moment(res.data.fim).format('yyyy-MM-DDTh:mm')})
                })
            }catch(e){
                alert(e)
            }
            await this.setState({inicio: this.state.inicio})
        }
    }

    inserirPromocao = async () => {
        
        if(this.state.id == 0){
            try{
                await api.post(`promocoes`, {
                    descricao: this.state.descricao,
                    inicio: this.state.inicio,
                    fim: this.state.fim,
                    quantidade: this.state.quantidade,
                    valor_original: parseFloat(this.state.valor_original),
                    valor_promocional: parseFloat(this.state.valor_promocional),
                    chave_produto: this.state.chave_produto,  
                }).then(async response => {
                    if(response.status === 204){
                        await api.get(`promocoesmax`, {
                        }).then(async res => {
                            let file = new FormData()
                            await this.setState({chave: res.data.chave})
                            file.append('chave', util.completarZerosEsquerda(res.data.chave, 8))
                            if(this.state.fotos.length > 0){
                                this.state.fotos.map(picture => (
                                    file.append('file', picture)
                                ))
                                api.post(`promocaofile`, file, {
                                    headers: {
                                        "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
                                    }
                                }).then(response => {
                                    if(response.status === 200){
                                        api.put(`promocoes/${this.state.chave}`, {
                                            url: '/PR'+util.completarZerosEsquerda(this.state.chave, 8),
                                        }).then(response => {
                                            if(response.status === 204){
                                                this.setState({fotos: []})
                                                alert('Promoção cadastrada corretamente com foto!')
                                            }else{
                                                alert('erro no cadastro!')
                                            }
                                        })
                                    }else{
                                        alert('erro no cadastro!')
                                    }
                                })
                            }else{
                                alert('Promoção cadastrada corretamente Sem foto!')
                            }
                        })
                        
                    }else{
                        alert("Erro no cadastro! Tente novamente!")
                    }
                })
            }catch(e){
                alert(e)
            }
        }else{
            /*
            let file = new FormData()
            let outroFile = new FormData()
            outroFile.append('chave', util.completarZerosEsquerda(this.state.chave, 8))
            file.append('chave', util.completarZerosEsquerda(this.state.chave, 8))
            this.state.fotos.map(picture => (
                file.append('file', picture)
            ))
            */
            
            try{
                api.put(`promocoes/${this.state.id}`, {
                    descricao: this.state.descricao,
                    inicio: this.state.inicio,
                    fim: this.state.fim,
                    quantidade: this.state.quantidade,
                    valor_original: parseFloat(this.state.valor_original),
                    valor_promocional: parseFloat(this.state.valor_promocional),
                    chave_produto: this.state.chave_produto 
                }).then(response => {
                    if(response.status === 204){
                        this.setState({pictures: []})
                        alert('Promoção atualizada!')
                    }else{
                        alert('erro')
                    }
                })    
            }catch(e){
                alert(e)
            }
            
        }
        
    }

    fileSelectedHandler = async event => {
        //console.log(event.target.files[0])
        await this.setState({fotos: [...this.state.fotos, event.target.files[0]]})
        console.log(this.state.fotos)
    }


    render(){
        const validations = []
        validations.push(this.state.descricao && this.state.descricao.trim().length >= 5)
        validations.push(this.state.quantidade && this.state.quantidade >= 1)
        validations.push(this.state.valor_original)
        //validations.push(this.state.valor_promocional && this.state.valor_promocional.trim().length > 0)
        validations.push(this.state.valor_original && parseFloat(this.state.valor_original) > parseFloat(this.state.valor_promocional))
        validations.push(this.state.inicio)
        validations.push(this.state.fim)
        //o formulário só será válido se todas as validações forem verdadeiras, com este reduce implementado
        const validForm = validations.reduce((t, a) => t && a)
        
        return (
            <div>

                {this.state.loading &&
                    <Skeleton />
                }

                {!this.state.loading &&
                <div>
                <section className="page-add">
                    
                    <Header />
                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-breadcrumb">
                                    <h2 style={{marginBottom: '3%', marginTop: '3%', textAlign: 'center'}}>{this.state.id == 0 ? 'Inserir promoção' : 'Alterar promoção'}</h2>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <div className="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik 
                                initialValues={{
                                    //codigo: null,
                                    //descricao: '',
                                    //id_seaport: null,
                                    //produto_web: false,
                                    //empresa: null,
                                    //status: '',
                                }}
                                onSubmit={async values => {
                                    await new Promise(r => setTimeout(r, 2000))
                                    this.inserirPromocao()
                                }}
                                >
                                    <Form className="contact-form">
                                     
                                        {this.state.id != 0 &&
                                            <div className="row">
                                            <div className="col-xl-1 col-lg-1 col-md-2 col-sm-12 col-12 text-left">
                                                <label htmlFor="codigo">Código: </label>
                                            </div>
                                            <div className="col-xl-11 col-lg-11 col-md-10 col-sm-12 col-12 text-left">
                                                <Field disabled value={this.state.chave} onChange={e => { this.setState({chave: e.currentTarget.value})}} id="chave" name="chave" type="text" placeholder="Código" />
                                            </div>
                                            </div>
                                        }
                                        <div className="row">

                                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12 text-left">
                                            <label htmlFor="codigo">Código do produto (se houver) </label>
                                        </div>
                                        <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12 col-12 text-left">
                                            <Field value={this.state.chave_produto} onChange={e => { this.setState({chave_produto: e.currentTarget.value})}} id="chave_produto" name="chave_produto" type="text" placeholder="Código do produto (se houver)" />
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-12 text-left">
                                            <label htmlFor="descricao">Nome: </label>
                                        </div>
                                        <div className="col-xl-11 col-lg-11 col-md-10 col-sm-9 col-12 text-left">
                                            <Field type="text" name="descricao" id="descricao" value={this.state.descricao} onChange={async e => { await this.setState({descricao: e.currentTarget.value})}} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-12 text-left">
                                            <label htmlFor="preco_venda">Quantidade: </label>
                                        </div>  
                                        <div className="col-xl-11 col-lg-11 col-md-10 col-sm-9 col-12 text-left">
                                            <Field onChange={e => { this.setState({quantidade: e.currentTarget.value})}} value={this.state.quantidade} id="qunatidade" name="quantidade" type="number" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 text-left">
                                            <label htmlFor="valor_original">Valor Original</label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-4 col-sm-9 col-9 text-left">
                                            <InputMask mask="999.99" onChange={e => { this.setState({valor_original: e.currentTarget.value})}} value={this.state.valor_original} id="valor_original" name="valor_original" type="text" />
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 text-left">
                                            <label htmlFor="valor_promocional">Valor Promocional </label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-4 col-sm-9 col-9 text-left">
                                            <InputMask mask="99.99" onChange={e => { this.setState({valor_promocional: e.currentTarget.value})}} value={this.state.valor_promocional} id="valor_promocional" name="valor_promocional" type="text" />
                                        </div>
                                    </div>     

                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 text-left">
                                            <label htmlFor="seaport_destiny">Início</label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-4 col-sm-9 col-9 text-left">
                                            <Field type="datetime-local" name="inicio" id="inicio" value={this.state.inicio} onChange={async e => { await this.setState({inicio: e.currentTarget.value}); console.log(this.state.inicio)}}>
                                            </Field>
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 text-left">
                                            <label htmlFor="seaport_destiny">Fim: </label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-4 col-sm-9 col-9 text-left">
                                            <Field type="datetime-local" name="fim" id="fim" value={this.state.fim} onChange={e => { this.setState({fim: e.currentTarget.value})}}>
                                            </Field>
                                        </div>
                                    </div>                                   
                                    
                                    <div className="row">
                                        
                                        <input type="file" name="file" className="form-control-lg" accept="image/png" onChange={this.fileSelectedHandler}/>
                                        <div className="col-lg-12 text-right">
                                            <button disabled={!validForm} type="submit" style={validForm ? {} : {backgroundColor: '#eee', opacity: 0.3} } >Salvar</button>
                                        </div>

                                    </div>

                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            </div>
        )
    }
}

export default AddPromocao
