import { requirePropFactory } from '@material-ui/core'
import React from 'react'
import {Link, useHistory, Redirect} from 'react-router-dom'
import './styles.css'

function Rodape(){

    return (

   <footer className='rodapenovoCia'>
       <div className='rodapecima'>
       <Link to={{pathname: `/`}}>
       <img src={require('../../images/rodape.jpeg')} className='img-fluid logorodapeCia' />
                                </Link>
       
        <div className='contredes' >
         <span>Siga-nos nas redes sociais</span>
         <div className='contlogosCia'>
            <a  onClick={()=> window.open('https://pt-br.facebook.com/ciadaspecaspel/', "_blank")} ><img src={require('./image 16.png')} className='img-fluid redeslogo' /></a> 
            <a  onClick={()=> window.open('https://www.instagram.com/ciadaspecaspelotas/?igshid=YmMyMTA2M2Y=', "_blank")} ><img src={require('./image 15.png')} className='img-fluid redeslogo' /></a> 
            <a  onClick={()=> window.open('https://api.whatsapp.com/send?phone=555333051473', "_blank")} ><img src={require('./image 14.png')} className='img-fluid redeslogo' /></a> 
         </div>
        </div>
       </div>
       <div className='rodapemeio'>
        <a href='https://www.google.com/maps/dir/CIA+DAS+PE%C3%87AS+PELOTAS+-+Rua+Santos+Dumont+-+Centro,+Pelotas+-+RS//@-31.7534028,-52.3450479,13.97z/data=!4m8!4m7!1m5!1m1!1s0x9511b5a077f32429:0x94eb243aff029ba8!2m2!1d-52.3413195!2d-31.753464!1m0' target='_blank'><span>Venha nos visitar</span></a>
        <span>R. Santos Dumont, 663 - Centro, Pelotas - RS</span>
        <span>Segunda a Sexta  das 08:00 às 12:00 e das 13:30 às 18:00 <br/> Sábados das 08:00 às 12:00</span>
       </div>
       <div className='rodapefim'>
        <div className='containertradeinfo'>
        <span style={{cursor:'pointer'}} onClick={() => window.open('http://tradesystem.com.br/', '_blank')}>Copyright © Todos os DIreitos Reservados | Sistema produzido e distribuido por Trade System</span>
        </div>
       </div>
        
   </footer>
    )
}

export default Rodape

/*
<a  onClick={()=> window.open("https://api.whatsapp.com/send?phone=5553991087357&text=A%20melhor%20companhia%20para%20o%20seu%20ve%C3%ADculo", "_blank")} ><img src={require('./image 14.png')} className='img-fluid redeslogo' /></a> 
<a ><img src={require('./image 15.png')} className='img-fluid redeslogo' onClick={()=> window.open("https://www.instagram.com/diskaguagasebebidas1101/", "_blank")} /></a>             
*/ 