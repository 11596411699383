import React, {Component} from 'react'
import './styles.css'
import api from '../../services/api'
import HeaderLoja from '../../components/headerLoja'
import Rodape from '../../components/rodape'
import axios from 'axios'
import {server, showError} from '../../common'
import semImagem from '../../img/products/no_image.jpg'
import {Link, useHistory, Redirect} from 'react-router-dom'
import util from '../../classes/util'
import {connect} from 'react-redux'
import {add} from '../../store/actions/produto'
import ReactFileReader from 'react-file-reader'

import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

  
  //<img src={images['doggy.png']} />
const id = util.completarZerosEsquerda(11, 8)
const reqImgs = require.context( `../../img/products/00000011`, false, /\.png$/ )
const paths = reqImgs.keys()
const imgs = paths.map( path => reqImgs ( path ) )
const testFolder = '../../img/products/00000011'
const readdir = require('@folder/readdir')

/*
fs.readdir(testFolder, (err, files) => {
  files.forEach(file => {
    console.log(file)
  })
})
*/
class ProdutoDetalhe extends Component {
    
    state = {
        produtos: [],
        qtd: 1,
        total: 0,
        produtoAdd: [],
        chavesProdutos: [], 
        id: null,
        familia1: [],
        familia2: [],
        familia3: [],
        redirect: false   
    }

    async componentDidMount(){
        window.scrollTo(0, 0)
        var id = this.props.match.params.id
        await this.setState({id})
        await this.loadData(id)
        //await this.reqImgs()
        await this.setState({chavesProdutos: this.props.carrinho.map(function(e) { return e.chave})})
        console.log(this.state.chavesProdutos.map(function(e) { return e}))
        console.log(imgs)
        //this.lerArquivos()
        //this.lerArquivos()
    }   

    loadData = async (produto) => {
        try{
            const res = await axios.get(`${server}/produtos/1/${produto}`)
            await this.setState({produtos: res.data})
        }catch(e){
            showError(e)
        }
        try {
            const res = await axios.get(`${server}/familiasadmin/${this.state.produtos[0].Grupo1}`)
            await this.setState({familia1: res.data})
        }catch(e){
            showError(e)
        }
        try {
            const res = await axios.get(`${server}/familiasadmin/${this.state.produtos[0].Grupo2}`)
            await this.setState({familia2: res.data})
        }catch(e){
            showError(e)
        }
        try {
            const res = await axios.get(`${server}/familiasadmin/${this.state.produtos[0].Grupo3}`)
            await this.setState({familia3: res.data})
        }catch(e){
            showError(e)
        }
    }


    addProduto = async () => {
      
       
    let pos = await this.state.chavesProdutos.indexOf(parseInt(this.props.match.params.id))
    console.log(this.props.match.params.id)
    console.log(pos)
    console.log(this.state.chavesProdutos.map(function(e) { return e}))    
    if(pos == -1){
            
        let total = this.state.produtos[0].Preco_Venda * this.state.qtd
        let teste = this.state.produtos[0]
        teste.quantidade = this.state.qtd
        teste.total = total
        await this.setState({produtoAdd: teste, total})
        this.props.onProduto({...this.state})
		await this.setState({chavesProdutos: [...this.state.chavesProdutos, this.state.produtos[0].chave]})

		confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='custom-ui'>
							<h1>Espaço Acervo</h1>
							<p>{this.state.produtos[0].Descricao} com {this.state.qtd} unidades adicionado!</p>
							<button 
								style={{marginRight: 5}}
								className="btn btn-success h-50 mx-1 px-auto"
								onClick={async () => {
									onClose()
								}}
							>
								Continuar comprando
							</button>
                            <button 
								style={{marginRight: 5}}
								className="btn btn-secondary h-50 mx-1 px-auto"
								onClick={async () => {
                                    onClose()
                                    await this.setState({redirect: true})
									
								}}
							>
								Ir para o carrinho
							</button>
						</div>
					)
				}
		})		
        //alert(`${this.state.produtos[0].Descricao} com ${this.state.qtd} unidades adicionado!`)       
        //console.log(this.state.chavesProdutos)
        
        }else{
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='custom-ui'>
							<h1>Espaço Acervo</h1>
							<p>Falha ao adicionar o produto, já adicionado anteriormente!</p>
							<button 
								style={{marginRight: 5}}
								className="btn btn-success w-25"
								onClick={async () => {
									onClose()
								}}
							>
								Ok
							</button>
						</div>
					)
				}
			})
           // alert("Falha ao adicionar o produto, já adicionado anteriormente")
        }
   }

   alterarQtd = async (alterar) => {
       let novaQuantidade = await this.state.qtd
       novaQuantidade = novaQuantidade + alterar
       novaQuantidade >=  1 ? await this.setState({qtd: novaQuantidade}) : await this.setState({qtd: 1})
   }

   filtrarDescricao = (texto) => {
        try{
            const original = texto
            var regex = /(<descricao>)(.*)(descricao>)/
            var negrito = /(<b>)(.*)(b>)/
            var filtrado = regex.exec(original)[2]
            var resultado = filtrado.substring(0, filtrado.length - 2)
            var filtradoB = resultado.split("<b>")
            var resultadoN = ''
            filtradoB.map(filt => {
                resultadoN += filt
            })
            return resultado
        }catch(e){
            return ''
        }
    }
 
    filtrarEspecificacao = (texto) => {
        try{
            const original = texto
            var tiraEne = original.replaceAll('\n', ' ')
            var tiraErre = tiraEne.replaceAll('\r', ' ')
            var regex = /(<especificacao>)(.*)(especificacao>)/
            var filtrado = regex.exec(tiraErre)[2]
            var resultado = filtrado.substring(0, filtrado.length - 2)
            //var retorno = resultado.split('666')
            return resultado
        }catch(e){
            return ''
        }
    }

    render(){

        return(
            <div className="header">
                {this.state.redirect &&
                    <Redirect to={{pathname: '/carrinho'}} />
                }
                <HeaderLoja empresa={this.props.match.params.empresa}/>
                    <section className="page-add margemAcima">
                    
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="page-breadcrumb">
                                    <h2>Detalhes do Produto<span>.</span></h2>
                                    <a href="#">Início</a>
                                    <a href="#">Detalhes</a>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                    <section className="product-page">
                        {this.state.produtos.map (produto => (

                       
                        <div key={produto.chave} className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <figure>
                                    <img src={util.urlExists(produto.chave)+'.jpg'}  className="img-fluid thumbnail" onError={(e)=>{e.target.onerror = null; e.target.src=semImagem}} alt={produto.Descricao}/>
                                    </figure>   
                                </div>
                                <div className="col-lg-6 col-6">
                                    <div className="product-content">
                                        <h2>{util.capitalize(produto.Descricao)}</h2>
                                        <div className="pc-meta">
                                            <h5>{produto.Preco_Venda.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</h5>
                                            <div className="rating">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <ul className="tags">
                                            <li><span>Categorias :</span> {this.state.familia1.Descricao ? util.capitalize(this.state.familia1.Descricao) : ''}{this.state.familia2.Descricao ? ', ' + util.capitalize(this.state.familia2.Descricao) : ''}{this.state.familia3.Descricao ? ', ' + util.capitalize(this.state.familia3.Descricao) : ''}</li>
                                        </ul>
                                        <div className="product-quantity">
                                            <div className="pro-qty2">
                                                <span onClick={() => this.alterarQtd(-1)} className="dec qtybtn">-</span>
                                                <input value={this.state.qtd} type="text" name="qtd" id="qtd" min={1} max={10} onChange={e => { 
                                                    if(e.currentTarget.value > 10){
                                                        e.currentTarget.value = 10 
                                                    }else{
                                                    this.setState({qtd: e.currentTarget.value})
                                                    }
                                                 }} />
                                                 <span onClick={() => this.alterarQtd(1)} className="inc qtybtn">+</span>
                                            </div>
                                        </div>
                                        <button onClick={() => this.addProduto()}  className="btn-primary btn">Adicionar ao Carrinho</button>
                                    </div>
                                </div>
                               
                            </div>
                            
                        </div>
                        ))}
                    </section>
                    {this.state.produtos.map (produto => (
                           
                           <div className="cont_info" key={produto.chave}>
                           <h2 className='ifotitulo1'>Informações do Produto</h2>
                                       
                                  
                                   {produto.Obs != null && 
                                       <div>
                                           
                                           <p dangerouslySetInnerHTML={{__html: this.filtrarDescricao(produto.Obs)}}></p>
                                           <p dangerouslySetInnerHTML={{__html: this.filtrarEspecificacao(produto.Obs)}}></p>
                                       </div>
                                   }
                                   </div>
                    ))}
                    <Rodape />
                </div>
            )
        }
}

const mapDispatchToProps = dispatch => {
    return{
        onProduto: produto => dispatch(add(produto))
    }
}

const mapStateToProps = ({produtos}) => {
    return {
        carrinho: produtos.produtos
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProdutoDetalhe)

/**
 * <div className="col-lg-8">
        <img src={require('../../img/add.jpg')} alt="" />
    </div>
    <div className="product-control">
        <Link to={{pathname: `/detalhes/${this.props.empresa}/${this.state.id - 1}`, state: produto.chave}}>Anterior</Link>
        <Link to={{pathname: `/detalhes/${this.props.empresa}/${(this.state.id) + 1}`, state: produto.chave}}>Próximo</Link>
    </div>
 */
