import React, { Component } from 'react'
import Styles from './styles.css'
import ReactDOM from 'react-dom'
//import Modal from '@material-ui/core/Modal'
import { Formik, Field, Form } from 'formik'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { server, showError, showSuccess } from '../../common'
import { login, logout } from '../../store/actions/user'
import axios from 'axios'
import { pesquisar } from '../../store/actions/pesquisa'
import semImagem from '../../img/products/no_image.jpg'
import moment from 'moment'
import util from '../../classes/util'
import api from '../../services/api'
import CEP from 'cep-promise'
import apiWeb from '../../services/apiWeb';
import InputMask from 'react-input-mask';
import Modal from '@material-ui/core/Modal';
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import Badge from '@material-ui/core/Badge'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Util from '../../classes/util'

class ModalAlert extends Component {
    componentDidUpdate = (props, states) => {
        if (this.props.alertAberto) {
            this.fecharModal();
        }
    }

    fecharModal = () => {
        setTimeout(this.props.closeAlert, '3000');
    }

    render() {

        return (
            <Modal
                className='modalAlert'
                open={this.props.alertAberto}
                onClose={this.props.fecharAlert}>

                <div className='modalContent'>
                    <div className='exitButton'>
                        <div className='modalButton' onClick={this.props.closeAlert}><span>X</span></div>
                    </div>
                    <div className='modalText'><span>{this.props.texto ? this.props.texto : "Erro Desconhecido!"}</span></div>

                </div>
            </Modal>

        )
    }

}

export default (ModalAlert)