import React, {Component} from 'react'
import './styles.css'
import {Formik, Field, Form, isInteger} from 'formik'
import Logo from '../../img/logos/ea.png'
import api from '../../services/api'
import util from '../../classes/util'
import InputMask from 'react-input-mask'
import Skeleton from '../../components/skeleton'
import Header from '../../components/header'
import Image from 'react-bootstrap/Image'
import {Link} from 'react-router-dom'

const estadoInicial = {
    fotos: [],
    thumbs: [],
    familias1: [],
    familias2: [],
    familias3: [],
    chave: null,
    codigo: null,
    descricao: '',
    preco_venda: null,
    grupo1: 0,
    grupo2: 0,
    grupo3: 0, 
    produto_web: false,
    empresa: 0,
    id: null,
    loading: true,
    maxNumber: 99,
    empresas: []
}

class AddProduto extends Component {
   
    state = {
        ...estadoInicial
    }

    componentWillMount = async () => {
        window.scrollTo(0, 0)
        var id = this.props.match.params.id
        await this.setState({id})
        await this.loadData(id)
        await this.carregaFamilias1()
        await this.carregaFamilias2()
        await this.carregaFamilias3()
        await this.carregaEmpresas()
        await this.setState({loading: false})
    }

    carregaFamilias1 = async () => {
        await api.get(`familiasall/1`, {
        }).then(response => {
            this.setState({familias1: response.data})
        })
    }

    carregaFamilias2 = async () => {
        await api.get(`familiasall/2`, {
        }).then(response => {
            this.setState({familias2: response.data})
        })
    }

    carregaFamilias3 = async () => {
        await api.get(`familiasall/3`, {
        }).then(response => {
            this.setState({familias3: response.data})
        })
    }

    carregaEmpresas = async () => {
        await api.get(`empresas`, {
        }).then(response => {
            this.setState({empresas: response.data})
        })
        
    }

    loadData = async (produto) => {
        if(produto > 0){
            try{
                await api.get(`produtosall/${produto}`, {
                }).then(async res => {
                    await this.setState({chave: res.data.chave, codigo: res.data.Codigo, descricao: res.data.Descricao, preco_venda: res.data.Preco_Venda.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}), grupo1: res.data.Grupo1, grupo2: res.data.Grupo2, grupo3: res.data.Grupo3, produto_web: res.data.produto_web, empresa: res.data.produto_empresa})
                })
            }catch(e){
                alert(e)
            }
        }
    }
   
    inserirProduto = async () => {
        await this.setState({loading: true})
        if(this.state.id == 0){
            await api.post(`produtos`, {
                codigo: this.state.codigo,
                descricao: this.state.descricao,
                produto_web: this.state.produto_web,
                empresa: this.state.empresa
            }).then(async response => {
                if(response.status === 204){
                    await this.setState({loading: false})
                    alert("Cadastro realizado corretamente!")
                }else{
                    alert("Erro no cadastro! Tente novamente!")
                }
            })
        }else{
            let outroFile = new FormData()
            outroFile.append('chave', util.completarZerosEsquerda(this.state.chave, 6))
            //file.append('chave', util.completarZerosEsquerda(this.state.chave, 8))
            
            try{
                this.state.fotos.map((picture, index) => {
                    let file = new FormData()
                    file.append('chave', util.completarZerosEsquerda(this.state.chave, 6))
                    file.append('indice', util.completarZerosEsquerda(index, 2))
                    file.append('file', picture)
                    api.post('file', file, {
                        headers: {
                            "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
                            }
                        }
                    )}
                )
                
                api.put(`produtosall/${this.state.id}`, {
                        descricao: this.state.descricao,
                        produto_web: this.state.produto_web,
                        empresa: this.state.empresa

                        }).then(async response => {
                            if(response.status === 204){
                                await this.setState({loading: false, fotos: []})
                                alert('Produto atualizado e Foto armazenada!')
                            }else{
                                this.setState({loading: false, fotos: []})
                                alert('erro')
                            }
                        })
                
            }catch(e){
                alert(e)
            }
            
        }
    }

    fileSelectedHandler = async (event) => {
        console.log(event.target.files[0])
        await this.setState({thumbs: [...this.state.thumbs, URL.createObjectURL(event.target.files[0])], fotos: [...this.state.fotos, event.target.files[0]]})
    }

    removerProduto = async (indice) => {
        var atualizado = [...this.state.fotos]
        await atualizado.splice(indice, 1)
        await this.setState({fotos: []})
        await this.setState({fotos: atualizado})
        var atualizadoThumb = [...this.state.thumbs]
        await atualizadoThumb.splice(indice, 1)
        await this.setState({thumbs: []})
        await this.setState({thumbs: atualizadoThumb})
    }

    render(){
        const validations = []
        validations.push(this.state.codigo && this.state.codigo.trim().length >= 2)
        validations.push(this.state.descricao && this.state.descricao.trim().length >= 4)
        validations.push(this.state.preco_venda)
        validations.push(this.state.fotos && this.state.fotos.length >= 1)
        //o formulário só será válido se todas as validações forem verdadeiras, com este reduce implementado
        const validForm = validations.reduce((t, a) => t && a)
        
        return (
            <div>

                {this.state.loading &&
                    <Skeleton />
                }

                {!this.state.loading &&
                <div>
                <section className="page-add">
                    
                    <Header />
                
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <Link to={{pathname: `/admin/produtos`}}>
                                    <button className="btn btn-danger">Voltar</button>
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                <div className="page-breadcrumb">
                                    <h2 style={{marginBottom: '3%', marginTop: '3%', textAlign: 'center'}}>{this.state.id == 0 ? 'Inserir produto' : 'Alterar Produto'}</h2>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <div className="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Formik 
                                initialValues={{
                                    //codigo: null,
                                    //descricao: '',
                                    //id_seaport: null,
                                    //produto_web: false,
                                    //empresa: null,
                                    //status: '',
                                }}
                                onSubmit={async values => {
                                    await new Promise(r => setTimeout(r, 2000))
                                    this.inserirProduto()
                                }}
                                >
                                    <Form className="contact-form">
                                    
                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left">
                                            <label htmlFor="codigo">Código: </label>
                                        </div>
                                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 text-left">
                                            <Field value={this.state.codigo} onChange={e => { this.setState({codigo: e.currentTarget.value})}} id="codigo" name="codigo" type="text" placeholder="Código" />
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left">
                                            <label htmlFor="descricao">Nome: </label>
                                        </div>
                                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 text-left">
                                            <Field type="text" name="descricao" id="descricao" value={this.state.descricao} onChange={async e => { await this.setState({descricao: e.currentTarget.value})}} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left">
                                            <label htmlFor="preco_venda">Preço de venda: </label>
                                        </div>  
                                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 text-left">
                                            <InputMask mask="99,99" onChange={e => { this.setState({preco_venda: e.currentTarget.value})}} value={this.state.preco_venda} id="preco_venda" name="preco_venda" type="text" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left">
                                            <label htmlFor="seaport_destiny">Família nível 1</label>
                                        </div>
                                        <div className="col-lg-3 text-left">
                                            <Field as="select" name="grupo1" id="grupo1" value={this.state.grupo1} onChange={e => { this.setState({grupo1: e.currentTarget.value})}}>
                                                {this.state.familias1.map(familia => (
                                                    <option value={familia.Chave} key={familia.Chave}>{familia.Descricao}</option>
                                                ))}
                                                <option value={0} key={0}>Selecione...</option>
                                            </Field>
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left">
                                            <label htmlFor="seaport_destiny">Família nível 2</label>
                                        </div>
                                        <div className="col-lg-3 text-left">
                                            <Field as="select" name="grupo2" id="grupo2" value={this.state.grupo2} onChange={e => { this.setState({grupo2: e.currentTarget.value})}}>
                                                {this.state.familias2.map(familia => (
                                                    <option value={Number(familia.Codigo).toString()} key={familia.Chave}>{familia.Descricao}</option>
                                                ))}
                                                <option value={0} key={0}>Selecione...</option>
                                            </Field>
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left">
                                            <label htmlFor="seaport_destiny">Família nível 3</label>
                                        </div>
                                        <div className="col-lg-3 text-left">
                                            <Field as="select" name="grupo3" id="grupo3" value={this.state.grupo3} onChange={e => { this.setState({grupo3: e.currentTarget.value})}}>
                                                {this.state.familias3.map(familia => (
                                                    <option value={Number(familia.Codigo).toString()} key={familia.Chave}>{familia.Descricao}</option>
                                                ))}
                                                <option value={0} key={0}>Selecione...</option>
                                            </Field>
                                        </div>
                                    </div>

                                    
                                   
                                    
                                    <div className="row">
                                        
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 text-left">
                                            <label htmlFor="produto_web" className="form-check-label">Produto web</label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-9">
                                            <Field  onClick={async (e) => { await this.setState({produto_web: !this.state.produto_web}) } } name="produto_web" id="produto_web" type="checkbox"  checked={this.state.produto_web} />
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 text-left">
                                            <label htmlFor="empresa" className="form-check-label">Empresa</label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-9 text-left">
                                            <Field as="select" name="empresa" id="empresa" value={this.state.empresa} onChange={async e => { await this.setState({empresa: e.currentTarget.value})}}>
                                                {this.state.empresas.map(empresa => (
                                                    <option value={empresa.Chave} key={empresa.Chave}>{empresa.Nome_Fantasia}</option>
                                                ))}
                                                <option value={0} key={0}>Selecione...</option>
                                            </Field>
                                        </div>                                        
                                        
                                        <input type="file" name="file" className="form-control-lg" accept="image/jpeg" onChange={this.fileSelectedHandler}/>
                                        
                                        <div className="col-lg-12 text-right">
                                            <button disabled={!validForm} type="submit" style={validForm ? {} : {backgroundColor: '#eee', opacity: 0.3} } >Salvar</button>
                                        </div>

                                    </div>

                                    </Form>
                                </Formik>
                            </div>
                            {this.state.fotos.map((foto, index) => (
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <p>Nome: {foto.name}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <Image src={this.state.thumbs[index]} thumbnail fluid style={{maxHeight: 200, maxWidth: 200}}/> 
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <button className="btn btn-danger" onClick={() => this.removerProduto(index)}>Remover foto</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }
            </div>
        )
        
    /*

    className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')}
    
       return (
        <section className="latest-products spad">
            <div className="container">
                <div className="product-filter">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title">
                                <h2>Administrador</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="contender">
                    <p>Login</p>
                    <p>Senha</p>
                </div>                
            </div>
    </section> 
    )
    <div className="App">
                                <ImageUploading
                                    multiple
                                    value={this.state.fotos}
                                    onChange={this.onImageUpload}
                                    maxNumber={this.state.maxNumber}
                                    dataURLKey="data_url"
                                >
                                {({
                                                imageList,
                                                onImageUpload,
                                                onImageRemoveAll,
                                                onImageUpdate,
                                                onImageRemove,
                                                isDragging,
                                                dragProps,
                                                }) => (
                                                // write your building UI
                                                <div className="upload__image-wrapper">
                                                    <button
                                                    style={isDragging ? { color: 'red' } : undefined}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                    >
                                                    Clique ou solte a imagem aqui</button>
                                                    &nbsp;
                                                    <button onClick={onImageRemoveAll}>Remover todas as fotos</button>
                                                    {this.state.fotos.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper">
                                                        <button onClick={() => onImageUpdate(index)}>Trocar a foto</button>
                                                        <button onClick={() => onImageRemove(index)}>Remover a foto</button>
                                                        </div>
                                                    </div>
                                                    ))}
                                                </div>
                                                )}
                                            </ImageUploading>
                                        </div>
    */
    }
}

export default AddProduto
