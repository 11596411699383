import React, { Component, useRef, } from 'react'
import api from '../../services/api'
import ListaProdutos from '../../components/listaProdutos'
import HeaderLoja from '../../components/headerLoja'
import { Link, useHistory, Redirect } from 'react-router-dom'
import { CAMINHO_FOTOS } from '../../config'
import './styles.css'
import Util from '../../classes/util'
import { connect } from 'react-redux'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import OwlCarousel from 'react-owl-carousel2';
import Rodape from '../../components/rodape'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { add } from '../../store/actions/produto'
import ModalAlert from '../../components/ModalAlert'

const estadoInicial = {
    promocoes: [],
    produtos: [],
    empresa: null,
    pesquisa: '',
    pagina: 0,
    porPagina: 12,
    paginacao: 0,
    loading: false,
    slide: 2,
    centro: false,
    alertAberto: false,
    textoErro: "",
    redireciona: false,
    display: [],
    principaisMarcas: [],
    familian1: [],
    familian2: [],
    promocoes_itens: [],
    produtodetalhe: [],
    chavesProdutos: [],
    produtosfinal: [],
    loading: true,
    mobileOpen: false,
    open: false,
    produtoAdd: [],
    quantidadeprodutos: 1,
    Redirect: false,

}

class Loja extends Component {




    carregarprodutos = async () => {
        await api.get('todosprodutos', {
        }).then(
            async res => {
                let agora = await this.state.produtos;
                agora.push(...res.data)
                await this.setState({ produtosfinal: agora })
            }
        )
    }




    carregarprodutos2 = async () => {
        await api.get('todosprodutos2', {
        }).then(
            async res => {
                await this.setState({ produtos: res.data })
            }
        )
    }

    addProduto = async (produtos) => {
        let valor = produtos.Preco_Venda
        if (produtos.Valor || produtos.valor) {
            valor = (produtos.Valor ? produtos.Valor : produtos.valor)
        }

        let total = valor * this.state.quantidadeprodutos
        let teste = produtos
        teste.quantidade = this.state.quantidadeprodutos
        teste.total = total
        await this.setState({ produtoAdd: teste, total })
        this.props.onProduto({ ...this.state })
        await this.setState({ chavesProdutos: [...this.state.chavesProdutos, produtos.chave] })
        await this.setState({ open: false })
        confirmAlert({
            customUI: ({ onClose }) => {
                if (this.props.usuario) {
                    return (

                        <div className='custom-ui2'>
                            <h1>Cia das peças</h1>
                            <p>{produtos.Descricao} com {this.state.quantidadeprodutos} unidades adicionado!</p>


                            <button
                                style={{ marginRight: 5 }}
                                className="btn btn-success h-50 mx-1 px-auto"
                                onClick={async () => {
                                    onClose()
                                }}
                            >
                                Continuar comprando
                            </button>
                            <button
                                onClick={async () => { onClose(); await this.setState({ Redirect: true }) }}
                                className="btn btn-secondary h-50 mx-1 px-auto" >

                                Ir para o carrinho

                            </button>
                        </div>
                    )
                } else {
                    onClose()
                }
            }
        })
        //alert(`${this.state.produtos[0].Descricao} com ${this.state.qtd} unidades adicionado!`)       
        ////console.log(this.state.chavesProdutos)
    }





    state = {
        ...estadoInicial
    }

    componentWillMount = async () => {
        await this.carregaEmpresa()
        // console.log('foi?')
        if (window.innerWidth > 2299) {
            this.setState({ slide: 4, centro: false })
        }
        if (window.innerWidth > 1599) {
            this.setState({ slide: 3, centro: false })
        }
        if (window.innerWidth < 1599 && window.innerWidth >= 1000) {
            this.setState({ slide: 2, centro: false })
        }
        if (window.innerWidth < 1000) {
            this.setState({ slide: 1, centro: false })
        }

    }
    componentDidMount = async () => {
        //await this.carregaEmpresa()
        await this.pegarpromoitens()
        await this.carregarprodutos2()
        await this.carregarprodutos()
        await this.carregarfamiliasn1()
        await this.carregarfamiliasn2()

        let array = await document.querySelectorAll('.arrumarbugowl')
        for (let i = 0; i < array.length; i++) {
            let r = await array[i].parentNode
            await r.classList.add('.sumir')
        }
        if (this.state.produtos.length > 5) {
            await this.pegarMarcas();
            this.displayProdutos(this.state.principaisMarcas);
        }
    }


    componentDidUpdate = async (prevProps, prevState) => {

        if (this.props.pesq !== prevState.pesq) {
            /*
            await this.setState({pesquisa: this.props.pesq})
            await this.setState({pagina: 0, produtos: []})
            const { pagina } = this.state
            const {porPagina} = this.state
            const empresa = this.state.empresa ? this.state.empresa : 0
            let url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/10000/0` : `produtos/${empresa}/10000/0`
            await api.get(url, {
            }).then(resp => {
                this.setState({tamanho: resp.data.length})
                this.carregaPaginas()
            })
            url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/${porPagina}/${this.state.paginacao}` : `produtos/${empresa}/${this.state.porPagina}/${this.state.paginacao}`
            await api.get(url, {
                }).then(response => {
                this.setState({produtos: []})    
                this.setState({produtos: response.data})
                }
            )
            */
            //alert(this.props.pesq)
        }
    }

    carregarfamiliasn1 = async () => {
        await api.post('familiasnivelum', {
            empresa: 1
        }).then(
            async res => {
                await this.setState({ familian1: res.data })
            }
        )
    }

    carregarfamiliasn2 = async () => {
        await this.state.familian1.map(async (f, indice) => {
            let c = f.Chave
            await api.post('familiasn2', {
                chave: c,
                empresa: 1
            }).then(
                async res => {
                    let temp = await this.state.familian2
                    //alert('temporario: ' + temp)
                    temp = await temp.concat(res.data)
                    //alert(temp)
                    await this.setState({ familian2: [] })
                    await this.setState({ familian2: temp })
                }
            )
        })
    }

    displayProdutos = async (grupos) => {
        let tudo = grupos.map((elemento, index) => {
            let display = [];
            let numeros = [];
            let produtos_gruponv2 = this.state.produtos.filter(prod => prod.Grupo2 == elemento.Chave)
            if (!produtos_gruponv2[0]) {
                return
            }

            if (produtos_gruponv2.length > 5) {
                for (let midIndex = 0; midIndex < 12; midIndex++) {
                    let numeroAleatorio = Math.floor(Math.random() * this.state.produtos.length);
                    if (numeros.indexOf(numeroAleatorio)) {
                        numeros.push(numeroAleatorio)
                        display.push(this.state.produtos[numeros[midIndex]]);
                    } else {
                        midIndex--;
                    }
                }
            } else {
                display = produtos_gruponv2
            }

            display = display.map((prod, index) => {
                //console.log(prod);
                return (
                    <div className='cttlistaprod'>
                        <div className='containerprodutoslistaprod_inicio'>

                            <div className='containerprodutoslistaprod2'>
                                <div className='placeholderImg_inicio'>
                                    <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)} onLoad={(e) => {
                                        e.currentTarget.src = require('../../images/watermark.jpg')
                                        e.currentTarget.style.display = 'block';
                                    }} onError={(e) => {
                                        e.currentTarget.style.display = 'none';
                                    }} alt=''></img>
                                </div>
                                <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)}
                                    onError={(e) => {
                                        e.currentTarget.src = require('../../images/semimagem.png')
                                    }} className='img-fluid imgprodutolistaprod_inicio' alt={prod.descricao} />


                                <span className='titulolistprod' title={prod.Descricao}>{Util.limitarString(prod.Descricao, 100)}</span>
                                <span className='valorlistprod'>
                                    {
                                        prod.valor ? prod.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : prod.Preco_Venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </div>
                            <div className='modalpequenolistprod'>
                                <Link className='buttaoemdiv' to={{ pathname: `/detalhaProduto`, state: { produto: prod } }} >
                                    {/* <div
                                                className='buttaoemdiv'
                                                onClick={async () => {
                                                    navigation.navigate('/detalhaProduto', {
                                                        produto: prod
                                                    })
                                                        /*
                                                    await this.setState({
                                                        open: !this.state.open,
                                                        quantidadeprodutos: 1,
                                                        produtodetalhe: prod
                                                    });
                                                    //  console.log(prod)
                                                }}
                                            > */}

                                    <span class='adicionarAoCarrinho'>Mais informações</span>
                                    {/* </div> */}
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            })
            return (
                <div className='inicio_nomeGrupo'>
                    <h1>{elemento.Descricao}</h1>
                    <div className='inicio_display'>
                    {

                    }
                    <OwlCarousel options={{ items: window.innerWidth > 780 ? 3 : 1, rewind: true, margin: 0, dots: false, center: false}}>

                        {display}

                    </OwlCarousel>
                    </div>
                    <br/><hr/><br/>
                </div>
            )
        })

        this.setState({ display: tudo })

    }



    pegarMarcas = async () => {
        await api.post('/pegarMarcas', {
            marcas: ['AXIOS', 'BOSCH', 'FREMAX']
        }).then(
            async res => {
                await this.setState({ principaisMarcas: res.data })
            }
        )
    }

    pegarpromoitens = async () => {
        api.get('/promoitens').then(res => {
            this.setState({ promocoes_itens: res.data })
            // console.log(JSON.stringify(res.data))
        }).catch(res => alert(res.data))

    }

    carregaEmpresa = async () => {
        await this.setState({ empresa: this.props.match.params.id })
        //alert(this.state.empresa)
    }

    passaEmpresa = () => {
        //alert(this.state.empresa)
        var e = this.state.empresa
        //alert(e)
        return e

    }

    gerarpercentual = (preconormal, precopromo) => {
        let precodivi = precopromo * 100
        let percentual = Math.round(precodivi / preconormal)
        return (100 - percentual)
    }

    filtrodet3 = (produto) => {
        if (produto.Grupo1 == this.state.produtodetalhe.Grupo1) {
            return true
        } else {
            return false
        }
    }


    filtrodet1 = (produto) => {
        if (produto.Grupo2 == this.state.produtodetalhe.Grupo2) {
            // console.log(produto.Grupo2)
            return true
        } else {
            return false
        }
    }

    filtrodet2 = (produto) => {
        if (produto.chave != this.state.produtodetalhe.chave) {
            return true
        } else {
            return false
        }
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    redirecionar = async () => {
        await this.setState({ redireciona: false })
    }

    carregaProdutos = async () => {
        const empresa = this.state.empresa ? this.state.empresa : 0
        let url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/10000/0` : `produtos/${empresa}/10000/0`
        await api.get(url, {
        }).then(resp => {
            this.setState({ tamanho: resp.data.length })
            this.carregaPaginas()
        })
        url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/${this.state.porPagina}/${this.state.paginacao}` : `produtos/${empresa}/${this.state.porPagina}/${this.state.paginacao}`
        await api.get(url, {
        }).then(response => {
            this.setState({ produtos: [] })
            this.setState({ produtos: response.data })
        }
        )
    }

    alterar = async (p) => {
        await this.setState({ pesquisa: p })
        this.openAlert(this.state.pesquisa)
    }

    render() {


        return (
            <div>
                {this.state.Redirect && <Redirect to="/carrinho" />}
                <HeaderLoja redirecionar={this.redirecionar} empresa={this.state.empresa} />
                <div>
                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%', overflow: 'scroll' }}
                    open={this.state.open}
                    onClose={async () => await this.setState({ open: !this.state.open })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className='modalcontainerlistprod'>
                            <div className='containersairlistprodmodal'>
                                <div className='butaosairmodallistprod' onClick={async () => await this.setState({ open: !this.state.open })}>
                                    X
                                </div>
                            </div>
                            <div className='containerprodutomodal'>
                                <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.produtodetalhe.chave)} className='img-fluid imgprodutolistaproddetalhe' alt='12' />
                                <span className='titulolistprod'>{this.state.produtodetalhe.Descricao}</span>
                                <span className='valorlistprod'> R$ {this.state.produtodetalhe.Valor || this.state.produtodetalhe.valor ? (this.state.produtodetalhe.Valor ? this.state.produtodetalhe.Valor : this.state.produtodetalhe.valor) : this.state.produtodetalhe.Preco_Venda}</span>
                                <div className='contadormaisemenosmodal'>
                                    <i class="fas fa-minus" onClick={async () => {
                                        if (this.state.quantidadeprodutos <= 100 && this.state.quantidadeprodutos >= 2) {
                                            await this.setState({ quantidadeprodutos: this.state.quantidadeprodutos - 1 })

                                        }
                                    }
                                    }
                                    ></i>
                                    <input type='number' id='modalnumber ' maxLength={2} value={this.state.quantidadeprodutos} />
                                    <i class="fas fa-plus" onClick={async () => {
                                        if (this.state.quantidadeprodutos <= 99 && this.state.quantidadeprodutos >= 1) {
                                            await this.setState({ quantidadeprodutos: this.state.quantidadeprodutos + 1 })

                                        }
                                    }

                                    }
                                    ></i>
                                </div>
                            </div>

                            <div className='butao_confirmarcompra_listprod' onClick={() => this.addProduto(this.state.produtodetalhe)}>Adicionar ao carrinho</div>

                            <div className='titulo_outrosprodutos_listprod'>  Outros Produtos Relacionados   </div>
                            <div className='row' style={{ width: '100%' }}>
                                <div className='col-1 col-md-1 col-lg-1  area_buttons3'>
                                    <div onClick={() => this.refs.car.prev()} >
                                        <i class="fas fa-chevron-left"></i>
                                    </div>
                                </div>


                                <div className='col-10 col-md-10 col-lg-10'>
                                    <OwlCarousel ref="car" options={{ items: 2, rewind: true, autoplay: false, margin: 0, dots: false, center: false }}>
                                        {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b)).map((prod, index) => {

                                            return (
                                                <div className='containerprod_outrosprodutos_listprod' onClick={async () => await this.setState({ quantidadeprodutos: 1, produtodetalhe: prod })}>
                                                    <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)} className='img-fluid img_outrosprodutos_listprod' alt='12' />
                                                    <span className='tituloprod_outrosprodutos_listprod'>{prod.Descricao}</span>
                                                    <span className='valor_outrosprodutos_listprod'>R$ {prod.Valor || prod.valor ? (prod.Valor ? prod.Valor : prod.valor) : prod.Preco_Venda}</span>
                                                </div>
                                            )
                                        })}


                                    </OwlCarousel>



                                </div>



                                <div className='col-1 col-md-1 col-lg-1 area_buttons3'>
                                    <div onClick={() => this.refs.car.next()} >
                                        <i class="fas fa-chevron-right"></i>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </Fade>
                </Modal>
                <div className='row'>
                    <div className='col-0 col-md-1 col-lg-1 ' />
                    <div className='col-12 col-md-12 col-lg-10  row'>
                        <div className='container_promo col-12 row'>

                            <div className='col-12 col-md-10 col-lg-10 inicio_slider'>
                                <OwlCarousel ref="car5" options={{ items: 3, autoplay: true, autoplayTimeout: 1500, margin: 0, dots: false, center: false, loop: true }}>
                                    {[...Array(9)].map((e, index) => {
                                        return (
                                            <div className='inicio_marcas'>
                                                <img src={require("../../images/marcas/" + (index + 1) + ".png")} className='img-fluid' alt='img' />

                                            </div >
                                        )
                                    })
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    <div className='contactBanner' onClick={()=> window.open('https://api.whatsapp.com/send?phone=555333051473', "_blank")}><span>Se não achou sua peça em nosso site, clique aqui para encomendar<img src={require('../../components/rodape/image 14.png')} className='img-fluid redeslogo_inicio' /></span></div> 
                    <div className='col-0 col-md-0 col-lg-2' />
                    <div className='col-12'>
                        <div className='imagens_do_meio'>
                        </div>

                    </div>
                    <div className='col-12 '>

                        <div className='col-12 row'>
                            <div className='col-0 col-md-1 col-lg-1' />

                            <div className='col-0 col-md-1 col-lg-1' />
                        </div>
                    </div>
                </div>


                {this.state.display[0] ? this.state.display : ""}
                <br /><br /><br />

                <Rodape />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onProduto: produto => dispatch(add(produto))
    }
}

const mapStateToProps = ({ produtos, user }) => {
    return {
        carrinho: produtos.produtos,
        usuario: user.email,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Loja)

/*
  {this.state.promocoes_itens.map((itens, indice) => (
                            
                                 <div className='teste'>
                                 <img src={'https://via.placeholder.com/300'} className='img-fluid produtos_promo_img' alt='12'/>
                                 <span className='titulo_promo'> 
                                  {itens.Descricao}
                                 </span>
                                 <span className='valor_promo_antigo'>
                                 R$   {itens.Preco_Venda}
                                 </span>
                                 <span className='valor_promo_novo'>
                                 R$  {itens.Valor}
                                 </span>
                                 <span className='porcentagem_promo'>
                                    {this.gerarpercentual(itens.Preco_Venda, itens.Valor)+'%'} OFF
                                 </span> 
                             </div>
                            ))} */
