import React, { Component } from 'react'
import './styles.css'
import util from '../../classes/util'
import HeaderLoja from '../../components/headerLoja'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { server, showError, showSuccess } from '../../common'
import semImagem from '../../img/products/no_image.jpg'
import axios from 'axios'
import api from '../../services/api'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import OwlCarousel from 'react-owl-carousel2';
import { Hidden, Drawer, Input } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Pagination from '@material-ui/lab/Pagination'
import listaProdutosCopy from '../listaProdutos copy'
import { connect } from 'react-redux'
import { add } from '../../store/actions/produto'
import Rodape from '../rodape'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { CAMINHO_FOTOS } from '../../config'
import Util from '../../classes/util'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ModalAlert from '../ModalAlert'

function filtroClassificados(chave) {
    return function filter(valor) {
        return valor.grupo1 == chave
    }
}


const estadoInicial = {
    classificados: [],
    classificadosn2: [],
    classificadosn3: [],
    itensClassificados: [],
    produto: '',
    produtosfinal: [],
    produtodetalhe: [],
    chavesProdutos: [],
    produtoAdd: [],
    grupo1: 0,
    grupo2: 0,
    grupo3: 0,
    familia: [],
    familia2: [],
    totalItens: 0,
    itensPorPagina: 12,
    pagina: 1,
    loading: true,
    mobileOpen: false,
    open: false,
    quantidadeprodutos: 1,
    pesquisa: '',
    Redirect: false,
    Voltar: false,
}

class DetalhaProdutos extends Component {

    state = {
        ...estadoInicial,
        alertAberto: false,
        textoErro: ""
    }

    componentDidMount = async () => {


        this.setState({ produto: this.props.location.state.produto });
        this.setState({ quantidadeprodutos: 1 });
        //console.log(this.state);

        await this.setState({ chavesProdutos: this.props.carrinho.map(function (e) { return e.chave }) })
        //console.log(this.props.location)
        //  console.log(this.state.chavesProdutos.map(function(e) { return e}))

        let pesquisa = "";
        if (this.props.location.state.pesquisa) {
            pesquisa = this.props.location.state.pesquisa;
        }
        if (this.props.location.state) {
            await this.setState({ familia: this.props.location.state.familia, familia2: this.props.location.state.familia2, pesquisa: pesquisa })
            await this.carregarprodutos2()
            await this.carregarprodutos()
            let totalItens = this.state.produtosfinal
                .filter((a) => this.filtrarfamilia1(a))
                .filter((a) => this.filtrarfamilia2(a))
                .filter((a) => this.filtrardescricao(a))
                .length
            await this.setState({ totalItens })


        } else {
            await this.setState({ Voltar: true })
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.location.state) {
            if (this.props.location.state.familia !== this.state.familia ||
                this.props.location.state.familia2 !== this.state.familia2
            ) {
                await this.setState({
                    familia: this.props.location.state.familia,
                    familia2: this.props.location.state.familia2,
                    pesquisa: '',
                    pagina: 1
                })
                let totalItens = this.state.produtosfinal
                    .filter((a) => this.filtrarfamilia1(a))
                    .filter((a) => this.filtrarfamilia2(a))
                    .filter((a) => this.filtrardescricao(a))
                    .length
                await this.setState({ totalItens })
            } else if (prevState.pesquisa !== this.state.pesquisa) {
                await this.setState({
                    familia: this.props.location.state.familia,
                    familia2: this.props.location.state.familia2,
                    pagina: 1
                })
                let totalItens = this.state.produtosfinal
                    .filter((a) => this.filtrarfamilia1(a))
                    .filter((a) => this.filtrarfamilia2(a))
                    .filter((a) => this.filtrardescricao(a))
                    .length
                await this.setState({ totalItens })
            }
        }
    }

    carregarImagensExtras = async (elemento) => {
        [...Array(8)].map((e, index) => {
            return (
                <div>
                    <img src={CAMINHO_FOTOS + Util.completarProdutoExtra(elemento, index + 1)} onError={(e) => {
                        e.currentTarget.src = require('../../images/semimagem.png')
                    }} className='img-fluid' alt='img' />

                </div >
            )
        })
    }

    trocaImagens = (imagem) => {
        const img = document.getElementById(imagem).src;
        const imgPrincipal = document.getElementById('imgPrincipal').src;

        document.getElementById('imgPrincipal').src = img;
        document.getElementById(imagem).src = imgPrincipal;
    }

    carregarprodutos = async () => {
        await api.get('todosprodutos', {
        }).then(
            async res => {
                let agora = await this.state.produtos
                agora.push(...res.data)
                await this.setState({ produtosfinal: agora, totalItens: agora.length })
            }
        )
    }


    carregarprodutos2 = async () => {
        await api.get('todosprodutos2', {
        }).then(
            async res => {
                await this.setState({ produtos: res.data })
            }
        )
    }

    filtrarfamilia1 = (produto) => {
        if (produto.Grupo1 == this.state.familia || !this.state.familia && !this.state.familia) {
            return true
        } else {
            return false
        }

    }

    filtrarfamilia2 = (produto) => {
        if (produto.Grupo2 == this.state.familia2 || this.state.familia2 == undefined) {
            return true
        } else {
            return false
        }

    }

    filtrardescricao = (produto) => {
        if (this.state.pesquisa == '') {
            return true
        } else {
            let texto = (produto.Descricao).toUpperCase();
            let textoObs = "";
            if (produto.Obs) {
                textoObs = (produto.Obs).toUpperCase();
            }
            let textopesquisa = (this.state.pesquisa).toUpperCase();
            textopesquisa = textopesquisa.split(' ');

            let retorno = true;
            let retornoObs = true;

            for (let i = 0; i < textopesquisa.length; i++) {
                if (textoObs.indexOf('' + textopesquisa[i]) == -1 && textopesquisa[i] != "") {
                    retornoObs = false;
                }
                if (texto.indexOf('' + textopesquisa[i]) == -1 && textopesquisa[i] != "") {
                    retorno = false;
                }
            }
            return (retorno || retornoObs);
        }

    }


    addProduto = async (produtos) => {
        let pos = await this.state.chavesProdutos.indexOf(parseInt(produtos.chave))
        if (pos == -1) {
            let total = produtos.Preco_Venda * this.state.quantidadeprodutos
            if (produtos.valor) {
                total = produtos.valor * this.state.quantidadeprodutos
            }
            //console.log(total);
            let teste = produtos
            teste.quantidade = this.state.quantidadeprodutos
            teste.total = total
            await this.setState({ produtoAdd: teste, total })
            this.props.onProduto({ ...this.state })
            await this.setState({ chavesProdutos: [...this.state.chavesProdutos, produtos.chave], open: false })

            confirmAlert({
                customUI: ({ onClose }) => {
                    if (this.props.usuario) {
                        return (

                            <div className='custom-ui2'>
                                <h1>CIA das Peças</h1>
                                <p>{produtos.Descricao} com {this.state.quantidadeprodutos} unidades adicionado!</p>


                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                    }}
                                >
                                    Continuar comprando
                                </button>
                                <button
                                    onClick={async () => { onClose(); await this.setState({ Redirect: true }) }}
                                    className="btn btn-secondary h-50 mx-1 px-auto" >

                                    Ir para o carrinho

                                </button>
                            </div>
                        )
                    } else {
                        return (

                            <div className='custom-ui2'>
                                <h1>CIA das Peças</h1>
                                <p>{produtos.Descricao} com {this.state.quantidadeprodutos} unidades adicionado!</p>


                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                    }}
                                >
                                    Continuar comprando
                                </button>
                            </div>
                        )
                    }
                }
            })
        } else {
            await this.setState({ open: false })
            confirmAlert({
                customUI: ({ onClose }) => {
                    if (this.props.usuario) {
                        return (

                            <div className='custom-ui2'>
                                <h1>CIA das Peças</h1>
                                <p>Falha ao adicionar o produto, já adicionado anteriormente!</p>


                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                    }}
                                >
                                    Continuar comprando
                                </button>
                                <button
                                    onClick={async () => { onClose(); await this.setState({ Redirect: true }) }}
                                    className="btn btn-secondary h-50 mx-1 px-auto" >


                                    Ir para o carrinho

                                </button>
                            </div>
                        )
                    } else {
                        return (

                            <div className='custom-ui2'>
                                <h1>CIA das Peças</h1>
                                <p>Falha ao adicionar o produto, já adicionado anteriormente!</p>


                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                    }}
                                >
                                    Continuar comprando
                                </button>
                            </div>
                        )
                    }
                }
            })
        }
        //alert(`${this.state.produtos[0].Descricao} com ${this.state.qtd} unidades adicionado!`)       
        ////console.log(this.state.chavesProdutos)
    }


    carregarprodutos = async () => {
        await api.get('todosprodutos', {
        }).then(
            async res => {
                let agora = await this.state.produtos
                agora.push(...res.data)
                await this.setState({ produtosfinal: agora, totalItens: agora.length })
            }
        )
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    filtrodet3 = (produto) => {
        if (produto.Grupo1 == this.state.produto.Grupo1) {
            return true
        } else {
            return false
        }
    }


    filtrodet1 = (produto) => {
        if (produto.Grupo2 == this.state.produto.Grupo2) {
            //  console.log(produto.Grupo2)
            return true
        } else {
            return false
        }
    }

    filtrodet2 = (produto) => {
        if (produto.chave != this.state.produto.chave) {
            return true
        } else {
            return false
        }
    }

    colocar_pontuacao = () => {
        let a = ''
        if (this.state.produto.Preco_Venda) {
            //  console.log(this.state.produtodetalhe.Preco_Venda)
            a = (this.state.produto.Preco_Venda).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }

        return a
    }


    renderMenuLateral() {
        return (
            <div>
                <h5>Selecione o tipo</h5>
                {this.state.classificadosn3.map(item => (
                    <p
                        style={{ cursor: 'pointer' }}
                        className="text-black mt-2 mb-2"
                        onClick={async () => {
                            await this.setState({ grupo3: item.chave })
                            this.handleDrawerToggle()
                            //alert('grupo 3: ' + this.state.grupo3)
                        }}
                    >
                        {item.descricao}
                    </p>
                ))}
            </div>
        )
    }

    render() {

        return (
            <div>
                <HeaderLoja />
                {this.state.Redirect && <Redirect to="/carrinho" />}
                {this.state.Voltar && <Redirect to="/" />}

                <div className='detalha_container'>
                    <div className='detalha_produtos'>
                        <div className='detalha_produtoPrincipal'>
                            <div className='placeholderImg_detalha'>
                                <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.produto.chave)} onLoad={(e) => {
                                    e.currentTarget.src = require('../../images/watermark.jpg')
                                    e.currentTarget.style.display = 'block';
                                }} onError={(e) => {
                                    e.currentTarget.style.display = 'none';
                                }} alt=''></img>
                            </div>
                            <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.produto.chave)}
                                onError={(e) => {
                                    e.currentTarget.src = require('../../images/semimagem.png')
                                }} className='img-fluid' id='imgPrincipal' alt='img' />
                            {this.state.produto.grupoNome}
                        </div>

                        <div className='detalha_extras'>

                            <div className='detalha_Owlextras'><OwlCarousel margin={10} options={{ items: 3, dots: false }}>{[...Array(8)].map((e, index) => {
                                return (
                                    <img className="img-fluid extrasImg" src={CAMINHO_FOTOS + Util.completarProdutoExtra(this.state.produto.chave, index + 1)} onError={(e) => {
                                        e.currentTarget.parentNode.remove();
                                    }} alt='img' id={index} onClick={(e) => this.trocaImagens(e.currentTarget.id)} />
                                )
                            })}</OwlCarousel></div>

                        </div>

                    </div>

                    <div className='detalha_informacoes'>
                        {/*console.log(this.state.produto)*/}
                        <div className='detalha_titulo'><h2>{this.state.produto.Descricao}</h2></div>
                        <div className='detalha_descricao'><span dangerouslySetInnerHTML={{__html: this.state.produto.Obs ? this.state.produto.Obs.split('<especificacao>')[0] : this.state.produto.Obs}}/></div>
                        <span className='detalha_valor'> {this.state.produto.valor ? this.state.produto.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : (this.colocar_pontuacao())}</span>
                        <div className='detalha_contadormaisemenos'>
                            <i class="fas fa-minus" onClick={async () => {
                                if (this.state.quantidadeprodutos <= 100 && this.state.quantidadeprodutos >= 2) {
                                    await this.setState({ quantidadeprodutos: this.state.quantidadeprodutos - 1 })

                                }
                            }
                            }
                            >

                            </i>
                            <input type='number' id='modalnumber ' onChange={this.state.quantidadeprodutos = Math.min(this.state.quantidadeprodutos, this.state.produto.Disp_It)} maxLength={2} value={this.state.quantidadeprodutos} />
                            <i class="fas fa-plus" onClick={async () => {
                                if (this.state.quantidadeprodutos <= 99 && this.state.quantidadeprodutos >= 1) {
                                    await this.setState({ quantidadeprodutos: this.state.quantidadeprodutos + 1 })
                                }
                            }}
                            >

                            </i>

                        </div>
                        <span className='estoque'>Quantidade em estoque: {this.state.produto.Disp_It}</span>

                        <div
                            className='detalha_butao_confirmarcompra'
                            onClick={() => this.addProduto(this.state.produto)}
                        >
                            Adicionar ao carrinho
                        </div>
                    </div>
                </div>

                <div className="detalha_relacionados">
                    {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b))[0] &&

                        <div className='titulo_detalha_outrosprodutos'>Outros Produtos Relacionados</div>}
                    {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b))[0] &&

                        <div className='detalha_row'>


                            <div className='detalha_outrosprodutos'>
                                <OwlCarousel options={{ items: (window.innerWidth > 970 ? 3 : 1), mouseDrag: false, dots: false }}>
                                    {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b)).map((prod, index) => {

                                        return (
                                            <div className='detalha_containerprod_outrosprodutos' onClick={async () => await this.setState({ quantidadeprodutos: 1, produto: prod })}>
                                                <div className='placeholderImg_detalhaExtras'>
                                                    <img className='placeholderImg_detalhaImgExtra'src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)} onLoad={(e) => {
                                                        e.currentTarget.src = require('../../images/watermark.jpg')
                                                        e.currentTarget.style.display = 'block';
                                                    }} onError={(e) => {
                                                        e.currentTarget.style.display = 'none';
                                                    }} alt=''></img>
                                                <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)}
                                                    onError={(e) => {
                                                        e.currentTarget.src = require('../../images/semimagem.png')
                                                    }} className='img-fluid imgprodutolistaprod2 detalhaImgExtra' alt={prod.descricao} />
                                                    </div>
                                                <span className='tituloprod_outrosprodutos_listprod'>{prod.Descricao}</span>
                                                <span className='valor_outrosprodutos_listprod'>{prod.valor ? prod.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : prod.Preco_Venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>



                            </div>

                        </div>}

                </div>

                <div>
                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                </div>
                <Rodape />
            </div>
        )

    }
}

/*            <div>

                    <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.produto.chave)}
                        onError={(e) => {
                            e.currentTarget.src = require('../../images/semimagem.png')
                        }} className='img-fluid imgprodutolistaprod2' alt='12' />

                    <span className='titulolistprod'>{this.state.produto.Descricao}</span>
                    <span className='prodObs'>{this.state.produto.Obs}</span>

            </div>


                </div >*/




const mapDispatchToProps = dispatch => {
    return {
        onProduto: produto => dispatch(add(produto))
    }
}

const mapStateToProps = ({ produtos, user }) => {
    return {
        carrinho: produtos.produtos,
        usuario: user.email,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DetalhaProdutos)

/*
<div className="col-1">
                        <Hidden 
                            smUp 
                            implementation="css"
                        >
                            <Drawer>
                                <IconButton
                                    onClick={() => alert('icone')}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Drawer>
                        </Hidden>
                        <Hidden 
                            xsDown 
                            implementation="css"
                        >
                            <Drawer
                                variant="permanent"
                                open
                            >
                                 <h5>Selecione o tipo</h5>
                                    {this.state.classificadosn3.map(item => (
                                        <p 
                                            style={{cursor: 'pointer'}}
                                            className="text-black mt-2 mb-2"
                                            onClick={async () => {
                                                await this.setState({grupo3: item.chave})
                                                alert('grupo 3: ' + this.state.grupo3)
                                            }}
                                        >{item.descricao}</p>
                                    ))}
                            </Drawer>
                        </Hidden>
                    </div>


*/ 