import axios from 'axios'

const cors = require('cors')
cors({
    origin: '*'
})

const apilocal = axios.create({
    baseURL: 'http://192.168.15.150/siacweb/',
    //baseURL: 'http://187.86.147.193:8081/siacweb',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
    }
    
})

export default apilocal;