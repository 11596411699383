import React, {Component} from 'react'
import './styles.css'
import CEP from 'cep-promise'
import TextField from '@material-ui/core/TextField'

class LocalEntrega extends Component {
    
    state = {
        rotaSelecionada: null,
        bairro: '',
        cep: '',
        endereco: '',
        cidade: ''
    }

    constructor(props){
        super(props)      
    }

    componentDidMount = async () => {
        if(this.props.tipo == 1){
            this.filtroRota(this.props.fretes, this.props.bairro)
        }
    }

    buscarDados = async () => {
        try{  
            var obj = await CEP(this.state.cep)
            var bairro = obj.neighborhood
            var endereco = obj.street
            var cidade = obj.city
            var uf = obj.state
            if(cidade == 'Rio Grande'){
                await this.setState({bairro, endereco, cidade})
                await this.filtroRota(this.props.fretes, this.state.bairro)
            }else{
                return alert('Infelizmente, não entregamos no CEP selecionado')
            }
          }catch(e){
            return alert("CEP não encontrado")
          }
        
    }

    filtroRota = async (fretes, bairro) => {
        try{
            let retorno = await fretes.filter(function(rota){
                return rota.descricao == bairro
            })
            await this.setState({rotaSelecionada: retorno[0]})
            this.props.alteraValorFrete(this.state.rotaSelecionada.valor_frete)
        }catch(e){
            return e
        }
    }

    render(){
        const est = "col-6 mb-2 w-100"
        
        return (
            
            <div className="localEntrega">
                
                {this.props.tipo == 1 &&
                    <div
                        className="containerLocalEntrega row"
                    >
                         <div className="col-6">
                            <h6>CEP: {this.props.cep}</h6>
                        </div>
                        <div className="col-6">
                            <h6>Endereço: {this.props.endereco}</h6>
                        </div>
                        <div className="col-6">
                            <h6>Numero: {this.props.numero}</h6>
                        </div>
                        <div className="col-6">
                            <h6>Complemento: {this.props.complemento}</h6>
                        </div>
                        <div className="col-6">
                            <h6>Bairro: {this.props.bairro}</h6>
                        </div>
                        <div className="col-6">
                            <h6>Valor do Frete: {this.state.rotaSelecionada ? this.state.rotaSelecionada.valor_frete.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) : 'R$0,00' }</h6>
                        </div>
                    </div>
                }
                {this.props.tipo != 1 &&
                <div className="containerLocalEntrega row">
                    <div className={est}>
                        <TextField
                            margin="dense"
                            size="small"
                            style={{minWidth: '75%'}}
                            id="cep"
                            variant="outlined"
                            label="CEP"
                            value={this.state.cep}
                            onChange={event => this.setState({cep: event.currentTarget.value})}
                            onBlur={() => this.buscarDados()}
                        />
                    </div>
                    <div className={est}>
                        <TextField
                            margin="dense"
                            size="small"
                            style={{minWidth: '75%'}}
                            id="endereco"
                            variant="outlined"
                            label="Endereço"
                            value={this.state.endereco}
                            onChange={event => this.setState({endereco: event.currentTarget.value})}
                        />
                    </div>                   
                    <div className={est}>
                        <TextField
                            margin="dense"
                            size="small"
                            style={{minWidth: '75%'}}
                            id="numero"
                            type="number"
                            variant="outlined"
                            label="Número"
                            value={this.state.numero}
                            onChange={event => this.setState({numero: event.currentTarget.value})}
                        />
                    </div>
                    <div className={est}>
                        <TextField
                            margin="dense"
                            size="small"
                            style={{minWidth: '75%'}}
                            id="complemento"
                            variant="outlined"
                            type="text"
                            label="Complemento"
                            value={this.state.complemento}
                            onChange={event => this.setState({complemento: event.currentTarget.value})}
                        />
                    </div>
                    <div className={est}>
                        <TextField
                            margin="dense"
                            size="small"
                            style={{minWidth: '80%'}}
                            id="bairro"
                            variant="outlined"
                            type="text"
                            label="Bairro"
                            value={this.state.bairro}
                            onChange={event => this.setState({bairro: event.currentTarget.value})}
                        />
                    </div>
                    <div className={est}>
                        <h6>{this.state.rotaSelecionada ? 'Valor do frete: ' + this.state.rotaSelecionada.valor_frete.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) : '' }</h6>
                    </div>

                </div>
                }
            </div>
        )

    }    
    
}


export default LocalEntrega

/**
 * <label className="labelLC">CEP: </label>
                        <input 
                            type="text"
                            value={this.state.cep}
                            onChange={event => this.setState({cep: event.currentTarget.value})}
                            onBlur={() => this.buscarDados()}
                        />
 */