import React, {Component} from 'react'
import './styles.css'
import {Formik, Field, Form} from 'formik'
import Logo from '../../img/ea.png'
import {Link} from 'react-router-dom'

const estadoInicial = {
    login: '',
    senha: ''
}

class Login extends Component {
    
    state = {
        ...estadoInicial
    }

    componentDidMount = () => {
    }

    render(){
        return (
            <section className="latest-products spad">
                <div className="container">
                    <div className="product-filter">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <img src={Logo} />
                                <div className="section-title">
                                    <h2>Administrador</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="contender">

                        <Formik 
                            initialValues={{
                                login: '',
                                senha: ''
                            }}
                            onSubmit={async values => {
                                await new Promise(r => setTimeout(r, 500))
                                alert(values)
                            }}
                        >
                            <Form className="contact-form">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <Field value={this.state.login} onChange={e => { this.setState({login: e.currentTarget.value})}} id="login" name="login" type="text" placeholder="Login" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <Field value={this.state.senha} onChange={e => { this.setState({senha: e.currentTarget.value})}} id="senha" name="senha" type="password" placeholder="Senha" />
                                    </div>
                                </div>
                                <div className="row"></div>
                                    <div className="col-lg-12 text-right">
                                        <Link to={{pathname: `/admin/inicio`}}><button type="submit" style={{backgroundColor: '#eee', opacity: 0.9}} >Entrar</button></Link>
                                    </div>
                            </Form>
                        </Formik>
                    </div>                
                </div>
        </section> 
        )
    }
}

export default Login
