import React, { Component } from "react"

const colorArray = ['black','red']
const estadoInicial = {
    dataAtual: new Date().getTime(),
    dataFinal: new Date('09/18/2020, 17:03:40').getTime(),
    diff: 0,
    timer: 0,
    dias: 0,
    horas: 0,
    minutos: 0,
    segundos: 0,
}

class DeCron extends Component {



  constructor (props) {
    super(props)
    this.state = {
        ...estadoInicial
    }

  }

  startTimer = () => {
      this.clockCall = setInterval(() => {
          this.decrementClock()
      }, 1000)
  }

  async componentDidMount(){
      await this.setState({dataFinal: new Date(this.props.fim)})
      await this.setState({dataAtual: new Date().getTime()})
      await this.setState({diff: parseInt(((this.state.dataFinal - this.state.dataAtual) / 1000))})
      await this.setState({dias: parseInt(this.state.diff / 86400)})
      await this.setState({diff: this.state.diff % 86400})
      await this.setState({horas: parseInt(this.state.diff / 3600)})
      await this.setState({diff: this.state.diff % 3600})
      await this.setState({minutos: parseInt(this.state.diff / 60)})
      await this.setState({segundos: parseInt(this.state.diff % 60)})
      await this.setState({timer: Math.ceil(this.state.diff / (1000 * 60 * 60 * 24))})
      this.clockCall = setInterval(() => {
          this.decrementClock();
      }, 1000)
  }

  componentWillUnmount(){
      clearInterval(this.clockCall)
  }

  decrementClock = () => {
    if(this.state.segundos === 0){
      this.setState({segundos: 60})
      this.setState({minutos: this.state.minutos - 1})
    }
    if(this.state.minutos < 0){
      this.setState({minutos: 59})
      this.setState({horas: this.state.horas - 1})
    }
    if(this.state.horas < 0){
      this.setState({horas: 23})
      this.setState({dias: this.state.dias - 1})
    }    
    this.setState((prevstate) => ({segundos: prevstate.segundos - 1}))
    if(this.state.dias < 0){
      clearInterval(this.clockCall)
      this.setState({dias: 0, horas: 0, minutos: 0, segundos: 0}) 
    }
  }

  render() {
    return (
        <div>
            <h6>A promoção dura por mais {this.state.dias} dias e {this.state.horas}:{this.state.minutos}:{this.state.segundos} horas</h6>
        </div>
    );
  }
}

export default DeCron