import React, {Component} from 'react'
import './styles.css'
import util from '../../classes/util'
import Header from '../../components/header'
import {Link, Redirect} from 'react-router-dom'
import {server, showError, showSuccess} from '../../common'
import semImagem from '../../img/products/no_image.jpg'
import axios from 'axios'
import {CAMINHO_FOTOS, CAMINHO_CLASSIFICADOS} from '../../config'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const estadoInicial = {
    classificados: [],
    loading: true
}

class ClassificadosHome extends Component {

    state = {
        ...estadoInicial
    }

    componentDidMount = () => {
        this.carregaClassificados()
    }

    carregaClassificados = async () => {
        //await alert(this.props.match.params.id)
        try{
            const res = await axios.get(`${server}/tiposClassificados`)
            await this.setState({classificados: res.data})
            await this.setState({loading: false})
        }catch(e){
            showError(e)
        }
    }

    loadTiposClassificados = async () => {
        
    }

    render() {
        
        return (
            <div>
                <Header />
                <h1>Classificados</h1>
                {this.state.classificados.length <= 0 &&
                    <h1>Sem anúncios</h1>
                }
                <OwlCarousel
                    className="hero-slider"
                    margin={10}
                    nav
                    items={2}
                >

                    {this.state.classificados.map(classificado => (
                        <div key={classificado.chave} className="classificado">
                            <h2 style={{textAlign: "center"}}>{classificado.descricao}</h2>
                            <Link to={{pathname: `/classificadosgrupos/${classificado.chave}`}}>
                                <img className="imagemClassificado" src={util.urlClassificadoExists(classificado.url)}  onError={(e)=>{e.target.onerror = null; e.target.src=semImagem}} />
                            </Link>                            
                        </div>
                    ))}
                </OwlCarousel>
                
            </div>
        )

    }
}

export default ClassificadosHome