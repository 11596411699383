import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {MULTI_EMPRESA} from './config'

import Home from './screens/home'
import ListaProdutos from './components/listaProdutos'
import DetalhaProduto from './components/detalhaProduto'
import Login from './admin/login'
import Inicio from './admin/inicio'
import AdminProdutos from './admin/produtos'
import AddProduto from './admin/addproduto'
import AdminFamilias from './admin/familias'
import AddFamilia from './admin/addfamilia'
import AdminPromocoes from './admin/promocoes'
import AddPromocao from './admin/addpromocao'
import Lojas from './screens/lojas'
import Promocoes from './screens/promocoes'
import Loja from './screens/loja'
import ProdutoDetalhe from './screens/produtoDetalhe'
import Cadastro from './components/cadastro'
import Carrinho from './screens/carrinho'
import MinhaConta from './screens/minhaconta'
import MeusPedidos from './screens/meuspedidos'
import ClassificadosGrupos from './screens/classificados'
import ClassificadoDetalhe from './screens/classificadodetalhe'
import Upload from './screens/upload'
import ClassificadosHome from './screens/classificadosHome'
import Getnet from './screens/getnet'
import EmBreve from './screens/embreve'
import ResetPassword from './screens/ResetPassword'

export default function Routes(){
    const [UrlVariavel, setUrlVariavel] = React.useState('');
    
    React.useEffect(() => {
        const Url = window.location.pathname.split('/')[1];

        setUrlVariavel(Url);
    },[])

    return (
        <BrowserRouter basename={`/${UrlVariavel}`}>
            <Switch>
                <Route path="/" exact component={MULTI_EMPRESA ? Home : Loja} />
                <Route path="/admin" exact component={Login} />
                <Route path="/admin/inicio" component={Inicio} />
                <Route path="/admin/produtos" component={AdminProdutos} />
                <Route path="/admin/addproduto/:id" component={AddProduto} />
                <Route path="/admin/familias" component={AdminFamilias} />
                <Route path="/admin/addfamilia/:id" component={AddFamilia} />
                <Route path="/admin/promocoes" component={AdminPromocoes} />
                <Route path="/admin/addpromocao/:id" component={AddPromocao} />
                <Route path="/loja/:id" component={Loja} /> 
                <Route path="/detalhes/:empresa/:id" component={ProdutoDetalhe} />
                <Route path="/listaProdutos" component={ListaProdutos} />
                <Route path="/detalhaProduto" component={DetalhaProduto} />
                <Route path="/meuspedidos" component={MeusPedidos} />
                <Route path="/cadastro/:id" component={Cadastro} />
                <Route path="/carrinho" component={Carrinho} />
                <Route path="/minhaconta" component={MinhaConta} />
                <Route path="/ResetPassword/:token" component={ResetPassword} />
                <Route path="/lojas" exact component={Lojas} />
                <Route path="/promocoes" exact component={Promocoes} />
                <Route path="/classificados" exact component={ClassificadosHome} />
                <Route path="/classificadosgrupos/:id" component={ClassificadosGrupos} />
                <Route path="/classificadodetalhe/:id" component={ClassificadoDetalhe} />
                <Route path="/upload" component={Upload} />
                <Route path="/getnet" component={Getnet} />
                <Route path="/embreve" component={EmBreve} />
                <Route path="*" component={MULTI_EMPRESA ? Home : Loja} />
            </Switch>
        </BrowserRouter>
    )
}

//<Route path="/classificado/:id" exact component={Classificados} />