import React, {Component} from 'react'
import api from '../../services/api'

const estadoInicial = {
    files: null,
    selectedFile: null,
    pictures: []
}

//const files = Set<File>

class Upload extends Component {

    state = {
        ...estadoInicial
    }  
    
    constructor(props) {
        super(props);
         this.state = {...estadoInicial };
         this.onDrop = this.onDrop.bind(this);
    }
 
    onDrop = async (picture) => {
        //this.setState({pictures: [...this.state.pictures, picture]
        await this.setState({pictures: picture.target.files[0]})
        //await this.setState({pictures: this.state.pictures.concat(picture),
        console.log(this.state.pictures)
    }

    fileSelectedHandler = async event => {
        //console.log(event.target.files[0])
        await this.setState({pictures: [...this.state.pictures, event.target.files[0]]})
        console.log(this.state.pictures)
    }

    enviarArquivos = async () => {
        let file = new FormData()
        this.state.pictures.map(picture => (
            file.append('file', picture)
        ))
        try{
            await api.post(`file`, file, {
            })
            .then(res => {
                this.setState({pictures: []})
                alert('Foto armazenada!')
            })
        }catch(e){
            alert(e)
        } 
    } 
 
    render() {
        return (
            <div>
        
            <input type="file" name="file" className="form-control-lg" accept="image/png" onChange={this.fileSelectedHandler}/>
            <button className="btn btn-success" disabled={this.state.pictures.length <= 0} onClick={() => this.enviarArquivos()}>Enviar arquivos</button>
            </div>
            
        )
    }
}

    /*

                <ImageUploader
                withIcon={true}
                buttonText='Selecione as imagens'
                onChange={this.onDrop}
                imgExtension={['.jpg', '.png']}
                maxFileSize={5242880}
            />
    

    onUpload = async () => {
        if(this.state.files){
            let files = new FormData()
            files.append('files', this.state.files)
            //console.log(this.state.files)
            await axios.post(`${server}/upload`, files).then(res => {
                console.log(res)
            })
        }
    }

    fileUploadHandler = () => {
        //const fd = new FormData
        //.append('image', this.state.selectedFile, this.state.selectedFile.name)
        //console.log(fd)
        //fetch('https://pokeapi.co/api/v2/pokemon/')
        fetch('http://192.168.15.150/siacweb/android.php?method=getContas')
            .then(function(response) {
                return response.json()
            })
            .then(function(json) {
                document.querySelector('#debug').innerHTML = JSON.stringify(json.results)
            })

    }

    render() {
        return(
            <div onSubmit={this.onFormSubmit}>
            <h1>Upload de arquivos</h1>
            <input type="file" name="file" accept="image/png" onChange={this.fileSelectedHandler}/>
            <button disabled={!this.state.selectedFile} onClick={() => this.fileUploadHandler()} className="btn btn-primary">Upload</button>
            <button onClick={() => this.fileUploadHandler()} className="btn btn-primary">Teste</button>
            <pre id="debug"></pre>
            </div>
            
        )
    }
}
*/

export default Upload

    
    /*
    onChange = (e) => {
        let files = e.target.files
        //console.warn("Data file", files)
        let reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = (e) => {
            //console.warn("img data", e.target.result)
            const url = "127.0.0.1/img/uploads/"
            const formData = {file:e.target.result}
            return post(url, formData)
            .then(response=>console.warn("resultado: ", response))
        }
       
    }


    }
    */