import React, {Component} from 'react'
import './styles.css'
import api from '../../services/api'
import Header from '../../components/header'
import Rodape from '../../components/rodape'
import axios from 'axios'
import {server, showError} from '../../common'
import util from '../../classes/util'
import CEP from 'cep-promise'
import { Redirect } from 'react-router-dom'
import {Formik, Field, Form} from 'formik' 
import {connect} from 'react-redux'
import moment from 'moment'
import ModalAlert from '../../components/ModalAlert'

const initialValues = {
    nome: '',
    login: '',
    senha: '',
    confirmaSenha: '',
    cnpjcpf: '',
    endereco: '',
    cep: '',
    bairro: '',
    complemento: '',
    numero: '',
    cidade: '',
    uf: '',
    fone: '',
    codigo: null
}
class MeusPedidos extends Component {
    

    state = {
        ...initialValues,
        produtos: [],
        teste: 1234,
        redireciona: false,
        empresa: [],
        enderecoSite: '',
        alertAberto: false,
        textoErro: "",
        numeroSite: 0,
        cidadeSite: '',
        ufSite: '',
        foneSite: '',
        chave: 0 ,
        pedidos: [] 
    }

    componentDidMount= async () => {
        window.scrollTo(0, 0)
        await this.setState({chave: this.props.chave})
        await this.carregaPedidos()
        console.log(JSON.stringify(this.state.pedidos))
    }

    carregaPedidos = async () => {
        await axios.post(`${server}/meuspedidos`, {
            pessoa: this.state.chave
        }).then(
            async res => {
                await this.setState({pedidos: res.data})
            }
        )
        
    }

    carregaControle = async () => {
        try{
            const res = await axios.get(`${server}/empresas`)
            await this.setState({empresa: res.data})
        }catch(e){
            if(e.response && e.response.data){
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            }else{
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
        
    }

    carregaCadastro = async () => {
        try{
            const res = await axios.get(`${server}/pessoas/1/${this.props.chave}`)
            await this.setState({nome: res.data.Nome, cnpjcpf: res.data.Cnpj_Cpf, login: res.data.login, cep: res.data.Cep, endereco: res.data.Endereco, numero: res.data.Numero, complemento: res.data.Complemento, cidade: res.data.Cidade_Descricao, bairro: res.data.bairro, uf: res.data.UF, fone: res.data.Fone})
        }catch(e){
            if(e.response && e.response.data){
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            }else{
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    redirecionar = async () => {
        await this.setState({ redireciona: true })
    }

    teste = () => {
        const teste = 'Teste categoria'
        return teste
    }

    buscarDados = async (cep) => {
        try{  
            var obj = await CEP(cep)
            var bairro = obj.neighborhood
            var endereco = obj.street
            var cidade = obj.city
            var uf = obj.state
            await this.setState({bairro, endereco, cidade, uf})
          }catch(e){
            return this.openAlert("CEP não encontrado")
          }
        
    }

    
    enviarDados = async () => {
        this.openAlert("Entrou aqui")
    }
    
    render(){

        const validations = []
        validations.push(this.state.login && this.state.login.includes('@'))
        validations.push(this.state.senha && this.state.senha.length >= 6)
        validations.push(this.state.nome && this.state.nome.trim().length >= 3)
        validations.push(this.state.senha === this.state.confirmaSenha)
        validations.push(this.state.cnpjcpf && toString(this.state.cnpjcpf).trim().length >= 11)
        validations.push(this.state.cep && this.state.cep.trim().length == 8)
        validations.push(this.state.cidade && this.state.cidade.trim().length >= 3)
        validations.push(this.state.endereco && this.state.endereco.trim().length >= 4)
        validations.push(this.state.uf && this.state.uf.trim().length === 2)
        validations.push(this.state.bairro && this.state.bairro.trim().length >= 2)
        validations.push(this.state.numero && toString(this.state.numero).trim().length >= 1)

        //o formulário só será válido se todas as validações forem verdadeiras, com este reduce implementado
        const validForm = validations.reduce((t, a) => t && a)

        return(
            <div>
                <Header />
                <div>
                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                </div>
                {this.state.redireciona &&
                    <Redirect to={'/'} />
                }
                <section className="page-add">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="page-breadcrumb">
                                    <h2>Meus Pedidos<span>.</span></h2>
                                    <a href="#">Início</a>
                                    <a href="#">Meus Pedidos</a>
                                    <a href="#">{this.props.chave}</a>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <img src="img/add.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="contact-section">
                    <div className="container">
                        <div className="containerPedidos">
                            <div className="d-flex flex-row bd-highlight mb-1 headerPedidos">
                                <h3 className="p-2  bd-highlight headerItem">Código</h3>
                                <h2 className="p-2 headerItem">Data</h2>
                                <h2 className="p-2 headerItem">Hora</h2>
                                <h2 className="p-2 headerItem">Itens</h2>
                                <h2 className="p-2 headerItem">Valor</h2>
                                <h2 className="p-2 headerItem">Status</h2>
                            </div>
                            {this.state.pedidos.map(pedido => (
                                <div key={pedido.Chave} className="d-flex flex-row bd-highlight mb-1 bodyPedidos">
                                    <h4 className="p-3 bd-highlight itemPedido">{pedido.Chave}</h4>
                                    <h4 className="p-3 bd-highlight itemPedido">{moment(pedido.Data_saida).format('DD/MM/YYYY')}</h4>
                                    <h4 className="p-3 bd-highlight itemPedido">{moment(pedido.Data_saida).format('HH:mm')}</h4>
                                    <h4 className="p-3 bd-highlight itemPedido">{pedido.ValorProdutos.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</h4>
                                    <h4 className="p-3 itemPedido">{pedido.Total.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</h4>
                                    <h4 className="p-3">{pedido.status}</h4>
                                </div>
                            ))}
                        </div>
                        
                        <div>

                            <div className="col-lg-3 offset-lg-1">
                                <div className="contact-widget">
                                    <div className="cw-item">
                                        <h5>Localização</h5>
                                        <ul>
                                            <li>{this.props.nome}</li>
                                            <li>{this.state.enderecoSite}, {this.state.numeroSite}</li>
                                            <li>{this.state.cidadeSite}, {this.state.ufSite}</li>
                                        </ul>
                                    </div>
                                    <div className="cw-item">
                                        <h5>Telefone</h5>
                                        <ul>
                                            <li>{this.state.foneSite}</li>
                                            <li>+55 53 98125-7786</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Rodape />
            </div>
            )
        }
}

const mapStateToProps = ({user}) => {
    return{
        nome: user.name,
        chave: user.chave
    }
}
export default connect(mapStateToProps, null)(MeusPedidos)

//<textarea placeholder="Menssagem"></textarea>
