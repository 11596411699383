import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom'
import './styles.css'

////// services
import api from '../../services/api'

import apisandbox from '../../services/apisandbox'
import apilocal from '../../services/apilocal'
import apigetneth from '../../services/apigetneth'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
////// components
import HeaderLoja from '../../components/headerLoja'
import Header from '../../components/header'
import Rodape from '../../components/rodape'
import Skeleton from '../../components/skeleton'
import LocalEntrega from '../../components/localdeentrega'
import Modal from '@material-ui/core/Modal';
import axios from 'axios'
import { server, showError, showSuccess } from '../../common'
import { CAMINHO_FOTOS } from '../../config'
import Util from '../../classes/util'
import { connect } from 'react-redux'
import {
    limpar,
    remove,
    selecionaFrete,
    removeFrete,
    alterar
} from '../../store/actions/produto'

import semImagem from '../../img/products/no_image.jpg'
import moment from 'moment';
import InputMask from 'react-input-mask';
import { Form, Formik, Field } from 'formik'
import { data } from 'jquery'
import Cartao from '../../classes/cartao'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CEP from 'cep-promise'
import ModalAlert from '../../components/ModalAlert'

class Carrinho extends Component {
    state = {
        pestado: '',
        pcidade: '',
        pcep: '',
        pbairro: '',
        pendereco: '',
        pcomplemento: '',
        pnumerocasa: '',
        pconfirmarcep: '',
        apr5: '',
        carrinho: [],
        icones: ['fas fa-money-bill-wave', 'fas fa-credit-card', 'fas fa-globe', 'fas fa-store', 'fas fa-house-user'],
        qtd: 1,
        tiposEntrega: [],
        entregaSelecionada: '', //8 Retirada, 9 Entrega. [Em finalizar compra, se não for 8, será 9]
        redireciona: false,
        tipoEntrega: null,
        condicoes: [],
        enderecos: [],
        empresa: 1,
        condicao: null,
        alertAberto: false,
        textoErro: "",
        posicao: null,
        totalProduto: null,
        botaoBloqueado: false,
        loading: false,
        condTeste: [],
        valorfrete: 0,
        teleEntrega: false,
        desconto_frete: 0,
        rotas: [],
        valueendereco: '',
        enderecoCadastrado2: [],
        enderecoSelecionado: '',
        valoFrete: 0,
        rotaSelecionada: 1,
        botaoAtivado: true,
        horarioSelecionado: '',
        passos: 3,
        formaentrega: 0, // 0 para entrega em casa, 1 para retirada na loja
        formapagamento: '', // salva forma de pagamento dos input do passo 2 de 3
        modalAberto: false,
        redirect: false,
        adicionarEnderecoLiberado: true
    }

    componentDidMount = async () => {
        if (!this.props.chave) {
            await this.setState({ redirect: true })
        }
        if (this.props.total >= 150) {
            await this.setState({ desconto_frete: -15 })
        }
        await window.scrollTo(0, 0)
        //const {produto} = this.props.match.params
        //await this.loadData(produto)
        await this.loadProdutos()
        await this.loadEmpresa()
        await this.loadTiposEntrega()
        await this.pegarenderecos()
        await this.loadCondicoes()
        await this.loadRotas()

    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevState.pcep != this.state.pcep) {
            let numberPattern = /\d+/g;
            var cpflimpo = ''
            if (this.state.pcep != '__.___-___' && this.state.pcep != '') {
                let cpflimpo2 = this.state.pcep.match(numberPattern)
                var cpflimpo = cpflimpo2.join('')
            }
            //console.log(cpflimpo)

            if (cpflimpo.length == 8 && this.buscarDados(cpflimpo)) {

                document.querySelector('#erro9').style.display = 'none'
                this.setState({ apr5: true, pconfirmarcep: cpflimpo })
            } else {
                document.querySelector('#erro9').style.display = 'block'
                this.setState({ apr5: false })
            }
        } else if (prevProps.total != this.props.total) {
            if (this.props.total >= 150) {
                await this.setState({ desconto_frete: -15 })
            } else {
                await this.setState({ desconto_frete: 0 })
            }
        }

    }

    nextstage = async () => {

        // if (window.innerWidth <= 576) {
        //     window.scrollTo(0, 350)
        // }

        // if (this.state.passos == 2) {
        //     let elemento = document.querySelector('.pdesativo')
        //     ReactDOM.findDOMNode(elemento).classList.add('pativo')
        //     ReactDOM.findDOMNode(elemento).classList.remove('pdesativo')
        //     await this.setState({ passos: this.state.passos + 1 })
        // }

        // console.log('clico?')
        // if (this.state.passos == 1) {
        //     let elemento = document.querySelector('.pdesativo')
        //     ReactDOM.findDOMNode(elemento).classList.add('pativo')
        //     ReactDOM.findDOMNode(elemento).classList.remove('pdesativo')
        //     await this.setState({ passos: this.state.passos + 1 })
        // }



    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    redirecionar = async () => {
        await this.setState({ redireciona: true })
    }

    pegardescricao = (ele) => {
        if (this.state.entregaSelecionada == 8) {
            let opretirarnaloja = ''
            this.state.rotas.forEach((elemento) => {
                if (elemento.chave == 2) {
                    opretirarnaloja = elemento.descricao
                }
            })

            return opretirarnaloja


        } else if (this.state.entregaSelecionada != 8) {
            let opretirarnaloja = ''
            //console.log("-aqui-")
            this.state.rotas.forEach((elemento) => {
                if (elemento.chave == ele) {
                    opretirarnaloja = elemento.descricao
                }
            })

            return opretirarnaloja


        }

    }

    Adicionar_Endereco = async () => {
        this.setState({adicionarEnderecoLiberado: false})
        await api.post(`test2`, {
            chave: this.props.chave,
            endereco: this.state.pendereco,
            numero: this.state.pnumerocasa,
            complemento: this.state.pcomplemento,
            cidade: this.state.pcidade,
            uf: this.state.pestado,
            bairro: this.state.pbairro,
            cep: this.state.pconfirmarcep,
            inclusao: moment().format()
        }).then(
            async res => {
                //console.log(JSON.stringify(res.data))
                this.openAlert('Endereço Adicionado com Sucesso')
                await this.pegarenderecos()
                await this.setState({ modalAberto: false })
                await this.setState({adicionarEnderecoLiberado: true})
            }
        )
    }

    buscarDados = async (cep) => {
        try {
            var obj = await CEP(cep)
            //console.log(obj)
            var bairro = obj.neighborhood
            var endereco = obj.street
            var cidade = obj.city
            var uf = obj.state
            await this.setState({ pbairro: bairro, pendereco: endereco, pcidade: cidade, pestado: uf })
            return true
        } catch (e) {
            return false
        }

    }

    voltarpassos = () => {
        let elementos = document.querySelectorAll('.pativo');
        if (this.state.passos == 1) {
            elementos.forEach((teste, index) => {
                if (index > 0) {
                    ReactDOM.findDOMNode(teste).classList.remove('pativo')
                    ReactDOM.findDOMNode(teste).classList.add('pdesativo')
                }
            })
        } else {
            elementos.forEach((teste, index) => {
                if (index > 1) {
                    ReactDOM.findDOMNode(teste).classList.remove('pativo')
                    ReactDOM.findDOMNode(teste).classList.add('pdesativo')
                }
            })
        }

    }

    mudaropcaoentrega1 = async () => {
        this.setState({ enderecoSelecionado: '', entregaSelecionada: '', valorfrete: 0 })
        let formaentrega0 = document.querySelector('#formaentrega0');
        let formaentrega1 = document.querySelector('#formaentrega1');
        ReactDOM.findDOMNode(formaentrega0).classList.remove('ativo')
        ReactDOM.findDOMNode(formaentrega0).classList.add('ativo')
        ReactDOM.findDOMNode(formaentrega1).classList.remove('ativo')
        await this.setState({ formaentrega: 0 })


    }

    mudaropcaoentrega0 = async () => {
        let opretirarnaloja = [];
        this.state.tiposEntrega.forEach((elemento) => {
            if (elemento.chave == 8) {
                opretirarnaloja = elemento
            }
        })

        this.setState({ enderecoSelecionado: 1, entregaSelecionada: opretirarnaloja.chave, valorfrete: 0 })
        let formaentrega0 = document.querySelector('#formaentrega0');
        let formaentrega1 = document.querySelector('#formaentrega1');
        ReactDOM.findDOMNode(formaentrega1).classList.remove('ativo')
        ReactDOM.findDOMNode(formaentrega1).classList.add('ativo')
        ReactDOM.findDOMNode(formaentrega0).classList.remove('ativo')
        await this.setState({ formaentrega: 1 })
    }


    destacarendereco = () => {
        let selecionado = document.querySelector('.enderecoselecionado')
        if (selecionado == null) {
            let clickado = document.querySelector(`#option${this.state.enderecoSelecionado.Chave}`)
            ReactDOM.findDOMNode(clickado).classList.add('enderecoselecionado')
        } else {
            ReactDOM.findDOMNode(selecionado).classList.remove('enderecoselecionado')
            let clickado = document.querySelector(`#option${this.state.enderecoSelecionado.Chave}`)
            ReactDOM.findDOMNode(clickado).classList.add('enderecoselecionado')
        }




    }


    loadData = async (produto) => {
        try {
            const res = await api.get(`/produtos/${this.props.match.params.id}`)
            this.setState({ produtos: res.data })
        } catch (e) {
            //showError(e)
        }
    }

    loadRotas = async () => {
        try {
            const res = await api.get(`/rotas`)
            this.setState({ rotas: res.data })
            //alert(JSON.stringify(this.state.rotas))
        } catch (e) {
            if (e.response && e.response.data) {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            } else {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    loadProdutos = async () => {
        try {
            await this.setState({ carrinho: this.props.carrinho })
        } catch (e) {
            if (e.response && e.response.data) {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            } else {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    loadEmpresa = async () => {
        try {
            const res = await api.get(`/empresas`)
            this.setState({ empresa: res.data[0].Chave })
        } catch (e) {
            if (e.response && e.response.data) {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            } else {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    loadTiposEntrega = async () => {
        try {
            const res = await api.get(`/tiposentrega`)
            this.setState({ tiposEntrega: res.data })
        } catch (e) {
            if (e.response && e.response.data) {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            } else {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    alterarValoFrete = frete => {
        //alert(frete)
        this.setState({ valoFrete: frete })
    }

    calcularFrete = async () => {
        if (this.state.enderecoSelecionado.Cidade_Descricao != "Pelotas") {
            return this.setState({ valorfrete: 0 })
        }
        try {
            const res = await api.post(`/rota`, { bairro: this.state.enderecoSelecionado.bairro })
            if (res.data) {
                this.setState({ valorfrete: res.data.valor_frete })
            } else {
                let rotasPossiveis = this.state.rotas.filter((elemento) => {
                    if (elemento.descricao.indexOf(this.state.enderecoSelecionado.bairro.toUpperCase()) != -1) {
                        return elemento;
                    }
                }).map((elemento) => {
                    return {
                        descricao: elemento.descricao.split(' '),
                        valor_frete: elemento.valor_frete,
                        tamanho: elemento.descricao.split(' ').length
                    }
                })
                let valorFrete = rotasPossiveis.filter((e) => {
                    if (parseInt(e.descricao[(e.tamanho-1)]) >= parseInt(this.state.enderecoSelecionado.Numero) && parseInt(this.state.enderecoSelecionado.Numero) >= parseInt(e.descricao[(e.tamanho-2)])) {
                        return e;
                    }
                })
                if (valorFrete[0]) {
                    this.setState({valorfrete: valorFrete[0].valor_frete});
                    return;
                }

                this.setState({ valorfrete: 0 })
            }
        } catch (e) {
            if (e.response && e.response.data) {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            } else {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    alteraTipoEntrega = async tipoEntrega => {
        await this.setState({ tipoEntrega })
        if (parseInt(tipoEntrega) === 8) {
            this.setState({ teleEntrega: true })
        } else {
            this.setState({ teleEntrega: false, valoFrete: 0 })

        }
        //alert(this.state.tipoEntrega)
    }

    carregaEnderecoCliente = async () => {
        const res = await api.get(`/pessoaendereco/${this.props.chave}`)
        await this.setState({ enderecoCadastrado: res.data })
        //console.log(this.state.enderecoCadastrado)
    }

    loadCondicoes = async () => {
        try {
            const res = await api.get(`/condicoes`)
            this.setState({ condicoes: res.data })
        } catch (e) {
            if (e.response && e.response.data) {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
            } else {
                this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
            }
        }
    }

    pegarenderecos = async () => {
        try {
            const res = await api.post(`/pessoaendereco`, {
                chave: this.props.chave,

            })
            //console.log(this.props.chave)
            await this.setState({ enderecos: res.data, enderecoSelecionado: res.data[0] });
            this.calcularFrete()


        } catch (e) {
            //showError(e)
        }
    }
    alteraCondicao = async condicao => {
        await this.setState({ condicao })
        //alert(this.state.condicao)
    }

    teste = () => {
        const teste = 'Teste categoria'
        return teste
    }

    removerItem = async (posicao, totalProduto, produto) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                if (this.props.usuario) {
                    return (

                        <div className='custom-ui3'>
                            <div className='imgs_carrinho_remove'>
                                <img className='placeholderImg3' src={CAMINHO_FOTOS + Util.completarProduto(produto.chave)} onLoad={(e) => {
                                    e.currentTarget.src = require('../../images/watermark.jpg')
                                    e.currentTarget.style.display = 'block';
                                }} onError={(e) => {
                                    e.currentTarget.style.display = 'none';
                                }} alt=''></img>
                                <img src={CAMINHO_FOTOS + Util.completarProduto(produto.chave)}
                                    onError={(e) => {
                                        e.currentTarget.src = require('../../images/semimagem.png')
                                    }} className='img-fluid' alt={produto.descricao} />
                            </div>

                            <p>Deseja remover este produto: {produto.Descricao}</p>

                            <div className='containerbutoesremoverproduto'>
                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-danger h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        await this.setState({ posicao, totalProduto });
                                        await this.props.onRemoverItem({ ...this.state });
                                        onClose();
                                    }}
                                >
                                    Remover produto
                                </button>
                                <button
                                    onClick={async () => { onClose(); }}
                                    className="btn btn-secondary h-50 mx-1 px-auto" >

                                    Cancelar ação

                                </button>
                            </div>
                        </div>
                    )
                } else {
                    onClose()
                }
            }
        })
    }

    limpaCarrinho = async () => {
        try {
            this.props.onLimpar({ ...this.state })
            this.openAlert('Itens removidos do Carrinho')
        } catch (e) {
            this.openAlert("Erro ao limpar o carrinho")
        }
    }

    alterarQuantidade = (valor, produto) => {
        let payload = {
            quantidade: valor,
            produto
        }
        this.props.onAlterarQuantidade({ payload })
    }

    alteraRota = async (rota) => {
        //alert(JSON.stringify(rota))
        this.setState({ rotaSelecionada: rota })

    }

    selecionarprimeiroendereco = async (a) => {
        await this.setState({ enderecoSelecionado: a, valueendereco: a.Chave });
    }

    android = async () => {

        try {

            let dados = JSON.stringify(this.props.carrinho)
            let file = new FormData()
            file.append('data', dados)
            file.append('condicaoPagamento', this.state.condicao)
            file.append('pessoa', this.props.chave)
            file.append('tipoentrega', this.state.tipoEntrega)
            file.append('endereco', this.state.teleEntrega ? JSON.stringify(this.state.rotaSelecionada) : null)
            file.append('rota', this.state.teleEntrega ? this.state.rotaSelecionada.chave : 0)
            file.append('obs', this.state.sem)
            file.append('valoProdutos', this.props.total)
            file.append('valoFrete', this.state.valoFrete)
            file.append('valoTotal', this.state.valoFrete + this.props.total)
            //alert(this.state.condicao)
            //return 1
            if (this.state.valoFrete + this.props.total > 0) {
                await apilocal.post(`/android.php?method=finalizaPedido`, file, {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    }
                }).then(
                    async res => {
                        //await console.log(res.data)
                    },
                    async err => {
                        //console.log(err)
                    }
                )
            }

        } catch (e) {
            this.openAlert('Erro na impressão do pedido')
        }

    }

    bloqueiaBotao = () => {
        this.setState({ botaoBloqueado: !this.state.botaoBloqueado })
    }

    finalizarCompra = async () => {
        this.bloqueiaBotao();

        if (this.props.total > 0) {
            try {
                let teste = 0;
                if (this.props.total > 200) {
                    this.setState({valorfrete: 0});
                }
                if (this.state.entregaSelecionada != 8) {
                    this.setState({entregaSelecionada: 9})
                }
                if (this.props.chave) {
                    //return 1
                    const resCod = await api.get(`/codigos`)
                    let proximo = await resCod.data.proximo
                    const dataHora = moment().format('YYYY-MM-DD HH:mm:ss')
                    const data = moment().format('YYYY-MM-DD')
                    const retorno = await api.post(`/movimentacao`, {
                        tipo: 'R',
                        valorFrete: (this.state.valorfrete),
                        empresa: this.state.empresa,
                        notaFiscal: proximo,
                        dadosAdic: 'Pedido Feito Pelo Site' + ' - ' + (this.state.entregaSelecionada != 8 ? 'Por Entrega' : ' Retirar Na Loja'),
                        subTotal: (this.props.total + this.state.valorfrete/*+ this.state.desconto_frete*/).toFixed(2),
                        valoProdutos: (this.props.total).toFixed(2),
                        total: (this.props.total + this.state.valorfrete/*+this.state.desconto_frete*/).toFixed(2),
                        nomePessoa: this.props.nome,
                        pessoa: this.props.chave,
                        condicao: this.state.formapagamento.chave,
                        dataSaida: moment().format('YYYY-MM-DD HH:mm:ss'),
                        tipoEntrega: this.state.entregaSelecionada,
                        endereco_entrega: (this.state.entregaSelecionada != 8 ? this.state.enderecoSelecionado.Chave : 'Retirado na loja'),
                    })
                    if (!retorno) {
                        this.openAlert('Erro ao efetuar a compra');
                        return;
                    }
                    await this.props.carrinho.forEach((elemento, index) => adicionaItens(elemento, index, retorno.data.retorno[0]))
                    await this.props.carrinho.forEach((elemento, index) => reduzEstoque(elemento, index, retorno.data.retorno[0]))
                    proximo++
                    await api.put(`/codigos`, {
                        proximo: proximo
                    })
                    this.openAlert('Compra efetuada!')
                    //await this.setState({produtos: []})
                    //await this.setState({totalProdutos: 0})
                    this.props.onLimpar({ ...this.state })
                    await this.setState({ botaoAtivado: true })
                    setTimeout(this.redirecionar, 2000)
                } else {
                    this.openAlert("Faça login para poder finalizar o seu pedido!")
                }
            } catch (e) {
                if (e.response && e.response.data) {
                    this.openAlert(`Ocorreu um problema: Mensagem: ${e.response.data}`)
                } else {
                    this.openAlert(`Ocorreu um problema: Mensagem: ${e}`)
                }
                //console.log("-----------")
                //console.log(e)
                //console.log("-----------")

            }
        } else {
            this.openAlert('Carrinho Vazio')
        }


        async function adicionaItens(element, index, chave) {
            try {
                const resMov = await api.get(`/movimentacao`)
                let chaveMov = await resMov.data.chave + 1;
                const resGIcms = await api.get(`/grupoicms/${element.gIcms}`)
                let situacaoTributaria = resGIcms.data.Situacao_Tributaria
                //console.log(situacaoTributaria)
                //console.log(element)
                await api.post(`/movitens`, {
                    chavemov: chave,
                    descricao: `${element.Descricao}`,
                    codigoProduto: element.chave,
                    quantidade: element.quantidade,
                    valorUnitario: (element.Valor ? element.Valor : element.Preco_Venda),
                    valorTotal: element.total,
                    situacaoTributaria: situacaoTributaria,
                    un: `${element.Unid_med}`
                })

            } catch (e) {
                if (e.response && e.response.data) {
                    this.openAlert(`Ocorreu um problema`)
                } else {
                    this.openAlert(`Ocorreu um problema2`)
                }
            }
        }

        async function reduzEstoque(element, index, chave) {
            try {
                await api.post(`/reduzEstoque`, {
                    produto: element.chave,
                    competencia: element.competencia,
                    disponivel: (parseInt(element.Disp_It) - parseInt(element.quantidade)),
                    reserva: (parseInt(element.Reserva_It) + parseInt(element.quantidade)),
                })

            } catch (e) {
                if (e.response && e.response.data) {
                    this.openAlert(`Ocorreu um problema`)
                } else {
                    this.openAlert(`Ocorreu um problema2`)
                }
            }
        }
    }
    //auth/oauth/v2/token
    //'content-type: application/x-www-form-urlencoded', 
    //'authorization: Basic Y2xpZW50LTAxOnNlY3JldC1rZXktMDI='

    render() {


        const validations2 = []

        validations2.push(this.state.pcep && this.state.pcep.trim().length == 10)

        validations2.push(this.state.pcidade && this.state.pcidade.trim().length >= 3)

        validations2.push(this.state.pendereco && this.state.pendereco.trim().length >= 2)

        validations2.push(this.state.pestado && this.state.pestado.trim().length >= 2)

        validations2.push(this.state.pbairro && this.state.pbairro.trim().length >= 2)

        validations2.push(this.state.pnumerocasa && toString(this.state.pnumerocasa).trim().length >= 1)

        validations2.push(this.state.adicionarEnderecoLiberado)

        const validForm2 = validations2.reduce((t, a) => t && a)


        const validations3 = []


        if (this.state.formaentrega == 1) {
            validations3.push(this.state.formaentrega == 1 && this.state.formapagamento != "")

        } else {

            validations3.push(this.state.formaentrega == 0 && this.state.enderecoSelecionado && this.state.formapagamento != "")
            if (this.state.entregaSelecionada == 8) {
                validations3.push(this.state.horarioSelecionado != '0' && this.state.horarioSelecionado != '')
            }


        }

        const validForm3 = validations3.reduce((t, a) => t && a)




        return (
            <div>
                {this.state.redirect == 1 && <Redirect to={'/'} />
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%', overflow: 'scroll' }}
                    open={this.state.modalAberto}
                    onClose={async () => await this.setState({ modalAberto: false })}
                >
                    <div className='modalcontainerlogin'>
                        <div className='containersairlistprodmodal'>
                            <div className='butaosairmodallistprod' onClick={async () => await this.setState({ modalAberto: !this.state.modalAberto })}>
                                X
                            </div>
                        </div>
                        <span className='tituloregistrarse'>Adicionar Endereço</span>
                        <div className='container_registrar_registrar'>
                            <div className='subtitulo_registrar'>Endereço</div>


                            <div className='pt-perspective'>



                                <div className='containeralinharinput'>

                                    <div className='continpuspanregistrar'>
                                        <span>Cep:</span>
                                        <InputMask type='text' mask='99.999-999' className='inputmdform' value={this.state.pcep} onChange={e => this.setState({ pcep: e.currentTarget.value })} onBlur={e => this.buscarDados(e.currentTarget.value)} />
                                        <span id='erro9' >Campo Invalido</span>
                                    </div>

                                    <div className='continpuspanregistrar'>
                                        <span>Cidade:</span>
                                        <input type='text' className='inputmdform' value={this.state.pcidade} />
                                        <span id='erro10'>Campo Invalido</span>
                                    </div>


                                    <div className='continpuspanregistrar'>
                                        <span>Estado:</span>
                                        <input type='text' className='inputmdform' value={this.state.pestado} />
                                        <span id='erro11'>Campo Invalido</span>
                                    </div>

                                    <div className='continpuspanregistrar'>
                                        <span>Numero:</span>
                                        <input type='number' className='inputmdform' value={this.state.pnumerocasa} onChange={e => this.setState({ pnumerocasa: e.currentTarget.value })} />
                                        <span id='erro15'>Campo Invalido</span>
                                    </div>

                                    <div className='continpuspanregistrarlg'>
                                        <span>Endereco:</span>
                                        <input type='text' className='inputlgform' value={this.state.pendereco} />
                                        <span id='erro12'>Campo Invalido</span>
                                    </div>


                                    <div className='continpuspanregistrar'>
                                        <span>Bairro:</span>
                                        <InputMask type='text' className='inputmdform' value={this.state.pbairro} />
                                        <span id='erro13'>Campo Invalido</span>
                                    </div>



                                    <div className='continpuspanregistrar'>
                                        <span>Complemento:</span>
                                        <InputMask type='text' className='inputmdform' value={this.state.pcomplemento} onChange={e => this.setState({ pcomplemento: e.currentTarget.value })} />
                                        <span id='erro14'>Campo Invalido</span>
                                    </div>

                                </div>
                            </div>

                            <div className={validForm2 ? 'butaoregistrar_se_ativo' : 'butaoregistrar_se'} onClick={validForm2 ? async () => { this.Adicionar_Endereco(); } : () => { }}> Finalizar Cadastro </div>

                        </div>

                    </div>
                </Modal>
                <HeaderLoja redirecionar={this.redirecionar} />
                <div>
                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                </div>
                <div className='row carinhoinverso'>
                    <div className='col-12 titulocarrinho'>Carrinho</div>

                    <div className='col-0 col-md-1 col-lg-1' />
                    <div className='col-12 col-md-7 col-lg-7'>
                        <span className='optionstitulos'>
                        </span>
                        <div className='primeirocontainercarrinho'>
                            <div className='containerenderecos'>

                                <span className='tituloescolhatipo'> Escolha o tipo de entrega </span>
                                <div className='containerlinhaentregar_retirar'>

                                    <div className={this.state.formaentrega == 0 ? 'contlinhas ativo' : 'contlinhas'} id='formaentrega0' onClick={() => this.mudaropcaoentrega1()}>
                                        <span>Entregar Em Casa</span>
                                        <div className='linhacolorir'>
                                            <div></div>
                                        </div>
                                        <i className="fas fa-house-user"></i>
                                    </div>

                                    <div className={this.state.formaentrega == 1 ? 'contlinhas ativo' : 'contlinhas'} id='formaentrega1' onClick={() => this.mudaropcaoentrega0()}>
                                        <span>Retirada na Loja</span>
                                        <div className='linhacolorir'>
                                            <div></div>
                                        </div>
                                        <i className="fas fa-store"></i>
                                    </div>


                                </div>

                                {this.state.formaentrega == 0 &&
                                    <div className='geralseleciona_endereco'>
                                        {this.state.enderecos.map((enderec, index) => {
                                            if (index == 0) {

                                                return (
                                                    <div className='containerselecionaendereco' id={`option${enderec.Chave}`} key={enderec.Chave} onClick={async () => { await this.setState({ enderecoSelecionado: enderec, valueendereco: enderec.Chave }); this.destacarendereco(); this.calcularFrete() }}>

                                                        <div className='containerseleciona'>

                                                            <input type='radio' name='endereco' value={enderec.Chave} onClick={async () => { await this.setState({ enderecoSelecionado: enderec, valueendereco: enderec.Chave }); this.calcularFrete() }} checked={enderec.Chave == this.state.enderecoSelecionado.Chave} />

                                                        </div>

                                                        <div className='cntendereco'>

                                                            <span>{enderec.Endereco + ' ,' + enderec.Numero + ' ' + enderec.Complemento}</span>

                                                            <div className='contabarriocity'>

                                                                <span> {enderec.bairro}</span>

                                                                <span>-</span>

                                                                <span>{enderec.Cidade_Descricao + ' - ' + enderec.UF}</span>

                                                            </div>

                                                        </div>

                                                    </div>





                                                )

                                            } else {
                                                return (
                                                    <div className='containerselecionaendereco' id={`option${enderec.Chave}`} key={enderec.Chave} onClick={async () => { await this.setState({ enderecoSelecionado: enderec, valueendereco: enderec.Chave }); this.destacarendereco(); this.calcularFrete() }} /*onEnded={console.log('aaaaa')}*/ on>

                                                        <div className='containerseleciona'>

                                                            <input type='radio' name='endereco' value={enderec.Chave} onClick={async () => { await this.setState({ enderecoSelecionado: enderec, valueendereco: enderec.Chave }); this.calcularFrete() }} checked={enderec.Chave == this.state.enderecoSelecionado.Chave} />

                                                        </div>

                                                        <div className='cntendereco'>

                                                            <span>{enderec.Endereco + ' ,' + enderec.Numero + ' ' + enderec.Complemento}</span>

                                                            <div className='contabarriocity'>

                                                                <span> {enderec.bairro}</span>

                                                                <span>-</span>

                                                                <span>{enderec.Cidade_Descricao + ' - ' + enderec.UF}</span>

                                                            </div>

                                                        </div>

                                                    </div>)
                                            }

                                        })}

                                    </div>

                                }
                                {this.state.formaentrega == 0 &&
                                    <div className='adicionarmaisenderecos' onClick={async () => await this.setState({ modalAberto: true })}>
                                        <span>Adicionar Mais Endereços</span>
                                    </div>
                                }
                                {!this.state.formaentrega == 0 &&
                                    <div className='containerselecionaendereco'>

                                        <div className='containerseleciona'>

                                            <input type='radio' checked />

                                        </div>

                                        <div className='cntendereco'>

                                            <span>R. Santos Dumont, 663 </span>

                                            <div className='contabarriocity'>

                                                <span> Centro</span>

                                                <span>-</span>

                                                <span> Pelotas-RS</span>

                                            </div>

                                        </div>


                                    </div>
                                }

                            </div>

                            <form className='containerformaspagamento'>
                                {this.state.condicoes.map((cond, index) => (
                                    <div className='containerinpuformaspagamento' onClick={async (a) => { await this.setState({ formapagamento: cond }); }} key={cond.chave}>
                                        <div className='cttico_nome'>

                                            <i class={this.state.icones[index] ? this.state.icones[index] : 'fas fa-credit-card'}></i>

                                            <span>{cond.descricao}</span>

                                        </div>
                                        <input type='radio' name='formapagamento' value={cond.chave} onClick={async (a) => { await this.setState({ formapagamento: cond }); }} checked={cond.chave == this.state.formapagamento.chave} />
                                    </div>



                                ))}





                            </form>

                            <div className='containerfinalizarcarrinho'>
                                <div className='escolhasfinalizarcarrinho'>
                                    <span>{this.state.formaentrega == 1 ? 'Retirada na Loja' : 'Local de entrega'}</span>
                                    <div className='containeralterarfinalizacarrinho'>
                                        <div>
                                            <i class="fas fa-location-arrow"></i>
                                            {this.state.formaentrega == 0 && <span>{(this.state.enderecoSelecionado.Endereco) ? this.state.enderecoSelecionado.Endereco + ' , ' + this.state.enderecoSelecionado.Numero + ' ' + this.state.enderecoSelecionado.Complemento + ' , ' + this.state.enderecoSelecionado.bairro + ' , ' + this.state.enderecoSelecionado.Cidade_Descricao : ""}</span>}
                                            {this.state.formaentrega == 1 && <span>R. Santos Dumont, 663, Centro, Pelotas-RS</span>}
                                        </div>
                                    </div>

                                </div>

                                <div className='escolhasfinalizarcarrinho'>
                                    <span>Forma de Pagamento</span>
                                    <div className='containeralterarfinalizacarrinho'>
                                        <div>
                                            <i class="far fa-credit-card"></i>
                                            <span>{this.state.formapagamento.descricao}</span>
                                        </div>
                                    </div>

                                </div>

                                {/* {this.state.formaentrega == 0 &&

                                    <div className='containertipodeentrega'>

                                        <span>Tipo de Entrega</span>

                                        <select name="select" value={this.state.entregaSelecionada.chave} onChange={async (a) => { this.setState({ entregaSelecionada: a.target.value }); console.log(this.state.entregaSelecionada) }}>
                                            <option value='0'> Escolha uma opção</option>
                                            {this.state.tiposEntrega.map((elemento) => {
                                                if (elemento.chave != 8) {
                                                    return (<option value={elemento.chave}>{elemento.descricao}</option>)

                                                }


                                            })}



                                        </select>

                                    </div>
                                } */}

                                {this.state.formaentrega == 0 && this.state.entregaSelecionada == 8 &&

                                    <div className='containertipodeentrega'>

                                        <span>Horario De Entrega</span>

                                        <select name="select2" value={this.state.horarioSelecionado.chave} onChange={async (a) => { this.setState({ horarioSelecionado: a.target.value }); /*console.log(this.state.horarioSelecionado)*/ }}>
                                            <option value='0'> Escolha uma opção</option>
                                            {this.state.rotas.map((elemento) => {
                                                let data_agora = new Date();
                                                let teste = moment();
                                                teste.locale('pt-br');


                                                let horafull = teste.format('HH:mm').toString();
                                                //console.log(horafull)
                                                let hora_minute_momente = parseInt(horafull.split(':').join(''))
                                                let diferenca1 = 930 - hora_minute_momente
                                                let diferenca2 = 1700 - hora_minute_momente
                                                //console.log(hora_minute_momente)
                                                if (elemento.chave != 2) {

                                                    if (elemento.chave == 3 && diferenca1 > 0) {
                                                        return (<option value={elemento.chave}>{elemento.descricao}</option>)
                                                    }

                                                    if (elemento.chave == 4 && diferenca2 > 0) {
                                                        return (<option value={elemento.chave}>{elemento.descricao}</option>)
                                                    }
                                                    if (elemento.chave == 5) {
                                                        return (<option value={elemento.chave}>{elemento.descricao}</option>)
                                                    }


                                                }


                                            })}



                                        </select>

                                    </div>


                                }

                            </div>







                            <div className='containerlistaprodutos'>
                                <span>Seus Produtos</span>
                                <div className='row headerlistaprodutos'>
                                    <div className='col-4'>Produto</div>
                                    <div className='col-2'>Valor Unitário</div>
                                    <div className='col-2'>Quantidade</div>
                                    <div className='col-2'>Total</div>
                                    <div className='col-2'>Remover</div>

                                </div>
                                <div className='listprodutoscarrinho'>

                                    {this.props.carrinho.map((produto, index) => (
                                        <div className='row containerprodutocarrinho'>
                                            <div className='col-4 containerimgprodutocarrinho'>
                                                <div className='imgs_carrinho_watermark'>
                                                    <img className='placeholderImg4 img-fluid' src={CAMINHO_FOTOS + Util.completarProduto(produto.chave)} onLoad={(e) => {
                                                        e.currentTarget.src = require('../../images/watermark.jpg')
                                                        e.currentTarget.style.display = 'block';
                                                    }} onError={(e) => {
                                                        e.currentTarget.style.display = 'none';
                                                    }} alt=''></img>
                                                    <img src={CAMINHO_FOTOS + Util.completarProduto(produto.chave)}
                                                        onError={(e) => {
                                                            e.currentTarget.src = require('../../images/semimagem.png')
                                                        }} className='img-fluid' alt={produto.descricao} />
                                                </div>
                                                <span> {produto.Descricao} </span>
                                            </div>

                                            <div className='col-2 valorprodutocarrinho'>
                                                <span>{produto.valor || produto.Valor ? (produto.valor ? produto.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : produto.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })) : produto.Preco_Venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </span>
                                            </div>

                                            <div className='col-2 quantidadeprodutocarrinho'>
                                                <input
                                                    value={produto.quantidade}
                                                    onChange={(event) => this.alterarQuantidade(event.currentTarget.value, produto)}
                                                />
                                            </div>

                                            <div className='col-2 totalprodutocarrinho'>
                                                <span> {produto.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </span>
                                            </div>

                                            <div className='col-2 removerprodutocarrinho' onClick={() => this.removerItem(index, produto.total, produto)} >
                                                <input type='text' value='X' disabled="disabled" readonly />
                                            </div>
                                        </div>


                                    ))}

                                </div>



                            </div>

                        </div>




                    </div>


                    <div className='col-12 col-md-3 col-lg-3'>
                        <div className='segundocontainercarrinho'>

                            <div className='containersuacompra'>
                                <span>Sua Compra:</span>
                                {/* {console.log(this.state)} */}
                                {this.state.formaentrega != 1 &&
                                <div>
                                    <div className='cttsuacompra'>
                                        <span>Taxa De Entrega:</span>
                                        <span>
                                            {(this.props.total) > 200 && this.state.valorfrete != 0 &&
                                                <s>{this.state.valorfrete.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</s>
                                            } {this.props.total <= 200 && this.state.valorfrete != 0 &&
                                                <span>{this.state.valorfrete.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                            } {this.state.valorfrete == 0 &&
                                                <span><a  className='link' onClick={()=> window.open('https://api.whatsapp.com/send?phone=555333051473', "_blank")} >Consulte o vendedor</a> 
                                                </span>
                                            }</span>
                                    </div>

                                {(this.props.total) > 200 && this.state.valorfrete != 0 &&
                                    <div className='cttsuacompra'>
                                        <span></span>
                                        <span>Frete grátis!</span>
                                    </div>}
                                    </div>
                                }
                                <div className='cttsuacompra'>
                                    <span>Total Dos Produtos:</span>
                                    <span>{this.props.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>

                                <div className='Total'>
                                    <span>Total</span>
                                    <span>{this.props.total > 200 ? (this.props.total /*+ this.state.desconto_frete*/).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : (this.props.total + this.state.valorfrete /*+ this.state.desconto_frete*/).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                </div>
                                <div className={validForm3 && !this.state.botaoBloqueado ? 'butaosuacompra' : 'butaosuacompra_desativo'} disabled={this.state.botaoBloqueado} onClick={validForm3 ? () => this.finalizarCompra() : () => { }}>
                                    Finalizar Compra
                                </div>

                            </div>




                        </div>



                    </div>
                    {this.state.redireciona &&
                        <Redirect to={'/'} />
                    }

                    <div className='col-0 col-md-1 col-lg-1' />
                </div>
                <Rodape />
            </div>
        )
    }
}

const mapStateToProps = ({ produtos, user, empresa }) => {
    return {
        total: produtos.totalVenda, carrinho: produtos.produtos, frete: produtos.frete,
        empresa: empresa.empresa,
        usuario: user.email,
        chave: user.chave,
        nome: user.nome
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLimpar: () => dispatch(limpar()),
        onRemoverItem: (item) => dispatch(remove(item)),
        onFrete: (frete) => dispatch(selecionaFrete(frete)),
        onRemoveFrete: () => dispatch(removeFrete()),
        onAlterarQuantidade: (item) => dispatch(alterar(item))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Carrinho)
