import React, {Component} from 'react'
import api from '../../services/api'
import apiWeb from '../../services/apiWeb'
import util from '../../classes/util'
import semImagem from '../../img/products/no_image.jpg'
import './styles.css'
import { requirePropFactory } from '@material-ui/core'
import {add} from '../../store/actions/produto'
import {Link, useHistory, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const estadoInicial = {
    produtos: [],
    familias: [],
    paginacao: 0,
    paginas: [],
    tamanho: 0,
    pesquisa: '',
    porPagina: 12,
    pags: 0,
    grupo: null,
    pesq: '',
    chavesProdutos: [],
    redirect: false
}

class ListaProdutos extends Component {
    
    state = {
        ...estadoInicial
    }

    componentDidMount = async () => {
        await this.carregaFamilias()
        await this.carregaProdutos()
        await this.carregaPaginas()
        await this.setState({chavesProdutos: this.props.carrinho.map(function(e) { return e.chave})})
    }


    componentDidUpdate = async (prevProps, prevState) => {
       
        if(this.state.paginacao !== prevState.paginacao){
            this.carregaProdutos()
        }else if(this.state.grupo !== prevState.grupo){
            await this.carregaProdutos()
            await this.setState({paginacao: 0})
        }else if(this.props.pesq !== prevState.pesq){
            
            await this.setState({pesq: this.props.pesq, produtos: []})
            const empresa = this.props.empresa ? this.props.empresa : 0
            console.log('empresa: ' + empresa + ', pesquisa: ' + this.state.pesq)
            await api.post('produtospesquisa', {
                descricao: this.state.pesq,
                empresa: empresa
            }).then(async resp => {
                this.setState({tamanho: resp.data.length, produtos: resp.data, paginacao: 0})
                this.carregaPaginas()
            })
            /*let url = this.state.grupo ? `produtos/${empresa}/${this.props.pesq}/10000/0` : `produtos/${empresa}/10000/0`
            await api.get(url, {
            }).then(resp => {
                this.setState({tamanho: resp.data.length})
                this.carregaPaginas()
            })
            url = this.props.pesq ? `produtos/${empresa}/${this.props.pesq}/${this.state.porPagina}/${this.state.paginacao}` : `produtos/${empresa}/${this.state.porPagina}/${this.state.paginacao}`
            await api.get(url, {
                }).then(response => {
                this.setState({produtos: []})    
                this.setState({produtos: response.data})
                }
            )*/
            //alert(this.props.pesq)
        }
    }

    carregaProdutos = async () => {
        const empresa = this.props.empresa ? this.props.empresa : 0
        let url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/10000/0` : `produtos/${empresa}/10000/0`
        //console.log(url)
        await api.get(url, {
        }).then(resp => {
            this.setState({tamanho: resp.data.length})
            this.carregaPaginas()
        })
        url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/${this.state.porPagina}/${this.state.paginacao}` : `produtos/${empresa}/${this.state.porPagina}/${this.state.paginacao}`
        await api.get(url, {
            }).then(response => {
            this.setState({produtos: []})    
            this.setState({produtos: response.data})
            }
        )
        //await this.setState({produtos: this.embaralhar(this.state.produtos)})
    }

    embaralhar = (array) => {
        var m = array.length, t, i;
      
        // While there remain elements to shuffle…
        while (m) {
      
          // Pick a remaining element…
          i = Math.floor(Math.random() * m--);
      
          // And swap it with the current element.
          t = array[m];
          array[m] = array[i];
          array[i] = t;
        }
      
        return array;
    }

    carregaFamilias = async () => {
        const empresa = this.props.empresa ? this.props.empresa : 0
        await api.get(`familias/${empresa}`, {
            }).then(response => {
            this.setState({familias: response.data})
            }
        )
    }
    
    carregaPaginas = async () => {
        await this.setState({pags: Math.ceil((this.state.tamanho / this.state.porPagina))})
        let paginas = []
        for(let i=0;i<this.state.pags;i++){
            paginas.push(i)
        }
        await this.setState({paginas: []})
        await this.setState({paginas})
        //alert(this.state.pags)
    }
    
    mostrarChave = (chave) => {
        var chaveAlt = util.completarZerosEsquerda(chave, 8)
        console.log(chaveAlt)
   }

   addProduto = async (produto) => {
      
       
    let pos = await this.state.chavesProdutos.indexOf(parseInt(produto.chave))
    console.log(produto.chave)
    console.log(pos)
    console.log(this.state.chavesProdutos.map(function(e) { return e}))    
    
    if(pos == -1){
            
        let total = produto.Preco_Venda * 1
        let teste = produto
        teste.quantidade = 1
        teste.total = total
        await this.setState({produtoAdd: teste, total})
        this.props.onProduto({...this.state})   
        //alert(`${produto.Descricao} com 1 unidade adicionado!`)       
        await this.setState({chavesProdutos: [...this.state.chavesProdutos, produto.chave]})
		confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Espaço Acervo</h1>
                        <p>{produto.Descricao} com 1 unidade adicionado!</p>
                        <button 
								style={{marginRight: 5}}
								className="btn btn-success h-50 mx-1 px-auto"
								onClick={async () => {
									onClose()
								}}
							>
								Continuar comprando
							</button>
                            <button 
								style={{marginRight: 5}}
								className="btn btn-secondary h-50 mx-1 px-auto"
								onClick={async () => {
                                    onClose()
                                    await this.setState({redirect: true})
									
								}}
							>
								Ir para o carrinho
							</button>
                    </div>
                )
            }
        })
        //console.log(this.state.chavesProdutos)
        
        }else{
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='custom-ui'>
							<h1>Espaço Acervo</h1>
							<p>Falha ao adicionar o produto, já adicionado anteriormente!</p>
							<button 
								style={{marginRight: 5}}
								className="btn btn-success w-25"
								onClick={async () => {
									onClose()
								}}
							>
								Ok
							</button>
						</div>
					)
				}
			})
        }  
   }


    render(){
        return (
            <section className="latest-products spad">
                 {this.state.redirect &&
                    <Redirect to={{pathname: '/carrinho'}} />
                }
               <div className="">

                
                    <nav id="navbar_top" className="navbar navbar-expand-lg navbar-dark bg-darked" aria-label="navbarloja">
                    <a className="navbar-brand" href="#">
                        <img className="img-fluid  sublogo px-md-5" src={require("../../images/vilacavamobile.png")} alt="Vila Cava" />
                        </a>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarloja" aria-controls="navbarloja" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        
                    <div className="container-fluid">
                       
    
                
                        <div className="navbar-collapse collapse" id="navbarloja">
                        <ul className="navbar-nav ms-lg-4 me-auto mb-2 mb-lg-0">
                            <li className="nav-item active">
                                <a className="nav-link" href="#">Início <span className="sr-only">(Página atual)</span></a>
                            </li>
                            <li className="nav-item">
                                    <a className="nav-link" onClick={() => this.setState({grupo: null})} style={{cursor: 'pointer'}}>
                                        Todas
                                    </a>
                                </li> 
                            {this.state.familias.map(familia => (
                                <li className="nav-item" key={familia.Chave}>
                                    <a className="nav-link" onClick={() => this.setState({grupo: familia.Chave})} style={{cursor: 'pointer'}}>
                                        {familia.descricao_web ? util.capitalize(familia.descricao_web) : util.capitalize(familia.Descricao)}
                                    </a>
                                </li> 
                            ))}
                            
                        </ul>
                        </div>
                    </div>
                    </nav>
                             


 
                    <div className="container-fluid px-md-5 margin_top">
                    <div className="row" id="product-list">
                        {this.state.produtos.map(produto => (
                            <div key={produto.chave} className="col-12 col-md-3 rounded mx-auto mb-4">
                               <div className="padronizar"> 
                                <img className="img-fluid" src={util.urlExists(produto.chave)+`.jpg`}  onError={(e)=>{e.target.onerror = null; e.target.src=semImagem}} alt={produto.Descricao}/>
</div>
                                
                                <Link to={{pathname: `/detalhes/${this.props.empresa}/${produto.chave}`, state: produto.chave}}>    
                                    <div style={{display: 'hidden'}}></div>
                                </Link>
                                <div className="px-md-3 py-4 pl-2 bg-card">
                                    <h4 style={{color: '#fff'}} className="mt-2">{util.capitalize(produto.Descricao)}</h4>
                                    <h4 style={{color: '#fff'}} className="mt-2">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(produto.Preco_Venda)}</h4>
                                    <div className="mx-auto">
                                        <Link to={{pathname: `/detalhes/${this.props.empresa}/${produto.chave}`, state: produto.chave}}>    
                                            <button type="button" className="mt-2 ms-1 btn btn-outline-light">Detalhes</button>
                                        </Link>
                                        <button style={{marginLeft: 10}} onClick={() => this.addProduto({...produto})} type="button" className="px-4 ms-2 mt-2 btn btn-light">Comprar</button>
                                    </div>
                                </div>                            
                            </div>  
                        ))}
                </div>
                </div>
                <div className="product-filter">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                            <ul className="pagination justify-content-center">
                            <li style={{marginLeft: 10, marginRight: 10, cursor: 'pointer'}} className="prev fa fa-angle-double-left" onClick={() => this.setState({paginacao: 0})}>{''}</li>
                                <li style={{marginLeft: 10, marginRight: 10, cursor: 'pointer'}} className="prev fa fa-arrow-left" onClick={() => this.setState({paginacao: this.state.paginacao > 1 ? this.state.paginacao - 1 : 0})}>{''}</li>
                                    {this.state.paginas.map(pagina => {
                                        if(pagina + 1 < 10){
                                            return (
                                                <li className={pagina == this.state.paginacao + 1 ? "active" : "teste"} style={{marginLeft: 10, marginRight: 10, cursor: 'pointer'}} key={pagina} onClick={() => this.setState({paginacao: pagina})}>{(pagina + 1)}</li>
                                            )
                                        }else if( (pagina + 1) >  (this.state.paginacao - 5) && (pagina + 1) <= (5 + this.state.paginacao)){
                                            return (
                                                <li key={pagina} onClick={() => this.setState({paginacao: pagina})}>{(pagina + 1)}</li>
                                            )
                                        }
                                    })}
                                <li style={{marginLeft: 10, marginRight: 10, cursor: 'pointer'}} className="next fa fa-arrow-right" onClick={() => this.setState({paginacao: this.state.paginacao >= this.state.pags - 1 ? this.state.pags -1  :this.state.paginacao + 1})}>{''}</li>
                                <li style={{marginLeft: 10, marginRight: 10, cursor: 'pointer'}} className="next fa fa-angle-double-right" onClick={() => this.setState({paginacao: this.state.pags - 1})}>{''}</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>                
            </div>
        </section> 
        )
    }
}

const mapStateToProps = ({ pesquisa, produtos }) => {
    return {
        //total: produtos.totalProdutos,
        //empresa: empresa.empresa,
        pesq: pesquisa.pesquisa,
        carrinho: produtos.produtos,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onProduto: produto => dispatch(add(produto))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaProdutos)

/*
<div className="col-12 col-md-3 rounded mx-auto mb-4">
                        <img className="img-fluid" src="./images/promocoes/lifan.jpg" alt="Lifan X60 2019" />
                        <div className="px-md-3 py-4 bg-card">
                        <h5 className="mt-2">Lifan X60 Talent 1.8 2019</h5>
                        <h4 className="mt-2">R$ 59.641,00</h4>
                        <div className="mx-auto">
                            <button type="button" className="mt-2 ms-1 btn btn-outline-light">Detalhes</button>
                            <button type="button" className="px-4 ms-2 mt-2 btn btn-light">Comprar</button>
                        </div>
                        </div>
                    </div>







<div className="section-title">
                                    <h2>Departamentos</h2>
                                </div>
                                
                                
                                <div key={produto.chave} className="col-12 col-md-3 rounded mx-auto mb-4">
                            <div className="single-product-item">
                                
                                <Link to={{pathname: `/detalhes/${this.props.empresa}/${produto.chave}`, state: produto.chave}}>    
                                    <img className="img-fluid rounded thumbnail foto" src={util.urlExists(produto.chave)+`.jpg`}  onError={(e)=>{e.target.onerror = null; e.target.src=semImagem}} alt={produto.Descricao}/>
                                </Link>
                                <div className="product-text">
                                    <h6>{util.capitalize(produto.Descricao)}</h6>
                                    <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(produto.Preco_Venda)}</p>
                                    <button onClick={() => this.addProduto({...produto})} className="btn btn-secondary">Comprar</button>
                                </div>
                            </div>
                            </div> 
                            
                            
                                               <div className="product-filter">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
 
                                <ul className="product-controls">
                                <li onClick={() => this.setState({grupo: null})}>Todas</li>
                                    {this.state.familias.map(familia => (
                                        <li onClick={() => this.setState({grupo: familia.Chave})} key={familia.Chave}>{familia.descricao_web ? util.capitalize(familia.descricao_web) : util.capitalize(familia.Descricao)}</li> 
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>








                      <div id="carouselExampleDark" className="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" >
<div className="sliderimg1">   
 <div className="carousel-caption d-none d-md-block">
        <h5> slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
      </div> 
    </div>
    <div className="carousel-item" >
    <div className="sliderimg2">   
 <div className="carousel-caption d-none d-md-block">
        <h5>2 slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
      </div> 
    </div>
    <div className="carousel-item">
    <div className="sliderimg3">   
 <div className="carousel-caption d-none d-md-block">
        <h5>3 slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
      </div> 
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>  
                    */
