export default class Util {
    
    static completarZerosEsquerda(str){
        const length = str.length;
            const resto = length - String(str).length
            return '0'.repeat(resto > 0 ? resto : '0') + str
    }

    static completarProduto(str){
        str+=''
       let a = '000000'
       let b = str.length;
       let cortei = 6 - b
       let cortep = a.substr(0,cortei)
       let final = 'PD'+cortep+str+'.JPG'
       return(final)

    }

    static completarProdutoExtra(str, num){
        str+=''
       let a = '000000'
       let b = str.length;
       let cortei = 6 - b
       let cortep = a.substr(0,cortei)
       let final = 'PD'+cortep+str+num+'.JPG'
       return(final)

    }

    static limitarString(str, max) {
        if (str.length > max) {
            const newStr = str.slice(0, max-3) + "...";
            return newStr;
        } else {
            return str;
        }
    }

    static primeiroNome(entrada){
        let saida = entrada.split(' ')
        return saida[0]
    }

    static urlExists(chave){
        return 'http://187.86.147.193:8081/siacweb/arquivos/fotos/PD' + this.completarZerosEsquerda(chave, 6)
    }

    static urlClassificadoExists(chave){
        return 'http://187.86.147.193:8081/site/src/img/classificados/' + chave
    }

    static urlLogoExists(chave){
        return 'http://187.86.147.193:8081/site/src/img/' + chave + '.png'
    }
	
	static urlPromocaoExists(chave){
        return 'http://187.86.147.193:8081/siacweb/arquivos/promocoes/' + chave + '.png'
    }

    static capitalize(string){
        return string.substring(0, 1).toLocaleUpperCase() + string.substring(1).toLocaleLowerCase()
    }

    static testaCPF(strCPF) {
        let soma
        let resto
        let i
        soma = 0
        if (strCPF == "00000000000") return false
        
        for (i=1; i<=9; i++) soma = soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
        resto = (soma * 10) % 11
        
            if ((resto == 10) || (resto == 11))  resto = 0
            if (resto != parseInt(strCPF.substring(9, 10)) ) return false
        
        soma = 0
        for (i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
        resto = (soma * 10) % 11
        
        if ((resto == 10) || (resto == 11))  resto = 0
        if (resto != parseInt(strCPF.substring(10, 11) ) ) return false
        return true
    }

    
}