import validator from "validator"

export const MOSTRA_BANNERS = true
export const MOSTRA_PROMOCOES = false
export const MOSTRA_MARCAS = true
//export const CAMINHO_FOTOS = 'http://187.86.144.70:8081/siacweb/arquivos/fotos/'
//export const CAMINHO_CLASSIFICADOS = 'http://187.86.144.70:8081/siacweb/arquivos/classificados/'
//export const CAMINHO_FOTOS = 'http://192.168.15.150/espacoacervo/site/src/img'
//export const CAMINHO_CLASSIFICADOS = 'http://192.168.15.150/espacoacervo/site/src/img/classificados/'
//export const CAMINHO_PROMOCOES = 'http://192.168.15.150/espacoacervo/site/src/img/promocoes'
//export const CAMINHO_FOTOS = 'http://192.168.2.200/site/src/img'
//export const CAMINHO_CLASSIFICADOS = 'http://192.168.2.200/site/src/img/classificados/'
//export const CAMINHO_PROMOCOES = 'http://192.168.2.200/site/src/img/promocoes'
let CAMINHO_FOTOS = 'http://ciapecas.ddns.net:2243/fotos3/'

if (window.location.href.search('192.168.0.200') != -1) { CAMINHO_FOTOS = 'http://192.168.0.200:2243/fotos3/' }

export { CAMINHO_FOTOS };
export const CAMINHO_CLASSIFICADOS = 'http://187.86.147.193:8081/site/src/img/classificados/'
export const CAMINHO_PROMOCOES = 'http://187.86.147.193:8081/site/src/img/promocoes'
export const MULTI_EMPRESA = false