import React, {Component} from 'react'
import Styles from './styles.css'
import Modal from '@material-ui/core/Modal'
import Header from '../../components/header'
import {Link, Redirect} from 'react-router-dom'
import {server, showError, showSuccess} from '../../common'
import {login} from '../../store/actions/empresa'
import api from '../../services/api'
import semImagem from '../../img/products/no_image.jpg'
import {pesquisar} from '../../store/actions/pesquisa'
import {CAMINHO_FOTOS, CAMINHO_CLASSIFICADOS} from '../../config'
import util from '../../classes/util'

const estadoInicial = {
    classificado: [],
    url: '',
    chave: null,
    titulo: '',
    descricao: '',
    loading: true,
    id: null
}

class ClassificadoDetalhe extends Component {

    state = {
        ...estadoInicial
    }

    componentDidMount = async () => {
        var id = this.props.match.params.id
        await this.setState({id})
        await this.loadData(this.state.id)
    }



    loadData = async (produto) => {
        try{
            await api.get(`classificado/${produto}`, {
            }).then(async res => {
                await this.setState({url: res.data.url, chave: res.data.chave, titulo: res.data.titulo, descricao: res.data.descricao})
            })
        }catch(e){
            alert(e)
        }
    }

    render(){
        return (
            <div>
                <Header />
                <h1 style={{textAlign: 'center'}}>Detalhes</h1>
                <div key={this.state.chave} className="item">                           
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <h2 style={{textAlign: 'center'}}>{this.state.titulo}</h2>
                                <img src={util.urlClassificadoExists(this.state.url)}  onError={(e)=>{e.target.onerror = null; e.target.src=semImagem}} />
                                <h3 style={{textAlign: 'center'}}>{this.state.descricao}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClassificadoDetalhe
