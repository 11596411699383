import React, {Component} from 'react'
import Styles from './styles.css'
import Modal from '@material-ui/core/Modal'
import {Formik, Field, Form} from 'formik'
import {Link, Redirect} from 'react-router-dom'
import {server, showError, showSuccess} from '../../common'
import {login} from '../../store/actions/empresa'
import axios from 'axios'
import {pesquisar} from '../../store/actions/pesquisa'
import {CAMINHO_FOTOS, CAMINHO_CLASSIFICADOS} from '../../config'
import {connect} from 'react-redux'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const estadoInicial = {
    classificados: [],
    loading: true
}

class Classificados extends Component {

    state = {
        ...estadoInicial
    }

    componentDidMount = async () => {
        this.loadTiposClassificados()
    }

    loadTiposClassificados = async () => {
        try{
            const res = await axios.get(`${server}/tiposClassificados`)
            await this.setState({classificados: res.data})
            await this.setState({loading: false})
        }catch(e){
            showError(e)
        }
    }

    render(){
        return (
            <div>

                <h2 style={{textAlign: 'center'}}>Classificados</h2>
                <OwlCarousel
                    className="hero-slider"
                    loop
                    margin={20}
                    nav
                    items={2}
                >

                    {this.state.classificados.map(classificado => (
                        <div key={classificado.chave} className="item">
                            <Link to={{pathname: `/classificados/${classificado.chave}`}}>
                                <img style={{minWidth: 300, maxWidth: 400, minHeight: 200, maxHeight: 300}} src={require(`../../img/classificados/${classificado.url}`)} />
                            </Link>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <h2 style={{textAlign: 'center'}}>{classificado.descricao}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>

            </div>
        )
    }
}

export default Classificados
