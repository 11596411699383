import React, { Component } from 'react'
import './styles.css'
import util from '../../classes/util'
import HeaderLoja from '../../components/headerLoja'
import { Link, Redirect } from 'react-router-dom'
import { server, showError, showSuccess } from '../../common'
import semImagem from '../../img/products/no_image.jpg'
import axios from 'axios'
import api from '../../services/api'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import OwlCarousel from 'react-owl-carousel2';
import { Hidden, Drawer, Input } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Pagination from '@material-ui/lab/Pagination'
import listaProdutosCopy from '../listaProdutos copy'
import { connect } from 'react-redux'
import { add } from '../../store/actions/produto'
import Rodape from '../rodape'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { CAMINHO_FOTOS } from '../../config'
import Util from '../../classes/util'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ModalAlert from '../ModalAlert'

function filtroClassificados(chave) {
    return function filter(valor) {
        return valor.grupo1 == chave
    }
}


const estadoInicial = {
    classificados: [],
    classificadosn2: [],
    classificadosn3: [],
    itensClassificados: [],
    produtos: [],
    produtosfinal: [],
    produtodetalhe: [],
    chavesProdutos: [],
    produtoAdd: [],
    grupo1: 0,
    grupo2: 0,
    grupo3: 0,
    familia: [],
    familia2: [],
    totalItens: 0,
    itensPorPagina: 12,
    pagina: 1,
    loading: true,
    mobileOpen: false,
    open: false,
    quantidadeprodutos: 1,
    pesquisa: '',
    Redirect: false,
    Voltar: false,

    mostrarFooter: false,
    itemEscolhido: {},
}

class ListaProdutos extends Component {

    state = {
        ...estadoInicial,
        alertAberto: false,
        textoErro: ""
    }

    componentDidMount = async () => {
        await this.setState({ chavesProdutos: this.props.carrinho.map(function (e) { return e.chave }) })

        let pesquisa = "";
        if (this.props.location.state && this.props.location.state.pesquisa) {
            pesquisa = this.props.location.state.pesquisa;
        }
        if (this.props.location.state) {
            await this.setState({ familia: this.props.location.state.familia, familia2: this.props.location.state.familia2, pesquisa: pesquisa })
            await this.carregarprodutos2()
            await this.carregarprodutos()
            let totalItens = this.state.produtosfinal
                .filter((a) => this.filtrarfamilia1(a))
                .filter((a) => this.filtrarfamilia2(a))
                .filter((a) => this.filtrardescricao(a))
                .length
            await this.setState({ totalItens })


        } else {
            await this.setState({ Voltar: true })
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {

        /****** ALTERANDO A FAMÍLIA *******/
        if (this.props.location.state) {
            if (this.props.location.state.familia !== this.state.familia ||
                this.props.location.state.familia2 !== this.state.familia2
            ) {
                await this.setState({
                    familia: this.props.location.state.familia,
                    familia2: this.props.location.state.familia2,
                    pesquisa: '',
                    pagina: 1
                })
                let totalItens = this.state.produtosfinal
                    .filter((a) => this.filtrarfamilia1(a))
                    .filter((a) => this.filtrarfamilia2(a))
                    .filter((a) => this.filtrardescricao(a))
                    .length
                await this.setState({ totalItens })
            } else if (prevState.pesquisa !== this.state.pesquisa) {
                await this.setState({
                    familia: this.props.location.state.familia,
                    familia2: this.props.location.state.familia2,
                    pagina: 1
                })
                let totalItens = this.state.produtosfinal
                    .filter((a) => this.filtrarfamilia1(a))
                    .filter((a) => this.filtrarfamilia2(a))
                    .filter((a) => this.filtrardescricao(a))
                    .length
                await this.setState({ totalItens })
            }
        }
    }

    carregarImagensExtras = async (elemento) => {
        [...Array(8)].map((e, index) => {
            //console.log(Util.completarProdutoExtra(elemento, index + 1));
        })
    }


    addProduto = async (produtos) => {
        let pos = await this.state.chavesProdutos.indexOf(parseInt(produtos.chave))
        if (pos == -1) {
            let total = produtos.Preco_Venda * this.state.quantidadeprodutos
            if (produtos.valor) {
                total = produtos.valor * this.state.quantidadeprodutos
            }
            let teste = produtos
            teste.quantidade = this.state.quantidadeprodutos
            teste.total = total
            await this.setState({ produtoAdd: teste, total })
            this.props.onProduto({ ...this.state })
            await this.setState({ chavesProdutos: [...this.state.chavesProdutos, produtos.chave], open: false })

            confirmAlert({
                customUI: ({ onClose }) => {
                    if (this.props.usuario) {
                        return (

                            <div className='custom-ui2'>
                                <h1>CIA das Peças</h1>
                                <p>{produtos.Descricao} com {this.state.quantidadeprodutos} unidades adicionado!</p>


                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                    }}
                                >
                                    Continuar comprando
                                </button>
                                <button
                                    onClick={async () => { onClose(); await this.setState({ Redirect: true }) }}
                                    className="btn btn-secondary h-50 mx-1 px-auto" >

                                    Ir para o carrinho

                                </button>
                            </div>
                        )
                    } else {
                        onClose()
                    }
                }
            })
        } else {
            await this.setState({ open: false })
            confirmAlert({
                customUI: ({ onClose }) => {
                    if (this.props.usuario) {
                        return (

                            <div className='custom-ui2'>
                                <h1>CIA das Peças</h1>
                                <p>Falha ao adicionar o produto, já adicionado anteriormente!</p>


                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                    }}
                                >
                                    Continuar comprando
                                </button>
                                <button
                                    onClick={async () => { onClose(); await this.setState({ Redirect: true }) }}
                                    className="btn btn-secondary h-50 mx-1 px-auto" >


                                    Ir para o carrinho

                                </button>
                            </div>
                        )
                    } else {
                        onClose()
                    }
                }
            })
        }
        //alert(`${this.state.produtos[0].Descricao} com ${this.state.qtd} unidades adicionado!`)       
        ////console.log(this.state.chavesProdutos)
    }


    carregarprodutos = async () => {
        await api.get('todosprodutos', {
        }).then(
            async res => {
                let agora = await this.state.produtos
                agora.push(...res.data)
                await this.setState({ produtosfinal: agora, totalItens: agora.length })
            }
        )
    }


    carregarprodutos2 = async () => {
        await api.get('todosprodutos2', {
        }).then(
            async res => {
                await this.setState({ produtos: res.data })
            }
        )
    }

    filtrarfamilia1 = (produto) => {
        if (produto.Grupo1 == this.state.familia || !this.state.familia && !this.state.familia) {
            return true
        } else {
            return false
        }

    }

    filtrarfamilia2 = (produto) => {
        if (produto.Grupo2 == this.state.familia2 || this.state.familia2 == undefined) {
            return true
        } else {
            return false
        }

    }

    filtrardescricao = (produto) => {
        if (this.state.pesquisa == '') {
            return true
        } else {
            let texto = (produto.Descricao).toUpperCase();
            let textoObs = "";
            if (produto.Obs) {
                textoObs = (produto.Obs).toUpperCase();
            }
            let textopesquisa = (this.state.pesquisa).toUpperCase();
            textopesquisa = textopesquisa.split(' ');

            let retorno = true;
            let retornoObs = true;

            for (let i = 0; i < textopesquisa.length; i++) {
                if (textoObs.indexOf('' + textopesquisa[i]) == -1 && textopesquisa[i] != "") {
                    retornoObs = false;
                }
                if (texto.indexOf('' + textopesquisa[i]) == -1 && textopesquisa[i] != "") {
                    retorno = false;
                }
            }
            return (retorno || retornoObs);
        }

    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    carregaClassificados = async () => {
        //await alert(this.props.match.params.id)
        try {
            const res = await axios.get(`${server}/gruposclassificadosn1`)
            await this.setState({ classificados: res.data })
            await this.setState({ loading: false })
        } catch (e) {
            showError(e)
        }
    }

    carregaClassificadosNivelDois = async () => {

        await this.state.classificados.map(async (f, indice) => {
            let c = f.chave
            ////console.log(c)
            await api.post('gruposclassificadosn2', {
                chave: c
            }).then(
                async res => {
                    let temp = await this.state.classificadosn2
                    temp = await temp.concat(res.data)
                    //alert(temp)
                    await this.setState({ classificadosn2: [] })
                    await this.setState({ classificadosn2: temp })
                }
            )
        })
    }

    carregaClassificadosNivelTres = async () => {
        await api.get('gruposclassificadosadminn3').then(res => {
            this.setState({ classificadosn3: res.data })
        })
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    filtrodet3 = (produto) => {
        if (produto.Grupo1 == this.state.produtodetalhe.Grupo1) {
            return true
        } else {
            return false
        }
    }


    filtrodet1 = (produto) => {
        if (produto.Grupo2 == this.state.produtodetalhe.Grupo2) {
            //  console.log(produto.Grupo2)
            return true
        } else {
            return false
        }
    }

    filtrodet2 = (produto) => {
        if (produto.chave != this.state.produtodetalhe.chave) {
            return true
        } else {
            return false
        }
    }
    colocar_pontuacao = () => {
        let a = ''
        if (this.state.produtodetalhe.Preco_Venda) {
            //  console.log(this.state.produtodetalhe.Preco_Venda)
            a = (this.state.produtodetalhe.Preco_Venda).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }

        return a
    }


    renderMenuLateral() {
        return (
            <div>
                <h5>Selecione o tipo</h5>
                {this.state.classificadosn3.map(item => (
                    <p
                        style={{ cursor: 'pointer' }}
                        className="text-black mt-2 mb-2"
                        onClick={async () => {
                            await this.setState({ grupo3: item.chave })
                            this.handleDrawerToggle()
                            //alert('grupo 3: ' + this.state.grupo3)
                        }}
                    >
                        {item.descricao}
                    </p>
                ))}
            </div>
        )
    }

    render() {

        return (
            <div>

                {this.state.Redirect && <Redirect to="/carrinho" />}
                {this.state.Voltar && <Redirect to="/" />}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '5%',
                        paddingBottom: '5%',
                        overflow: 'scroll'
                    }}
                    open={this.state.open}
                    onClose={async () => await this.setState({ open: !this.state.open })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className='modalcontainerlistprod'>
                            <div className='containersairlistprodmodal'>
                                <div className='butaosairmodallistprod' onClick={async () => await this.setState({ open: !this.state.open })}>
                                    X
                                </div>
                            </div>
                            <div className='containerprodutomodal'>
                                <div className='imgprodutomodal'>
                                    <img className='placeholderImg2' src={CAMINHO_FOTOS + Util.completarProduto(this.state.produtodetalhe.chave)} onLoad={(e) => {
                                        e.currentTarget.src = require('../../images/watermark.jpg')
                                    }} onError={(e) => {
                                        e.currentTarget.remove();
                                    }} alt=''></img>
                                    <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.produtodetalhe.chave)}
                                        onError={(e) => {
                                            e.currentTarget.src = require('../../images/semimagem.png')
                                        }} className='img-fluid imgprodutolistaprod' alt='12' />
                                </div>
                                <span className='titulolistprod'>{this.state.produtodetalhe.Descricao}</span>
                                <span className='prodObs'>{this.state.produtodetalhe.Obs}</span>
                                <span className='valorlistprod'> {this.state.produtodetalhe.valor ? this.state.produtodetalhe.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : (this.colocar_pontuacao())}</span>
                                <div className='contadormaisemenosmodal'>
                                    <i class="fas fa-minus" onClick={async () => {
                                        if (this.state.quantidadeprodutos <= 100 && this.state.quantidadeprodutos >= 2) {
                                            await this.setState({ quantidadeprodutos: this.state.quantidadeprodutos - 1 })

                                        }
                                    }
                                    }
                                    >

                                    </i>
                                    <input type='number' id='modalnumber ' onChange={this.state.quantidadeprodutos = Math.min(this.state.quantidadeprodutos, this.state.produtodetalhe.Disp_It)} maxLength={2} value={this.state.quantidadeprodutos} />
                                    <i class="fas fa-plus" onClick={async () => {
                                        if (this.state.quantidadeprodutos <= 99 && this.state.quantidadeprodutos >= 1) {
                                            await this.setState({ quantidadeprodutos: this.state.quantidadeprodutos + 1 })
                                        }
                                    }}
                                    >

                                    </i>

                                </div>
                                <span className='estoque'>Quantidade em estoque: {this.state.produtodetalhe.Disp_It}</span>
                            </div>

                            <div
                                className='butao_confirmarcompra_listprod'
                                onClick={() => this.addProduto(this.state.produtodetalhe)}
                            >
                                Adicionar ao carrinho
                            </div>

                            {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b))[0] &&

                                <div className='titulo_outrosprodutos_listprod'>Outros Produtos Relacionados</div>}
                            {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b))[0] &&

                                <div className='row' style={{ width: '100%' }}>
                                    <div className='col-2 area_buttons3'>
                                        <div onClick={() => this.refs.car.prev()} >
                                            <i class="fas fa-chevron-left"></i>
                                        </div>
                                    </div>


                                    <div className='col-8'>
                                        <OwlCarousel ref="car" options={{ items: 2, rewind: true, autoplay: false, margin: 0, dots: false, center: false }}>
                                            {this.state.produtosfinal.filter((b) => this.filtrodet3(b)).filter((b) => this.filtrodet1(b)).filter((b) => this.filtrodet2(b)).map((prod, index) => {

                                                return (
                                                    <div className='containerprod_outrosprodutos_listprod' onClick={async () => await this.setState({ quantidadeprodutos: 1, produtodetalhe: prod })}>
                                                        <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)}
                                                            onError={(e) => {
                                                                e.currentTarget.src = require('../../images/semimagem.png')
                                                            }} className='img-fluid imgprodutolistaprod2' alt={prod.descricao} />
                                                        <span className='tituloprod_outrosprodutos_listprod'>{prod.Descricao}</span>
                                                        <span className='valor_outrosprodutos_listprod'>{prod.valor ? prod.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : prod.Preco_Venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>

                                    </div>

                                    <div className='col-2 area_buttons3'>
                                        <div onClick={() => this.refs.car.next()} >
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>

                                </div>}
                        </div>
                    </Fade>
                </Modal>

                <HeaderLoja desativaPesquisa />

                <div>
                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                </div>
                <div className='row'>

                    <div className='col-12 containerpesquisalistaproduto'>
                        <div className='containerpesquisaprod'>
                            <input type="text" id="pesquisa" name="pesquisa" placeholder="pesquisar..." className="pesquisalistaproduto" onChange={e => { this.setState({ pesquisa: e.currentTarget.value }) }} value={this.state.pesquisa} />
                            <div className='contnome_icopesquisa'>
                                <i class="fas fa-search"></i>
                                <span>Buscar</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 containerpesquisalistaproduto'>
                        <div
                            style={{
                                marginTop: 10
                            }}
                        >
                            <Pagination
                                variant="text"
                                color="primary"
                                count={Math.ceil(this.state.totalItens / this.state.itensPorPagina)}
                                page={this.state.pagina}
                                onChange={(event, valor) => this.setState({ pagina: valor })}
                            />
                        </div>
                    </div>

                    <div className='col-0 col-md-1 col-lg-1'></div>
                    <div className='col-12 col-md-10 col-lg-10 cttlistaprod'>

                        {this.state.produtosfinal
                            .filter((a) => this.filtrarfamilia1(a))
                            .filter((a) => this.filtrarfamilia2(a))
                            .filter((a) => this.filtrardescricao(a))
                            .filter((item, indice) => {
                                return indice >= ((this.state.pagina - 1) * this.state.itensPorPagina) && indice <= (this.state.pagina * this.state.itensPorPagina) - 1
                            })
                            .map((prod, index) => {
                                return (

                                    <div className='containerprodutoslistaprod' onClick={() => { this.state.itemEscolhido.chave == prod.chave && this.state.mostrarFooter ? this.setState({ mostrarFooter: false }) : this.setState({ mostrarFooter: true, itemEscolhido: prod }) }}>
                                        <div className='containerprodutoslistaprod2'>
                                            <div className='placeholderImg'>
                                                <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)} onLoad={(e) => {
                                                    e.currentTarget.src = require('../../images/watermark.jpg')
                                                    e.currentTarget.style.display = 'block';
                                                }} onError={(e) => {
                                                    e.currentTarget.style.display = 'none';
                                                }} alt=''></img>
                                            </div>
                                            <img src={CAMINHO_FOTOS + Util.completarProduto(prod.chave)}
                                                onError={(e) => {
                                                    e.currentTarget.src = require('../../images/semimagem.png')
                                                }} className='img-fluid imgprodutolistaprod' alt={prod.descricao} />


                                            <span className='titulolistprod' title={prod.Descricao}>{Util.limitarString(prod.Descricao, 100)}</span>
                                            <span className='valorlistprod'>
                                                {
                                                    prod.valor ? prod.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : prod.Preco_Venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </span>
                                            <div className='modalpequenolistprod'>
                                                <Link className='buttaoemdiv' to={{ pathname: `/detalhaProduto`, state: { produto: prod } }} >
                                                    {/* <div
                                                    className='buttaoemdiv'
                                                    onClick={async () => {
                                                        navigation.navigate('/detalhaProduto', {
                                                            produto: prod
                                                        })
                                                            /*
                                                        await this.setState({
                                                            open: !this.state.open,
                                                            quantidadeprodutos: 1,
                                                            produtodetalhe: prod
                                                        });
                                                        //  console.log(prod)
                                                    }}
                                                > */}

                                                    <span class='adicionarAoCarrinho'>Mais informações</span>
                                                    {/* </div> */}
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                    </div>
                    <div className='col-0 col-md-1 col-lg-1'></div>
                </div>
                {this.state.itemEscolhido &&
                    <div className="divDescreveItem" style={this.state.mostrarFooter ? {} : { display: 'none' }}>
                        <div className="divDescreveItemImagem">
                            <div className='placeholderImg_descreveIem'>
                                <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.itemEscolhido.chave)} onLoad={(e) => {
                                    e.currentTarget.src = require('../../images/watermark.jpg')
                                    e.currentTarget.style.display = 'block';
                                }} onError={(e) => {
                                    e.currentTarget.style.display = 'none';
                                }} alt=''></img>
                            </div>
                            <img src={CAMINHO_FOTOS + Util.completarProduto(this.state.itemEscolhido.chave)}
                                onError={(e) => {
                                    e.currentTarget.src = require('../../images/semimagem.png')
                                }} className='img-fluid imgprodutolistaprod' alt={this.state.itemEscolhido.descricao} />
                        </div>
                        {window.innerWidth > 600 &&
                            <div className="divDescreveItemTitulo"><h4>{`${this.state.itemEscolhido.Descricao}`}</h4></div>
                        }
                        {window.innerWidth <= 600 &&
                            <div className="divDescreveItemTitulo">{`${this.state.itemEscolhido.Descricao}`}</div>
                        }
                        {window.innerWidth > 600 && this.state.itemEscolhido.Obs && 
                            <div className='divDescreveItemDescricao'><span dangerouslySetInnerHTML={{__html: this.state.itemEscolhido.Obs.split('<especificacao>')[1] ? this.state.itemEscolhido.Obs.split('<especificacao>')[1].replace('</especificacao>','') : this.state.itemEscolhido.Obs}}/></div>
                        }
                    </div>
                }
                <Rodape />
            </div>
        )

    }
}




const mapDispatchToProps = dispatch => {
    return {
        onProduto: produto => dispatch(add(produto))
    }
}

const mapStateToProps = ({ produtos, user }) => {
    return {
        carrinho: produtos.produtos,
        usuario: user.email,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ListaProdutos)

/*
<div className="col-1">
                        <Hidden 
                            smUp 
                            implementation="css"
                        >
                            <Drawer>
                                <IconButton
                                    onClick={() => alert('icone')}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Drawer>
                        </Hidden>
                        <Hidden 
                            xsDown 
                            implementation="css"
                        >
                            <Drawer
                                variant="permanent"
                                open
                            >
                                 <h5>Selecione o tipo</h5>
                                    {this.state.classificadosn3.map(item => (
                                        <p 
                                            style={{cursor: 'pointer'}}
                                            className="text-black mt-2 mb-2"
                                            onClick={async () => {
                                                await this.setState({grupo3: item.chave})
                                                alert('grupo 3: ' + this.state.grupo3)
                                            }}
                                        >{item.descricao}</p>
                                    ))}
                            </Drawer>
                        </Hidden>
                    </div>


*/ 