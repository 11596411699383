import axios from 'axios'

const apisandbox = axios.create({
    baseURL: 'https://api-sandbox.getnet.com.br/',
    headers:  {
        "Content-Type": `application/x-www-form-urlencoded`,
        "Authorization": 'Basic YjNmMmYyNWQtNmM2Zi00NDUxLThiNzEtNjQ5N2E3ZWZkZDgzOjhiNDBkODY4LTYwMzUtNDhmNy1hZDUzLTgyMTJjMjg3OGVjZg==' 
    },
    timeout: 5000
})

export default apisandbox;