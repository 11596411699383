const server = 'http://ftptrade.ddns.net:3001'

function showError(err) {
    if(err.response && err.response.data){
        alert(`Ocorreu um problema: Mensagem: ${err.response.data}`)
    }else{
        alert(`Ocorreu um problema: Mensagem: ${err}`)
    }
}

function showSuccess(msg){
    alert(`Sucesso! ${msg}`)
}

export {server, showError, showSuccess}