import React, {Component} from 'react'
import apilocal from '../../services/apilocal'
import apisandbox from '../../services/apisandbox'
import util from '../../classes/util'
import semImagem from '../../img/products/no_image.jpg'
import './styles.css'
import {Link, useHistory} from 'react-router-dom'


const estadoInicial = {
    produtos: [],
}

class Getnet extends Component {
    
    state = {
        ...estadoInicial
    }

    getnet = async () => {
        /*
        await this.setState({loading: true})
        let token = await apisandbox.post('auth/oauth/v2/token?scope=oob&grant_type=client_credentials', {
            headers:  {
                "Content-Type": `application/x-www-form-urlencoded`,
                "Authorization": 'Basic YjNmMmYyNWQtNmM2Zi00NDUxLThiNzEtNjQ5N2E3ZWZkZDgzOjhiNDBkODY4LTYwMzUtNDhmNy1hZDUzLTgyMTJjMjg3OGVjZg==' 
            }
         }).then(response => {
                if(response.status === 400){
                    return 'erro'
                }else{
                    return response.data.access_token
                } 
            }
        )
        console.log("Token: "+token)
        let file = new FormData()
        file.append('card_number', "5155901222280001")
        file.append("customer_id", "customer_21081826")
        let cartao = await apisandbox.post('v1/tokens/card?card_number=5155901222280001', file, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            if(response.status === 400){
                return 'erro'
            }else if (response.status === 401){
                return 'não autorizado'
            }else{
                return response.data.number_token
            }
        })
        console.log("Cartão: "+cartao)
        //alert(cartao)
        var c = new Cartao("Joao da Silva")
        c.setNumberToken(cartao)
        c.setBrand("Mastercard")
        c.setMonth("10")
        c.setYear("18")
        c.setSecurityCode("123")
        console.log(c)
        let autorizado = await apisandbox.post('v1/cards/verification', c, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            switch(response.status){
            case 200:
                return response.data.verification_id
                break
            case 400:
                return 'erro'
                break
            default:
                return 'erro'
            }
        })
        console.log(autorizado)
        await this.setState({loading: false})
        */
       await apilocal.get('android.php?method=getCondicoes', {
           headers: {
                "Content-Type": "application/json; charset=utf-8",
           }
        }).then(response => {
            this.setState({condTeste: response.data})
        })        
    }

    render(){
        return (
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 text-left">
                    <div className="site-btn clear-btn" onClick={() => this.getnet()}>Tokengetnet</div>
                </div>
            </div>
        )
    }
}

export default Getnet
                