import React, {Component} from 'react'
import api from '../../services/api'
import util from '../../classes/util'
import semImagem from '../../img/products/no_image.jpg'
import './stylesheet.css'
import { requirePropFactory } from '@material-ui/core'
import fs, { exists } from 'fs'
import {Link, useHistory, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Header from '../../components/header'
import Lojas from '../../components/lojas'
import Classificados from '../../components/classificados'
import Promocoes from '../../components/promocoes'
import { faDonate, faHome } from '@fortawesome/fontawesome-free-solid'
import { faCalendarCheck, faCashRegister, faHandshake, faPhotoVideo } from '@fortawesome/free-solid-svg-icons'
import promocoes from '../../images/promocoes.png'

const estadoInicial = {
    produtos: [],
    familias: [],
    paginacao: 0,
    paginas: [],
    tamanho: 0,
    grupo: null,
    produtosfinal: [],
    pagFiles: [{
        id: 1
    }],
    files: [],
    enviar: false,
    qtdFiles: 0
}

class Home extends Component {
    
    state = {
        ...estadoInicial
    }

    componentDidMount = async () => {
        await this.carregaFamilias()
        await this.carregaProdutos()
        await this.carregaPaginas()
        console.log(this.state.produtos);
    }


    componentDidUpdate = async (prevProps, prevState) => {
        if(this.state.paginacao !== prevState.paginacao){
            this.carregaProdutos()
        }else if(this.state.grupo !== prevState.grupo){
            this.carregaProdutos()
            this.setState({paginacao: 0})
        }
    }


    carregaProdutos = async () => {
        const empresa = 2
        let url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/10000/0` : `produtos/${empresa}/10000/0`
        await api.get(url, {
        }).then(resp => {
            this.setState({tamanho: resp.data.length})
            this.carregaPaginas()
        })
        url = this.state.grupo ? `produtos/${empresa}/${this.state.grupo}/${this.state.porPagina}/${this.state.paginacao}` : `produtos/${empresa}/${this.state.porPagina}/${this.state.paginacao}`
        await api.get(url, {
            }).then(response => {
            this.setState({produtos: []})    
            this.setState({produtos: response.data})
            }
        )
    }

    carregaFamilias = async () => {
        const empresa = 2
        await api.get(`familias/${empresa}`, {
            }).then(response => {
            this.setState({familias: response.data})
            }
        )
    }
    
    carregaPaginas = async () => {
        await this.setState({pags: Math.ceil((this.state.tamanho / this.state.porPagina))})
        let paginas = []
        for(let i=0;i<this.state.pags;i++){
            paginas.push(i)
        }
        await this.setState({paginas: []})
        await this.setState({paginas})
        //alert(this.state.pags)
    }
    
    mostrarChave = (chave) => {
        var chaveAlt = util.completarZerosEsquerda(chave, 8)
        console.log(chaveAlt)
   }

    handleUploadFile = async (e) => {
        await this.setState({files: [...this.state.files, e.target.files[0]]})
        await this.setState({qtdFiles: this.state.qtdFiles + 1})
        await this.setState({pagFiles: [...this.state.pagFiles, {id: this.state.qtdFiles}]})
        console.log(this.state.files[0])
        console.log(this.state.pagFiles)
    }

    render(){
        return (
            <div>
                <header className="mb-3">
                        <nav className="navbar navbar-light">
                            <div className="mx-auto mt-md-5">
                            <Link to={{pathname: `/`}} classname="navbar-brand">
                                    <img 
                                        className="img-fluid logo" 
                                        src={require('../../images/logo_acervo_home.png')} alt="Espaço Acervo" title="Logo" />
                                </Link>
                            </div>
                        </nav>
                </header>

                <article className="d-md-none">
                    <div className="container">
                        <div className="row">
                            <div className="mx-auto">
                                <div className="col-12 mb-3">
                                <Link to={{pathname: `/`}} className="d-block">
                                        <img 
                                            className="img-fluid rounded" 
                                            src={require('../../images/mobile/promocoes.png')}
                                            alt="Promoções" title="Promoções" />
                                    </Link>
                                </div>
                                <div className="col-12 mb-3">
                                    <Link to={{pathname: `/lojas`}} className="d-block">
                                        <img 
                                            className="img-fluid rounded" 
                                            src={require('../../images/mobile/lojas-online.png')} 
                                            alt="Lojas On-Line" 
                                            title="Lojas On-Line" 
                                        />
                                    </Link>
                                </div>
                                <div className="col-12 mb-3">
                                    <Link to={{pathname: `/`}} className="d-block">
                                        <img 
                                            className="img-fluid rounded" 
                                            src={require("../../images/mobile/premiacoes_embreve.png")} 
                                            alt="Premiações e Eventos" 
                                            title="Premiações e Eventos" />
                                    </Link>
                                </div>
                                <div className="col-12 mb-3">
                                    <Link to={{pathname: `/classificados`}} className="d-block">
                                        <img 
                                            className="img-fluid rounded" 
                                            src={require("../../images/mobile/parceiros_embreve.png")}  
                                            alt="Parceiros e classificados" 
                                            title="Parceiros e classNameificados" 
                                        />
                                    </Link>
                                </div>
                                <div className="col-12 mb-3">
                                    <Link to={{pathname: `/`}} className="d-block">
                                        <img 
                                            className="img-fluid rounded" 
                                            src={require("../../images/mobile/midia_embreve.png")} 
                                            alt="Mídia e Entreterimento" 
                                            title="Mídia e Entreterimento" 
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="d-none d-md-block mt-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-2 mb-3">
                                <Link to={{pathname: `/`}} className="d-block">
                                    <img 
                                        className="img-fluid rounded" 
                                        src={require(`../../images/promocoes.png`)}
                                        alt="Promoções"
                                        title="Promoções" />
                                </Link>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-2 mb-3">
                                <Link to={{pathname: `/lojas`}} className="d-block">
                                    <img 
                                        className="img-fluid rounded" 
                                        src={require(`../../images/loja-online.png`)} 
                                        alt="Lojas On-Line" 
                                        title="Lojas On-Line" 
                                    />
                                </Link>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-2 mb-3">
                                <Link to={{pathname: `/`}} className="d-block">
                                    <img 
                                        className="img-fluid rounded" 
                                        src={require(`../../images/premiacoes_eventos_embreve.png`)} 
                                        alt="Premiações e Eventos" 
                                        title="Premiações e Eventos" 
                                    />
                                </Link>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-2 mb-3">
                                <Link className="d-block" href="#" to={{pathname: `/`}}>
                                    <img 
                                        className="img-fluid rounded" 
                                        src={require(`../../images/classificados_embreve.png`)}
                                        alt="Parceiros e Classificados" 
                                        title="Parceiros e Classificados" 
                                    />
                                </Link>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-2 mb-3">
                                <Link className="d-block"  to={{pathname: `/`}}>
                                    <img 
                                        className="img-fluid rounded" 
                                        src={require(`../../images/midia_entretenimento_embreve.png`)}
                                        alt="Mídia e Entreterimento" 
                                        title="Mídia e Entreterimento" />
                                </Link>
                            </div>
                        </div>       
                    </div>
                </article>

                <footer>

                </footer>
            
                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossOrigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossOrigin="anonymous"></script>
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossOrigin="anonymous"></script>
        </div>
            
        )
    }
}

export default Home
