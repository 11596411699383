import React, { Component } from 'react'
import Styles from './styles.css'
import ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom'

//import Modal from '@material-ui/core/Modal'
import { Formik, Field, Form } from 'formik'
import bcrypt from 'bcryptjs'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { server, showError, showSuccess } from '../../common'
import { login, logout } from '../../store/actions/user'
import axios from 'axios'
import { pesquisar } from '../../store/actions/pesquisa'
import semImagem from '../../img/products/no_image.jpg'
import moment from 'moment'
import util from '../../classes/util'
import api from '../../services/api'
import CEP from 'cep-promise'
import apiWeb from '../../services/apiWeb';
import InputMask from 'react-input-mask';
import Modal from '@material-ui/core/Modal';
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import Badge from '@material-ui/core/Badge'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Util from '../../classes/util'
import ModalAlert from '../ModalAlert'

const customStyles = {
    content: {
        position: 'absolute',
        zIndex: 10000000,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 25,
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: '0px',
    }
}
const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge)

function filtroFamilias(chave) {
    return function filter(valor) {
        return valor.grupo1 == chave
    }
}


class HeaderLoja extends Component {

    state = {

        open: false,
        pnome: '',
        psobrenome: '',
        psenha: '',
        pconfirmar_senha: '',
        pcpf: '',
        ecpf: '',
        redireciona: false,
        pemail: '',
        ptelefone: '',
        pcelular: '',
        pestado: '',
        pcidade: '',
        pcep: '',
        pbairro: '',
        pendereco: '',
        pcomplemento: '',
        pnumerocasa: '',
        pconfirmarcpf: '',
        econfirmarcpf: '',
        pconfirmartelefone: '',
        pconfirmarcep: '',
        chave: 0,
        emp: 0,
        nome: null,
        pesquisa: '',
        empresa: [],
        modalAberto: false,
        alertAberto: false,
        textoErro: "",
        familian1: [],
        familian2: [],
        login: 1,
        username: '',
        senha: '',
        senhaRec: '',
        confirmaSenhaRec: '',
        tokenRec: '',
        apr1: false,
        apr2: false,
        apr3: false,
        apr4: false,
        apr5: false,
        registropage: 1
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevState.pnome != this.state.pnome) {
            let tamanho = this.state.pnome.trim().length
            if (tamanho > 2 && !this.state.pnome.search(/^[a-zA-Z\s]*$/)) {
                document.querySelector('#erro1').style.display = 'none'
            } else {
                document.querySelector('#erro1').style.display = 'block'
            }
        }
        if (prevState.psobrenome != this.state.psobrenome) {
            let tamanho = this.state.psobrenome.trim().length
            if (tamanho > 2 && !this.state.psobrenome.search(/^[a-zA-Z\s]*$/)) {
                document.querySelector('#erro2').style.display = 'none'
            } else {
                document.querySelector('#erro2').style.display = 'block'
            }
        }
        if (prevState.psenha != this.state.psenha) {
            let tamanho = this.state.psenha.trim().length
            if (tamanho > 5) {
                document.querySelector('#erro8').style.display = 'none'
            } else {
                document.querySelector('#erro8').style.display = 'block'
            }
        }
        if (prevState.pconfirmar_senha != this.state.pconfirmar_senha) {
            let tamanho = this.state.psenha.trim().length
            if (this.state.psenha == this.state.pconfirmar_senha && document.querySelector('#erro8').style.display == 'none' && this.state.pconfirmar_senha != '') {
                document.querySelector('#erro3').style.display = 'none'
                this.setState({ apr1: true })
            } else {
                document.querySelector('#erro3').style.display = 'block'
                this.setState({ apr1: false })
            }
        }
        if (prevState.pcpf != this.state.pcpf) {
            let numberPattern = /\d+/g;
            var cpflimpo = ''
            if (this.state.pcpf != '___.___.___-__' && this.state.pcpf != '') {
                let cpflimpo2 = this.state.pcpf.match(numberPattern)
                var cpflimpo = cpflimpo2.join('')
            }
            //console.log(cpflimpo)
            //console.log(util.testaCPF(cpflimpo))
            //console.log(this.state.pcpf)
            if (util.testaCPF(cpflimpo) && this.state.pcpf.length > 10) {
                this.buscacpf(cpflimpo)
            } else {
                document.querySelector('#erro4').style.display = 'block'
                this.setState({ apr2: false })
            }
        }
        if (prevState.ecpf != this.state.ecpf) {
            let numberPattern = /\d+/g;
            var cpflimpo = ''
            if (this.state.ecpf != '___.___.___-__' && this.state.ecpf != '') {
                let cpflimpo2 = this.state.ecpf.match(numberPattern)
                var cpflimpo = cpflimpo2.join('')
            }
            //console.log(cpflimpo)
            //console.log(util.testaCPF(cpflimpo))
            //console.log(this.state.econfirmarcpf)
            //console.log(this.state.ecpf)
            if (util.testaCPF(cpflimpo) && this.state.ecpf.length > 10) {
                this.buscacpfRepetido(cpflimpo)
            } else {
                document.querySelector('#erro7').style.display = 'block'
                this.setState({ apr2: true, pconfirmarcpf: '' })
            }
        }
        if (prevState.pemail != this.state.pemail) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(this.state.pemail)) {
                document.querySelector('#erro5').style.display = 'none'
                this.setState({ apr3: true })
                this.buscaemail(this.state.pemail)


            } else {
                document.querySelector('#erro5').style.display = 'block'
                this.setState({ apr3: false })
            }
        }

        if (prevState.ptelefone != this.state.ptelefone) {
            let numberPattern = /\d+/g;
            var cpflimpo = ''
            if (this.state.ptelefone != '(__) _ ____-____' && this.state.ptelefone != '') {
                let cpflimpo2 = this.state.ptelefone.match(numberPattern)
                var cpflimpo = cpflimpo2.join('')
            }
            //console.log(cpflimpo)
            if (cpflimpo.length > 10) {
                document.querySelector('#erro6').style.display = 'none'
                this.setState({ apr4: true, pconfirmartelefone: cpflimpo })
            } else {
                document.querySelector('#erro6').style.display = 'block'
                this.setState({ apr4: false })
            }
        }

        if (prevState.pcep != this.state.pcep) {
            let numberPattern = /\d+/g;
            var cpflimpo = ''
            if (this.state.pcep != '__.___-___' && this.state.pcep != '') {
                let cpflimpo2 = this.state.pcep.match(numberPattern)
                var cpflimpo = cpflimpo2.join('')
            }
            //console.log(cpflimpo)

            if (cpflimpo.length == 8 && this.buscarDados(cpflimpo)) {

                document.querySelector('#erro9').style.display = 'none'
                this.setState({ apr5: true, pconfirmarcep: cpflimpo })
            } else {
                document.querySelector('#erro9').style.display = 'block'
                this.setState({ apr5: false })
            }
        }



    }



    buscacpf = async (t) => {
        //console.log(t + "CPF")
        const cpf = await api.post('buscacpfcadastro', {
            cpf: t
        })
        console.log(cpf)
        if (cpf.data.login) {
            document.querySelector('#erro4').style.display = 'block'
            this.setState({ apr2: false })
        } else {
            //console.log("here")
            document.querySelector('#erro4').style.display = 'none'
            this.setState({ apr2: true, pconfirmarcpf: t })
            //console.log(this.state.pconfirmarcpf)
        }
    }

    buscacpfRepetido = async (t) => {
        const cpf = await api.post('buscacpfcadastro', {
            cpf: t
        })
        console.log(cpf)

        if (cpf.data.login) {
            document.querySelector('#erro7').style.display = 'none'
            this.setState({ apr2: true, econfirmarcpf: t })
        } else {
            document.querySelector('#erro7').style.display = 'block'
            this.setState({ apr2: false, econfirmarcpf: '' })
        }
    }

    buscaemail = async (t) => {
        const cpf = await api.post('buscaemail', {
            login: t
        })
        if (cpf.data.Chave) {
            document.querySelector('#erro5').style.display = 'block'
            this.setState({ apr3: false })
        } else {
            document.querySelector('#erro5').style.display = 'none'
            this.setState({ apr3: true })
        }
    }



    buscarDados = async (cep) => {
        try {
            var obj = await CEP(cep)
            //console.log(obj)
            var bairro = obj.neighborhood
            var endereco = obj.street
            var cidade = obj.city
            var uf = obj.state
            await this.setState({ pbairro: bairro, pendereco: endereco, pcidade: cidade, pestado: uf })
            return true
        } catch (e) {
            return false
        }

    }

    componentWillMount = async () => {
        await this.carregaEmpresa(this.props.empresa)
        await this.carregarfamiliasn1()
        await this.carregarfamiliasn2()
    }

    componentDidMount = async () => {
    }

    openModal = async () => {
        await this.setState({ modalAberto: true })
    }

    closeModal = async () => {
        await this.setState({ modalAberto: false })
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    esqueciSenha = async () => {
        if (this.state.econfirmarcpf) {
            const email = await api.post('/buscaemailporcpf', { cpf: this.state.econfirmarcpf })
            if (email.data) {
                this.setState({ login: 5 })

                await api.post(`/esqueci`, {
                    email: email.data.login,
                })
            } else {
                this.openAlert('CPF não cadastrado!')
            }
        } else {
            this.openAlert('CPF não cadastrado!')
        }
    }

    enviarToken = async () => {
        api.post('/validaToken', {
            token: this.state.tokenRec,
            cpf: this.state.econfirmarcpf
        }).then((res) => {
            if (res.data) {
                const tokenTime = res.data.senha_recuperada.slice(60);
                const tokenCode = res.data.senha_recuperada.slice(0,60);

                let currentTime = new Date();
                //console.log(this.state.tokenRec)
                if (currentTime < tokenTime) {
                    bcrypt.compare(this.state.tokenRec, tokenCode, (err, isMatch) => {
                        if(err || !isMatch){
                            return this.openAlert('Código inválido!')
                        }
                        this.setState({ login: 6 })
                    })
                } else {
                    this.openAlert('código expirado')
                }
            } else {
                this.openAlert('Cpf inválido')
            }
        })
    }

    trocarsenha = async () => {
        //console.log(this.state.senhaRec)
        api.post('/novaSenha', {
            senha: this.state.senhaRec,
            cpf: this.state.econfirmarcpf
        }).then((res) => {
            this.openAlert(JSON.stringify(res.data))
            this.openAlert('Senha alterada')
            setTimeout(() => this.setState({ redireciona: true }), 3000)
        })
    }

    carregarfamiliasn1 = async () => {
        await api.post('familiasnivelum', {
            empresa: 1
        }).then(
            async res => {
                await this.setState({ familian1: res.data })
            }
        )
    }

    carregarfamiliasn2 = async () => {
        await this.state.familian1.map(async (f, indice) => {
            let c = f.Chave
            await api.post('familiasn2', {
                chave: c,
                empresa: 1
            }).then(
                async res => {
                    let temp = await this.state.familian2
                    //alert('temporario: ' + temp)
                    temp = await temp.concat(res.data)
                    //alert(temp)
                    await this.setState({ familian2: [] })
                    await this.setState({ familian2: temp })
                }
            )
        })
    }

    enterPress(target) {
        if (target.charCode == 13 && this.state.pesquisa != "") {
            this.setState({ redireciona: true })
        }
    }





    carregaEmpresa = async (empresa) => {
        try {
            await api.get(`empresas/${this.props.empresa}`)
                .then(
                    async res => await this.setState({ empresa: res.data }),
                    async res => {
                        await apiWeb.get(`empresas/${this.props.empresa}`)
                            .then(
                                async res => await this.setState({ empresa: res.data }),
                                //async res => alert('Erro, tente novamente!')
                                async res => this.openAlert('Erro, tente novamente!')
                            )
                    }
                )

        } catch (e) {
            this.openAlert(e.response.data)
        }
    }

    pesquisar = async () => {
        await this.props.onPesquisar({ ...this.state })
    }

    enviar = async () => {
        //alert(this.state.pesquisa)
        await this.props.onPesquisar({ ...this.state })
    }



    handleOpen = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Espaço Acervo</h1>
                        <p>Faça Login no site!</p>
                        <Formik
                            initialValues={{
                                login: '',
                                senha: ''
                            }}
                            onSubmit={async values => {
                                await this.pesquisar()
                                //alert(JSON.stringify(values, null, 2))
                            }}
                        >
                            <Form className='contact-form'>

                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center">
                                        <Field className="form-control inpute" value={this.state.login} onChange={e => { this.setState({ login: e.currentTarget.value }) }} id="login" name="login" type="text" placeholder="email" />
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center">
                                        <Field className="form-control inpute" value={this.state.senha} onChange={e => { this.setState({ senha: e.currentTarget.value }) }} id="senha" name="senha" type="password" placeholder="senha" />
                                    </div>
                                </div>

                                <button
                                    style={{ marginRight: 5 }}
                                    className="btn btn-success h-50 mx-1 px-auto"
                                    onClick={async () => {
                                        onClose()
                                        await this.signin()
                                    }}
                                >
                                    Fazer login
                                </button>

                            </Form>

                        </Formik>


                    </div>
                )
            }
        })
    }


    nextpage = () => {
        let elemento = document.querySelector('#page1registro')
        ReactDOM.findDOMNode(elemento).classList.add('pt-page-moveToLeft')
        let elemento2 = document.querySelector('#page2registro')
        ReactDOM.findDOMNode(elemento2).classList.add('pt-page-current')
        ReactDOM.findDOMNode(elemento2).classList.add('pt-page-moveFromRight')
        setTimeout(() => {
            ReactDOM.findDOMNode(elemento).classList.remove('pt-page-current')
            ReactDOM.findDOMNode(elemento).classList.remove('pt-page-moveToLeft')
            ReactDOM.findDOMNode(elemento2).classList.remove('pt-page-moveFromRight')
        }, 600)
        //console.log('cliquei')
    }

    handleClose = () => {
        this.setOpen(false)
    }

    setOpen = (valor) => {
        this.setState({ open: valor })
    }

    testeCarrinho = () => {
        //alert(`Carrinho: ${this.props.total}`)
    }

    signin = async (email, senha) => {

        try {
            const res = await api.post(`/signin`, {
                login: email,
                senha: senha,
            })
            const res2 = await api.get(`/signin/${email}`)
            await this.setState({ chave: res2.data.Chave, nome: res2.data.Nome })
            axios.defaults.headers.common['Authorization'] = `bearer ${res.data.token}`
            await this.props.onLogin({ ...this.state })
            //alert('Login Realizado!')
            this.openAlert('Login Realizado!')
            this.closeModal()
        } catch (e) {
            this.openAlert(e.response.data)
        }
    }

    signOut = async () => {
        try {
            await this.props.onLogout({ ...this.state })
            //alert('Logout realizado!')
            this.openAlert('Logout realizado!')
            this.redirecionar();

        } catch (e) {
            this.openAlert("Erro")
        }
    }

    signup = async () => {
        try {
            const res = await api.post(`/verificapessoa`, {
                cpf: this.state.pconfirmarcpf,
                id: this.state.pemail
            })
            //console.log(res.data[0]["count(`chave`)"]);
            let cpf = res.data[0]["count(`chave`)"]
            //console.log(cpf)
            //return 1
            let nome_compleoto = this.state.pnome + ' ' + this.state.psobrenome
            if (cpf == 0) {
                let inclusao = moment().format('YYYY-MM-DD hh:mm:ss');
                //console.log(inclusao)
                //await this.setState({codigo: proximo})
                await api.post(`/signup`, {
                    Nome: nome_compleoto,
                    login: this.state.pemail,
                    Cnpj_Cpf: this.state.pconfirmarcpf,
                    senha: this.state.psenha,
                    confirmPassword: this.state.pconfirmar_senha,
                    endereco: this.state.pendereco,
                    numero: this.state.pnumerocasa,
                    complemento: this.state.pcomplemento,
                    cidade: this.state.pcidade,
                    uf: this.state.pestado,
                    bairro: this.state.pbairro,
                    fone: this.state.pconfirmartelefone,
                    cep: this.state.pconfirmarcep,
                    inclusao: inclusao
                })
                const resPes = await api.get(`/maxpessoa`)
                let proximo = resPes.data.chave
                await api.put(`/signup`, {
                    codigo: proximo,
                    chave: proximo
                })
                await api.post(`/email`, {
                    email: this.state.pemail,
                    usuario: this.state.pemail,
                    senha: this.state.psenha
                })
                this.openAlert('Usuário cadastrado!')
                await this.signin(this.state.pemail, this.state.psenha)
                //document.location.reload(true)
                //this.closeModal()
                //this.setState({login:1})
                //this.openModal()
            } else if (cpf == 1) {
                const response = await api.post('/pessoainfo', {
                    cpf: this.state.pconfirmarcpf
                })

                if (response.data.login == null) {
                    await api.post('/signupOldUser', {
                        login: this.state.pemail,
                        Cnpj_Cpf: this.state.pconfirmarcpf,
                        senha: this.state.psenha,
                        confirmPassword: this.state.pconfirmar_senha,
                        cidade: this.state.pcidade
                    })

                    await api.post(`/email`, {
                        email: this.state.pemail,
                        usuario: this.state.pemail,
                        senha: this.state.psenha
                    })

                    this.openAlert('Usuário cadastrado!')
                    await this.signin(this.state.pemail, this.state.psenha)
                } else {
                    this.openAlert("Falha no cadastro, já existe um usuario cadastrado com este login ou cpf/cnpj!")
                    this.closeModal()
                }
            } else {
                this.openAlert("Falha no cadastro, já existe um usuario cadastrado com este login ou cpf/cnpj!")
                this.closeModal()
            }
        } catch (e) {
            //console.log(e.response.data)
        }
    }

    redirecionar = () => {
        setTimeout(this.props.redirecionar, '1000');
    }

    handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            await this.signin(this.state.username, this.state.senha)
        }
    }

    body = (
        <div id="modalStyle" >
            <h2 id="simple-modal-title">Faça login no site!</h2>
            <Formik
                initialValues={{
                    login: '',
                    senha: ''
                }}
                onSubmit={async values => {
                    await this.pesquisar()
                    //alert(JSON.stringify(values, null, 2))
                }}
            >
                <Form className='contact-form'>

                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center">
                            <Field value={this.state.pemail} onChange={e => { this.setState({ pemail: e.currentTarget.value }) }} id="login" name="login" type="text" placeholder="email" />
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center">
                            <Field value={this.state.psenha} onChange={e => { this.setState({ psenha: e.currentTarget.value }) }} id="senha" name="senha" type="password" placeholder="senha" />
                        </div>
                    </div>

                    <Link className="button" to={{ pathname: '/carrinho' }}><button onClick={() => this.signin()} type="submit">Entrar</button></Link>

                </Form>

            </Formik>

        </div>
    )


    render() {

        const validations = []

        validations.push(!this.state.pnome.search(/^[a-zA-Z\s]*$/) && this.state.pnome.trim().length > 2)

        validations.push(!this.state.psobrenome.search(/^[a-zA-Z\s]*$/) && this.state.psobrenome.trim().length > 2)

        validations.push(this.state.psenha == this.state.pconfirmar_senha && this.state.apr1 == true)

        validations.push(this.state.apr2 == true && this.state.pcpf.length > 10)

        validations.push(this.state.apr3 == true && this.state.pemail.length > 5)

        validations.push(this.state.apr4 == true && this.state.ptelefone.length > 10)

        validations.push(this.state.psenha.trim().length > 5)

        const validForm = validations.reduce((t, a) => t && a)

        const validations2 = []

        validations2.push(this.state.pcep && this.state.pcep.trim().length == 10)

        validations2.push(this.state.pcidade && this.state.pcidade.trim().length >= 3)

        validations2.push(this.state.pendereco && this.state.pendereco.trim().length >= 2)

        validations2.push(this.state.pestado && this.state.pestado.trim().length >= 2)

        validations2.push(this.state.pbairro && this.state.pbairro.trim().length >= 2)

        validations2.push(this.state.pnumerocasa && toString(this.state.pnumerocasa).trim().length >= 1)

        const validForm2 = validations2.reduce((t, a) => t && a)


        const validations3 = []



        validations3.push(this.state.senhaRec == this.state.confirmaSenhaRec)

        validations3.push(this.state.senhaRec.trim().length > 5)

        const validForm3 = validations3.reduce((t, a) => t && a)


        return (
            <div className="bg">
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%', overflow: 'scroll' }}
                    open={this.state.modalAberto}
                    onClose={async () => await this.setState({ modalAberto: false })}
                >
                    <div className='modalcontainer1'>
                        {this.state.login == 1 &&
                            <div className='modalcontainerlogin'>
                                <div className='containersairlistprodmodal'>
                                    <div className='butaosairmodallistprod' onClick={async () => await this.setState({ modalAberto: !this.state.modalAberto })}>
                                        X
                                    </div>
                                </div>
                                <img src={require('../../images/logosemnumero_used.png')} className='img-fluid logologin2' />

                                <span className='titulologin'>LOGIN</span>


                                <div className='container_input_login'>

                                    <div className='container_tituloinput_login'>
                                        <span className='tituloinput_login'>Email</span>
                                        <input type='text' className='input_login' value={this.state.username} onChange={e => { this.setState({ username: e.currentTarget.value }) }} />
                                    </div>

                                    <div className='container_tituloinput_login'>
                                        <span className='tituloinput_login'>Senha</span>
                                        <input type='password' className='input_login' value={this.state.senha} onKeyPress={this.handleKeyPress} onChange={e => { this.setState({ senha: e.currentTarget.value }) }} />
                                    </div>

                                    <Link onClick={async () => this.setState({ login: 4 })}>
                                        <div className='container_esqueciSenha'>
                                            <span >Esqueceu sua senha?</span>
                                        </div>
                                    </Link>

                                    <div className='butao1logar' onClick={async () => await this.signin(this.state.username, this.state.senha)}>  Entrar   </div>

                                </div>





                            </div>}

                        {this.state.login == 0 &&
                            <div className='modalcontainerlogin'>
                                <div className='containersairlistprodmodal'>
                                    <div className='butaosairmodallistprod' onClick={async () => await this.setState({ modalAberto: !this.state.modalAberto })}>
                                        X
                                    </div>
                                </div>
                                <span className='tituloregistrarse'>Registrar-se</span>
                                <div className='container_registrar_registrar'>
                                    <div className='subtitulo_registrar'> {this.state.registropage == 1 ? 'Dados De Acesso' : 'Endereço'}</div>


                                    <div className='pt-perspective'>


                                        <div className='containeralinharinput pt-page pt-page-current' id='page1registro'>
                                            <div className='continpuspanregistrar'>
                                                <span>Nome:</span>
                                                <input type='text' className='inputmdform' value={this.state.pnome} onChange={e => this.setState({ pnome: e.currentTarget.value })} />
                                                <span id='erro1'>Nome não pode conter numeros e nem caracteres especiais</span>
                                            </div>


                                            <div className='continpuspanregistrar'>
                                                <span>Sobrenome:</span>
                                                <input type='text' className='inputmdform' value={this.state.psobrenome} onChange={e => this.setState({ psobrenome: e.currentTarget.value })} />
                                                <span id='erro2'>Sobrenome não pode conter numeros e nem caracteres especiais</span>
                                            </div>


                                            <div className='continpuspanregistrar'>
                                                <span>Senha:</span>
                                                <input type='password' className='inputmdform' value={this.state.psenha} onChange={e => this.setState({ psenha: e.currentTarget.value })} />
                                                <span id='erro8' >Senha tem quer ter no minimo 6 caracteres</span>
                                            </div>


                                            <div className='continpuspanregistrar'>
                                                <span>Confirmar Senha:</span>
                                                <input type='password' className='inputmdform' value={this.state.pconfirmar_senha} onChange={e => this.setState({ pconfirmar_senha: e.currentTarget.value })} />
                                                <span id='erro3'>Senhas nao conferem</span>
                                            </div>

                                            <div className='continpuspanregistrarlg'>
                                                <span>Email:</span>
                                                <input type='email' className='inputlgform' value={this.state.pemail} onChange={e => this.setState({ pemail: e.currentTarget.value })} />
                                                <span id='erro5'>Email inválido ou já utilizado</span>
                                            </div>

                                            <div className='continpuspanregistrar'>
                                                <span>CPF:</span>
                                                <InputMask type='text' mask='999.999.999-99' className='inputmdform' value={this.state.pcpf} onChange={e => this.setState({ pcpf: e.currentTarget.value })} />
                                                <span id='erro4'>Cpf inválido ou já utilizado</span>
                                            </div>

                                            <div className='continpuspanregistrar'>
                                                <span>Telefone:</span>
                                                <InputMask type='text' mask='(99) 9 9999-9999' className='inputmdform' value={this.state.ptelefone} onChange={e => this.setState({ ptelefone: e.currentTarget.value })} />
                                                <span id='erro6'>Numero Inválido</span>
                                            </div>

                                        </div>

                                        <div className='containeralinharinput pt-page' id='page2registro'>

                                            <div className='continpuspanregistrar'>
                                                <span>Cep:</span>
                                                <InputMask type='text' mask='99.999-999' className='inputmdform' value={this.state.pcep} onChange={e => this.setState({ pcep: e.currentTarget.value })} onBlur={e => this.buscarDados(e.currentTarget.value)} />
                                                <span id='erro9' >Cep Inválido </span>
                                            </div>

                                           <div className='continpuspanregistrar'>
                                                <span>Cidade:</span>
                                                <input type='text' className='inputmdform' value={this.state.pcidade} onChange={e => this.setState({ pcidade: e.currentTarget.value })}/>
                                                <span id='erro10'>Cidade Inválida </span>
                                            </div>


                                            <div className='continpuspanregistrar'>
                                                <span>Estado:</span>
                                                <input type='text' className='inputmdform' value={this.state.pestado} onChange={e => this.setState({ pestado: e.currentTarget.value })}/>
                                                <span id='erro11'>Estado Inválido</span>
                                            </div>

                                            <div className='continpuspanregistrar'>
                                                <span>Numero:</span>
                                                <input type='number' className='inputmdform' value={this.state.pnumerocasa} onChange={e => this.setState({ pnumerocasa: e.currentTarget.value })} />
                                                <span id='erro15'>Numero Inválido </span>
                                            </div>

                                            <div className='continpuspanregistrarlg'>
                                                <span>Endereco:</span>
                                                <input type='text' className='inputlgform' value={this.state.pendereco} onChange={e => this.setState({ pendereco: e.currentTarget.value })}/>
                                                <span id='erro12'>Campo Invalido</span>
                                            </div>


                                            <div className='continpuspanregistrar'>
                                                <span>Bairro:</span>
                                                <InputMask type='text' className='inputmdform' value={this.state.pbairro} onChange={e => this.setState({ pbairro: e.currentTarget.value })}/>
                                                <span id='erro13'>Campo Inválido</span>
                                            </div>



                                            <div className='continpuspanregistrar'>
                                                <span>Complemento:</span>
                                                <InputMask type='text' className='inputmdform' value={this.state.pcomplemento} onChange={e => this.setState({ pcomplemento: e.currentTarget.value })} />
                                                <span id='erro14'>Campo Inválido</span>
                                            </div>

                                        </div>
                                    </div>
                                    {this.state.registropage == 1 && <div className={validForm ? 'butaoregistrar_se_ativo' : 'butaoregistrar_se'} onClick={validForm ? async () => { this.nextpage(); this.setState({ registropage: 2 }) } : () => { }}> Seguinte </div>}
                                    {this.state.registropage == 2 && <div className={validForm2 ? 'butaoregistrar_se_ativo' : 'butaoregistrar_se'} onClick={validForm2 ? async () => { this.signup(); this.setState({ login: 3 }) } : () => { }}> Finalizar Cadastro </div>}

                                </div>

                            </div>}
                        {this.state.login == 3 &&
                            <ReactLoading color='red' type={'cylon'} height={200} width={200} />
                        }
                        {this.state.login == 4 &&
                            <div className='modalcontainerloginEsqueci'>
                                <div className='containersairlistprodmodal'>
                                    <div className='butaosairmodallistprod' onClick={async () => await this.setState({ modalAberto: !this.state.modalAberto })}>
                                        X
                                    </div>
                                </div>
                                <span className='tituloregistrarse'>Insira seu cpf para recuperarmos sua senha:</span>
                                <div className='container_registrar_esqueci'>
                                    <div className='continpuspanregistrar'>
                                        <span>CPF:</span>
                                        <InputMask type='text' mask='999.999.999-99' className='inputmdform' value={this.state.ecpf} onChange={e => this.setState({ ecpf: e.currentTarget.value })} />
                                        <span id='erro7'>Cpf inválido ou inexistente</span>
                                    </div>
                                    <div className='butao1logar' onClick={async () => await this.esqueciSenha(this.state.ecpf)}>  Recuperar Senha   </div>
                                </div>

                            </div>
                        }
                        {this.state.login == 5 &&
                            <div className='recSenha modalcontainerloginEsqueci'>

                                <div>
                                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                                </div>
                                <div className='titulo'>
                                    <p>Cheque seu email</p>
                                </div>
                                <div className='recSenhaInputs'>
                                <div className='RecSenhacasedadosusuario'>
                                    <span>Código:</span>
                                    <input type='text' onChange={async (e) => await this.setState({ tokenRec: e.target.value })} value={this.state.tokenRec} />
                                </div>
                                <div className={this.state.tokenRec.trim().length == 4 ? 'butaotrocarsenha' : 'butaotrocarsenhad'} onClick={e => this.enviarToken()} > Confirmar</div>
                                <div className='col-0 col-md-1 col-lg-1' />
                                </div>
                            </div>}

                        {this.state.login == 6 &&
                            <div className='recSenha modalcontainerloginEsqueci'>

                                <div>
                                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                                </div>
                                <div className='titulo'>
                                    <p>Alterar sua senha:</p>
                                </div>
                                <div className='recSenhaInputs'>
                                <div className='RecSenhacasedadosusuario'>
                                    <span>Nova Senha:</span>
                                    <input type='password' onChange={async (e) => await this.setState({ senhaRec: e.target.value })} value={this.state.senhaRec} />
                                </div>
                                <div className='RecSenhacasedadosusuario'>
                                    <span>Confirmar Senha:</span>
                                    <input type='password' onChange={async (e) => await this.setState({ confirmaSenhaRec: e.target.value })} value={this.state.confirmaSenhaRec} />
                                </div>
                                <div className={validForm3 ? 'butaotrocarsenha' : 'butaotrocarsenhad'} onClick={validForm3 ? () => this.trocarsenha() : {}} > Confirmar troca de senha</div>
                                <div className='col-0 col-md-1 col-lg-1' />
                                </div>
                            </div>}

                    </div>
                </Modal >


                <header className="mb-3">
                    <div className='backgroundheader2'>
                        <Link to={{ pathname: `/` }} className='linkimgheader'>
                            <img src={require('../../images/banner.jpg')} className='imgheader2'></img>
                        </Link>
                        {this.props.msg ? <span>{this.props.msg}</span> : ""}
                    </div>
                    <nav className="navbar navbar-expand-xl navbar-light " id='headernovoCia'>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#conteudoNavbarSuportado" aria-controls="conteudoNavbarSuportado" aria-expanded="false" aria-label="Alterna navegação">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="conteudoNavbarSuportado">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link to={{ pathname: `/` }} className="nav-link" style={{ marginRight: 45 }}>
                                        Inicio
                                    </Link>
                                    <span className={this.props.usuario ? "text-body" : "text-body mr-1 ml-1"}>{'|'}</span>
                                </li>
                                <li className="nav-item dropdown">

                                    <a className="nav-link dropdown-toggle categorias" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Produtos
                                        <i className="fas fa-bars"></i>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <div className="dropdown-item dropend">

                                            <Link
                                                to={{ pathname: `/listaprodutos`, state: { familia: null, pesquisa: "" } }}
                                                className="dropdown-item"
                                            >
                                                Todos

                                                <p style={{ color: 'white' }}></p>
                                            </Link>
                                        </div>

                                        {this.state.familian1.map((familia, indice) => {
                                            return (

                                                <div className="dropdown-item dropend" key={familia.Chave}>

                                                    <Link
                                                        to={{ pathname: `/listaprodutos`, state: { familia: familia.Chave } }}
                                                        className="dropdown-item"
                                                    >
                                                        {util.capitalize(familia.Descricao)}

                                                        <p style={{ color: 'white' }}></p>
                                                    </Link>
                                                    <ul className="dropdown-menu">
                                                        {this.state.familian2.filter(filtroFamilias(familia.Chave)).map(family => (
                                                            <Link
                                                                to={{ pathname: `/listaprodutos`, state: { familia: familia.Chave, familia2: family.Chave } }}
                                                                className="dropdown-item" key={family.Chave}
                                                            >

                                                                <p style={{ color: 'white' }}>{util.capitalize(family.Descricao)}</p>

                                                            </Link>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </li>
                            </ul>
                            <div className="containericonenomesairheader">
                                {this.props.usuario &&
                                    <li className="nav-item  containerconta">

                                        <Link

                                            className="nav-link"
                                            alt="Minha Conta"
                                            to={{ pathname: '/MinhaConta' }}
                                        >
                                            <span>Minha Conta</span>
                                            <i className="fas fa-user"></i>
                                        </Link>
                                        <span className={"text-body mr-2 ml-2"}>{'|'}</span>
                                    </li>
                                }

                                {!this.props.usuario &&
                                    <li className="nav-item  ">

                                        <Link
                                            className="nav-link btCadastro"
                                            onClick={async () => this.setState({ modalAberto: true, login: 0 })}> Cadastre-se</Link>
                                    </li>
                                }
                                {!this.props.usuario &&
                                    <div className="bt22 btLogin" onClick={async () => this.setState({ modalAberto: true, login: 1 })}>{'Login'}                                <span className={"text-body mr-2 ml-2"}>{'|'}</span> </div>
                                }

                                {!this.props.desativaPesquisa &&
                                    <li className="nav-item pesquisa">
                                        <div className='containerpesquisalistaproduto2'>
                                            <div className='containerpesquisaprod containerpesquisaprod2'>
                                                <input onKeyPress={e => this.enterPress(e)} type="text" id="pesquisa" name="pesquisa" placeholder="ex.: coxim Sandero" className="pesquisalistaproduto" onChange={e => { this.setState({ pesquisa: e.currentTarget.value }) }} value={this.state.pesquisa} />
                                                <div className='contnome_icopesquisa'>
                                                    <Link
                                                        to={{ pathname: `/listaprodutos`, state: { familia: null, pesquisa: this.state.pesquisa } }}
                                                    >
                                                        <i class="fas fa-search white"></i>
                                                    </Link>
                                                    {this.state.redireciona &&
                                                        <Redirect to={{
                                                            pathname: '/listaprodutos',
                                                            state: { familia: null, pesquisa: this.state.pesquisa }
                                                        }} />

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                }
                                <li className="nav-item ">
                                    {this.props.usuario &&
                                        <Link
                                            to={{ pathname: '/carrinho' }}
                                            className="nav-link carrinho"
                                            alt="Carrinho"
                                            style={{ display: 'flex', flexDirection: 'row' }}
                                        >
                                            <i className="fas fa-shopping-cart" ></i>
                                            <span className="itens">{this.props.total}</span>

                                        </Link>


                                    }</li>
                                    <li className="nav-item  ">
                                        {this.props.usuario &&
                                            <div className="bt22 btLogout" onClick={() => this.signOut()}>Logout</div>
                                        }
                                    </li>{!this.props.usuario &&

                                        <a className="nav-link bt22" alt="Carrinho" style={{ display: 'flex', flexDirection: 'row' }} onClick={() => this.openAlert('Cadastre-se primeiro!')} >

                                            <i className="fas fa-shopping-cart" ></i>
                                            <span className="itens">{this.props.total}</span>

                                        </a>
                                    }

                            </div>
                            <div>
                                <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                            </div>
                        </div>
                    </nav>
                </header>


            </div >
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (usuario) => dispatch(login(usuario)),
        onLogout: () => dispatch(logout()),
        onPesquisar: (pesq) => dispatch(pesquisar(pesq))
    }
}

const mapStateToProps = ({ produtos, user, pesquisa }) => {
    return {
        total: produtos.totalProdutos,
        usuario: user.email,
        chave: user.chave,
        nome: user.nome,
        pe: pesquisa.pesquisa

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLoja)