import axios from 'axios';
let baseURL = 'http://ftptrade.ddns.net:1505';

if (window.location.href.search('192.168.0.200') != -1) {baseURL= 'http://ftptrade.ddns.net:1505'}

	const api = axios.create({
		baseURL: baseURL
    //baseURL: 'http://ftptrade.ddns.net:3001/',
    //baseURL: 'http://187.86.147.193:5001/',
});
export default api;