import React, {Component} from 'react'
import './styles.css'
import {Formik, Field, Form, isInteger} from 'formik'
import Logo from '../../img/logos/ea.png'
import api from '../../services/api'
import util from '../../classes/util'
import InputMask from 'react-input-mask'
import Skeleton from '../../components/skeleton'
import Header from '../../components/header'

const estadoInicial = {
    chave: null,
    codigo: null,
    descricao: '',
    nivel: null, 
    grupo_web: false,
    grupo_empresa: null,
    id: null,
    loading: true,
    empresas: []
}

class AddFamilia extends Component {
    
    state = {
        ...estadoInicial
    }

    componentWillMount = async () => {
        window.scrollTo(0, 0)
        var id = this.props.match.params.id
        await this.setState({id})
        await this.loadData(id)
        await this.carregaEmpresas()
        await this.setState({loading: false})
    }

    loadData = async (produto) => {
        if(produto > 0){
            try{
                await api.get(`familiasadmin/${produto}`, {
                }).then(async res => {
                    await this.setState({chave: res.data.Chave, codigo: res.data.Codigo, descricao: res.data.Descricao, nivel: res.data.Nivel, grupo_web: res.data.grupo_web, empresa: res.data.grupo_empresa})
                })
            }catch(e){
                alert(e)
            }
        }
    }

    carregaEmpresas = async () => {
        await api.get(`empresas`, {
        }).then(response => {
            this.setState({empresas: response.data})
        })
        
    }

    inserirFamilia = async () => {
        if(this.state.id == 0){
            await api.post(`produtos`, {
                codigo: this.state.codigo,
                descricao: this.state.descricao,
                produto_web: this.state.produto_web,
                empresa: this.state.empresa
            }).then(response => {
                if(response.status === 204){
                    alert("Cadastro realizado corretamente!")
                }else{
                    alert("Erro no cadastro! Tente novamente!")
                }
            })
        }else{
            let file = new FormData()
            let outroFile = new FormData()
            outroFile.append('chave', util.completarZerosEsquerda(this.state.chave, 8))
            file.append('chave', util.completarZerosEsquerda(this.state.chave, 8))
            this.state.fotos.map(picture => (
                file.append('file', picture)
            ))

            try{
                await api.post(`criadiretorio`, {chave: util.completarZerosEsquerda(this.state.chave, 8)}, {
                }).then(res => {
                    api.post(`file`, file, {
                        headers: {
                            "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
                        }
                    }).then(res => {
                        api.put(`familiasall/${this.state.id}`, {
                            descricao: this.state.descricao,
                            grupo_web: this.state.grupo_web,
                            grupo_empresa: this.state.empresa

                        }).then(response => {
                            if(response.status === 204){
                                this.setState({pictures: []})
                                alert('Produto atualizado e Foto armazenada!')
                            }else{
                                alert('erro')
                            }
                        })
                    })
                })
            }catch(e){
                alert(e)
            }
            
        }
    }

    fileSelectedHandler = async event => {
        //console.log(event.target.files[0])
        await this.setState({fotos: [...this.state.fotos, event.target.files[0]]})
        console.log(this.state.fotos)
    }


    render(){
        const validations = []
        validations.push(this.state.codigo && this.state.codigo.trim().length >= 2)
        validations.push(this.state.nivel && this.state.nivel >= 1 && this.state.nivel <= 3)
        validations.push(this.state.fotos && this.state.fotos.length >= 1)
        //o formulário só será válido se todas as validações forem verdadeiras, com este reduce implementado
        const validForm = validations.reduce((t, a) => t && a)
        
        return (
            <div>

                {this.state.loading &&
                    <Skeleton />
                }

                {!this.state.loading &&
                <div>
                <section className="page-add">
                    
                    <Header />
                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-breadcrumb">
                                    <h2 style={{marginBottom: '3%', marginTop: '3%', textAlign: 'center'}}>{this.state.id == 0 ? 'Inserir Família' : 'Alterar Família'}</h2>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <div className="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik 
                                initialValues={{
                                    //codigo: null,
                                    //descricao: '',
                                    //id_seaport: null,
                                    //produto_web: false,
                                    //empresa: null,
                                    //status: '',
                                }}
                                onSubmit={async values => {
                                    await new Promise(r => setTimeout(r, 2000))
                                    this.inserirFamilia()
                                }}
                                >
                                    <Form className="contact-form">
                                    
                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 text-left">
                                            <label htmlFor="codigo">Código: </label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-9 text-left">
                                            <Field value={this.state.codigo} onChange={e => { this.setState({codigo: e.currentTarget.value})}} id="codigo" name="codigo" type="text" placeholder="Código" />
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 text-left">
                                            <label htmlFor="descricao">Nível: </label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-9 text-left">
                                            <Field type="number" min="1" max="3" name="nivel" id="nivel" value={this.state.nivel} onChange={async e => { await this.setState({nivel: e.currentTarget.value})}} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 text-left">
                                            <label htmlFor="codigo">Descrição: </label>
                                        </div>
                                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 text-left">
                                            <Field value={this.state.descricao} onChange={e => { this.setState({descricao: e.currentTarget.value})}} id="descricao" name="descricao" type="text" placeholder="Descrição" />
                                        </div>  
                                    </div>              
                                           
                                    <div className="row">
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 text-left">
                                            <label htmlFor="produto_web" className="form-check-label">Grupo web</label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-9">
                                            <Field  onClick={async (e) => { await this.setState({grupo_web: !this.state.grupo_web})} } name="grupo_web" id="grupo_web" type="checkbox"  checked={this.state.grupo_web} />
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 text-left">
                                            <label htmlFor="empresa" className="form-check-label">Empresa</label>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-9 text-left">
                                            <Field as="select" name="empresa" id="empresa" value={this.state.empresa} onChange={async e => { await this.setState({empresa: e.currentTarget.value}); alert(this.state.empresa)}}>
                                                {this.state.empresas.map(empresa => (
                                                    <option value={empresa.Chave} key={empresa.Chave}>{empresa.Nome_Fantasia}</option>
                                                ))}
                                                <option value={0} key={0}>Selecione...</option>
                                            </Field>
                                        </div>
                                        
                                        <input type="file" name="file" className="form-control-lg" accept="image/png" onChange={this.fileSelectedHandler}/>
                                        <div className="col-lg-12 text-right">
                                            <button disabled={!validForm} type="submit" style={validForm ? {} : {backgroundColor: '#eee', opacity: 0.3} } >Salvar</button>
                                        </div>

                                    </div>

                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            </div>
        )
    }
}

export default AddFamilia
