import React, { Component } from 'react'
import './styles.css'
import api from '../../services/api'

import HeaderLoja from '../../components/headerLoja'
import Header from '../../components/header'
import Rodape from '../../components/rodape'
import axios from 'axios'
import { CAMINHO_FOTOS } from '../../config'
import { server, showError } from '../../common'
import Util from '../../classes/util'
import CEP from 'cep-promise'
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, Field, Form } from 'formik'
import ReactLoading from 'react-loading';
import { connect } from 'react-redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import e from 'cors'
import ModalAlert from '../../components/ModalAlert'


const initialValues = {
    nome: '',
    login: '',
    senha: '',
    confirmaSenha: '',
    redireciona: false,
    cnpjcpf: '',
    endereco: '',
    cep: '',
    bairro: '',
    complemento: '',
    numero: '',
    cidade: '',
    uf: '',
    fone: '',
    codigo: null,
    check: false,
    redirect: false
}
class MinhaConta extends Component {


    state = {
        ...initialValues,
        produtos: [],
        teste: 1234,
        empresa: [],
        enderecoSite: '',
        movitens: [],
        alertAberto: false,
        textoErro: "",
        movimentacoes: [],
        enderecos: [],
        numeroSite: 0,
        cidadeSite: '',
        ufSite: '',
        foneSite: '',
        loading: true,

    }

    async componentDidMount() {
        window.scrollTo(0, 0)

        await this.carregaControle()
        await this.carregaCadastro()
        await this.carregarMovimentacoes()
        await this.carregarMovitens()
        await this.carregaEnderecos()
        await this.setState({ enderecoSite: this.state.empresa[0].Endereco })
        await this.setState({ numeroSite: this.state.empresa[0].Numero })
        await this.setState({ cidadeSite: this.state.empresa[0].Cidade })
        await this.setState({ ufSite: this.state.empresa[0].UF })
        await this.setState({ foneSite: this.state.empresa[0].Fone })
        if (!this.props.chave) {
            await this.setState({ redirect: true })
        }

    }

    trocarsenha = async () => {
        api.post('atualizarsenha', {
            senha: this.state.senha,
            login: this.state.login
        }).then((res) => {
            this.openAlert(JSON.stringify(res.data))
            this.enviaremail()
            this.openAlert('Alteração de senha realizada com sucesso')
        })
    }

    enviaremail = async () => {
        await api.post(`emailal`, {
            email: this.state.login,
            usuario: this.state.login,
            senha: this.state.senha
        }).then((res) => {
            this.openAlert('Email com as informações atualizadas foi encaminhado para seu email')
        })


    }

    carregarMovimentacoes = async () => {

        try {
            const res = await api.post(`/meuspedidos`, {
                pessoa: this.props.chave
            })
            await this.setState({ movimentacoes: res.data })



        } catch (e) {
            this.openAlert(e.response.data)
        }
    }

    openAlert = async (texto) => {
        await this.setState({ alertAberto: true })
        await this.setState({ textoErro: texto })
    }

    closeAlert = async () => {
        await this.setState({ alertAberto: false })
    }

    filtromovichave = (a, b) => {

        if (a == undefined) {
            return false

        } else {
            if (a.ChaveMov == b) {

                return true
            } else {
                return false
            }
        }

    }


    carregaEnderecos = async () => {
        try {
            const res = await api.post(`/pessoaenderecos`, {
                chave: this.props.chave
            })
            if (res.data) {
                await this.setState({ enderecos: res.data })
            } else {
                await this.setState({ enderecos: false })
            }
        } catch (e) {
            this.openAlert(e.response.data)
        }
        //console.log(this.state.enderecos)
    }

    carregarMovitens = async () => {
        await this.state.movimentacoes.map(async (f, indice) => {
            let c = f.Chave
            let vetor = []
            await api.post('movitensuser', {
                chavemov: c,
            }).then(
                async res => {
                    await vetor.push(res.data)
                }

            )
            let tudo = await this.state.movitens
            tudo.push(vetor)
            await this.setState({ movitens: tudo })
        })
        await this.setState({ loading: false })
    }


    simplificardescricao = (descricao) => {
        let descricao2 = descricao.split('Pedido Feito Pelo Site')
        return descricao2[1]
    }


    carregaControle = async () => {
        try {
            const res = await api.get(`/empresas`)
            await this.setState({ empresa: res.data })
        } catch (e) {
            this.openAlert(e.response.data)
        }

    }

    carregaCadastro = async () => {
        try {
            const res = await api.get(`/pessoas/1/${this.props.chave}`)

            await this.setState({ nome: res.data.Nome, cnpjcpf: res.data.Cnpj_Cpf, login: res.data.login, cep: res.data.Cep, endereco: res.data.Endereco, numero: res.data.Numero, complemento: res.data.Complemento, cidade: res.data.Cidade_Descricao, bairro: res.data.bairro, uf: res.data.UF, fone: res.data.Fone })
        } catch (e) {
            this.openAlert(e.response.data)
        }
    }

    teste = () => {
        const teste = 'Teste categoria'
        return teste
    }

    /*
    signup = async () => { 
        try{
           const res = await axios.get(`${server}/pessoas/${this.state.login}`)
           let cpf = res.data[0].contador
           if(cpf == 0){
            //await this.setState({codigo: proximo})
            await axios.post(`${server}/signup`, {
                Nome: this.state.nome,
                login: this.state.login,
                Cnpj_Cpf: this.state.cnpjcpf,
                senha: this.state.senha,
                confirmPassword: this.state.confirmaSenha,    
                endereco: this.state.endereco,
                numero: this.state.numero,
                complemento: this.state.complemento,
                cidade: this.state.cidade,
                uf: this.state.uf,
                bairro: this.state.bairro,
                cep: this.state.cep,
                inclusao: moment().format()
            })
            const resPes = await axios.get(`${server}/maxpessoa`)
            let proximo = resPes.data.chave
            await axios.put(`${server}/signup`, {
                codigo: proximo,
                chave: proximo
            })
            await axios.post(`${server}/email`, {
                email: this.state.login,
                usuario: this.state.login,
                senha: this.state.senha
            })
            alert('Usuário cadastrado!')
            this.setState({...initialValues})
           }else{
            alert("Falha no cadastro, já existe um cadastro com este usuário!")
           }
        }catch(e){
            this.openAlert(e.response.data)
        }
        
    }
    */
    buscarDados = async (cep) => {
        try {
            var obj = await CEP(cep)
            var bairro = obj.neighborhood
            var endereco = obj.street
            var cidade = obj.city
            var uf = obj.state
            await this.setState({ bairro, endereco, cidade, uf })
        } catch (e) {
            return this.openAlert("CEP não encontrado")
        }

    }

    redirecionar = async () => {
        await this.setState({ redireciona: true })
    }


    enviarDados = async () => {
        this.openAlert("Entrou aqui")
    }

    render() {

        const validations = []



        validations.push(this.state.senha == this.state.confirmaSenha)

        validations.push(this.state.senha.trim().length > 5)

        const validForm = validations.reduce((t, a) => t && a)

        return (
            <div>
                {this.state.redirect && <Redirect to={'/'} />}
                <HeaderLoja redirecionar={this.redirecionar} />
                {this.state.redireciona &&
                    <Redirect to={'/'} />
                }
                <div>
                    <ModalAlert texto={this.state.textoErro} alertAberto={this.state.alertAberto} closeAlert={this.closeAlert} />
                </div>
                <div className='row containerminhaconta'>
                    <span className='titulominhaconta'> Minha conta</span>
                    <div className='col-0 col-md-1 col-lg-1' />

                    <div className='col-12 col-md-3 col-lg-3'>
                        <span className='titutousuario'>Dados Do Usuário</span>
                        <div className='containerdadosusuario'>
                            <div className='casedadosusuario'>
                                <span>Nome:</span>
                                <input type='text' value={this.state.nome} /*onChange={(e) => console.log(e)}*/ />
                            </div>

                            <div className='casedadosusuario'>
                                <span>CPF/CNPJ:</span>
                                <input type='text' value={this.state.cnpjcpf} /*onChange={(e) => console.log(e)}*/ />
                            </div>

                            <div className='casedadosusuario'>
                                <span>Email:</span>
                                <input type='text' value={this.state.login} /*onChange={(e) => console.log(e)}*/ />
                            </div>
                            <span> Alterar senha?</span>
                            <Checkbox checked={this.state.check} onChange={(e) => this.setState({ check: e.target.checked })} />
                            {this.state.check &&
                                <div className='casedadosusuario'>
                                    <span>Nova Senha:</span>
                                    <input type='password' onChange={async (e) => await this.setState({ senha: e.target.value })} value={this.state.senha} />
                                </div>}
                            {this.state.check &&
                                <div className='casedadosusuario'>
                                    <span>Confirmar Senha:</span>
                                    <input type='password' onChange={async (e) => await this.setState({ confirmaSenha: e.target.value })} value={this.state.confirmaSenha} />
                                </div>}
                            {this.state.check &&
                                (<div className={validForm ? 'butaotrocarsenha' : 'butaotrocarsenhad'} onClick={validForm ? () => this.trocarsenha() : () => { }} > Confirmar troca de senha</div>)
                            }




                        </div>






                    </div>


                    <div className='col-12 col-md-7 col-lg-7'>
                        <div className='containermeuspedidos'>

                            <span className='titutousuario'>Meus Pedidos</span>
                            {this.state.loading &&
                                <div className='continerspinminhaconta'>



                                    <ReactLoading type={'spin'} color={'#001AFF'} height={100} width={105} />
                                    <span>Carregando dados...</span>
                                </div>
                            }
                            <div className='containerrealmeuspedidos'>
                                {!this.state.loading && this.state.movimentacoes.map((movimentacao, index) => (
                                    <div className="accordion " id="accordionExample" key={movimentacao.Chave}>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={`movimentacao2${movimentacao.Chave}`}>
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#movimentacao${movimentacao.Chave}`} aria-expanded="true" aria-controls={`movimentacao${movimentacao.Chave}`}>
                                                    {`Pedido Feito Pelo Site - ${moment(movimentacao.Data_saida).format('DD-MM-YYYY HH:mm')}  ${this.simplificardescricao(movimentacao.Dados_Adic)}`}
                                                </button>
                                            </h2>
                                            <div id={`movimentacao${movimentacao.Chave}`} className="accordion-collapse collapse" aria-labelledby={`movimentacao2${movimentacao.Chave}`} data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className='row'>
                                                        <div className='col-3 titulostabelaminhaconta'>Produto</div>
                                                        <div className='col-3  titulostabelaminhaconta'>Valor Unitário</div>
                                                        <div className='col-3  titulostabelaminhaconta'>Quantidade</div>
                                                        <div className='col-3  titulostabelaminhaconta'>Total</div>

                                                        {this.state.movitens[index] != undefined && this.state.movitens[index][0].filter((r) => this.filtromovichave(r, movimentacao.Chave)).map((elemento) => {
                                                            if (elemento != undefined) {

                                                                return (<div className='col-12 row contlistaprodutosminhaconta' key={elemento.ChaveMov} id={elemento.ChaveMov}>
                                                                    <div className='col-3 containerimg_spanminhaconta'>
                                                                        <div className='imgs2'>
                                                                            <img className='placeholderImg5' src={CAMINHO_FOTOS + Util.completarProduto(elemento.Codigo_Produto)} onLoad={(e) => {
                                                                                e.currentTarget.src = require('../../images/watermark.jpg')
                                                                                e.currentTarget.style.display = 'block';
                                                                            }} onError={(e) => {
                                                                                e.currentTarget.style.display = 'none';
                                                                            }} alt=''></img>
                                                                            <img src={CAMINHO_FOTOS + Util.completarProduto(elemento.Codigo_Produto)}
                                                                                onError={(e) => {
                                                                                    e.currentTarget.src = require('../../images/semimagem.png')
                                                                                }} className='img-fluid' alt='' />
                                                                        </div>

                                                                        <span>{elemento.Descricao}</span>
                                                                    </div>
                                                                    <div className='col-3'>{(elemento.Valor_Unitario).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                                                                    <div className='col-3'>{elemento.Quantidade} </div>
                                                                    <div className='col-3'>{(elemento.Valor_Total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>

                                                                </div>)
                                                            }

                                                        })}


                                                        <div className='row col-12 containerfooterminhaconta' >

                                                            <div className='col-3'>Sub-total: {(movimentacao.sub_total - movimentacao.ValorFrete).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div className='col-3'>Frete: {(movimentacao.ValorFrete).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div className='col-3'>Total: {(movimentacao.Total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>

                                                        </div>
                                                        <div className='enderecoItem'>
                                                            <span>{this.state.enderecos.filter(e => e.Chave == movimentacao.endereco_entrega).map(i => `Entregue em: ${i.Endereco} ${i.Numero} ${i.Complemento} (${i.bairro}) - ${i.Cidade_Descricao}/${i.UF}`)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>









                                ))}




                            </div>

                        </div>




                    </div>




                    <div className='col-0 col-md-1 col-lg-1' />


                </div>

                <Rodape />
            </div>
        )
    }
}

const mapStateToProps = ({ user }) => {
    return {
        nome: user.name,
        chave: user.chave
    }
}
export default connect(mapStateToProps, null)(MinhaConta)

//<textarea placeholder="Menssagem"></textarea>
