import React, {Component} from 'react'
import Styles from './styles.css'
import Modal from '@material-ui/core/Modal'
import {Formik, Field, Form} from 'formik'
import {Link, Redirect} from 'react-router-dom'
import {server, showError, showSuccess} from '../../common'
import {login} from '../../store/actions/empresa'
import axios from 'axios'
import {pesquisar} from '../../store/actions/pesquisa'
import {CAMINHO_FOTOS} from '../../config'
import {connect} from 'react-redux'
import api from '../../services/api'
import apiWeb from '../../services/apiWeb'

class Lojas extends Component {
    
    state = {
        open: false,
        empresas: [],
        empresa: null,
        nomeEmpresa: null,
        redirect: false
    }

    componentDidMount = () => {
        this.loadEmpresas()
        //console.log(window.innerWidth)
    }

    loadEmpresas = async () => {
        try{
            await api.get(`empresas`).then(
                async res =>  await this.setState({empresas: res.data}),
                async res => await apiWeb.get(`empresas`).then( 
                    async res =>  await this.setState({empresas: res.data}),
                    async res =>  alert('erro, tente novamente!')     
                )
            )
           
        }catch(e){
            showError(e)
        }       
    }

    carregarImagem = (imagem) => {

        const caminhoAbs = `${CAMINHO_FOTOS}/${imagem}.png`
        if(caminhoAbs){
          //Alert.alert("Teste", `${caminhoAbs}`)
            return caminhoAbs
        }else{
            return 'http://192.168.15.150/severo/assets/produtos/sem_imagem.png'
        }  
    }

    setEmpresa = async (nome, empresa) => {
        await this.setState({empresa, nomeEmpresa: nome})
        await this.props.onLogin({...this.state})
        await this.setState({redirect: true})
    }

    render(){

        if(this.state.redirect) {
            return <Redirect to={`/loja/${this.state.empresa}`} />
        }

        return(
            <section className="section-contact">
                <div className="product-filter">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                         <div className="section-title">
                               <h1>Lojas</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.state.empresas.map(empresa => (
                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-12 espacoAbaixo text-center" key={empresa.Chave}>  
                            <button>
                                <img 
                                    onClick={() => this.setEmpresa(empresa.Dados_Complementares, empresa.Chave)} 
                                    src={this.carregarImagem(window.innerWidth <= 572 ? empresa.imagem_app_mobile : empresa.imagem_app)} 
                                    className="img alturaLoja" 
                                />
                            </button>  
                        </div>
                    ))}
                </div>   
            </section>            
        )
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onLogin: (empresa) => dispatch(login(empresa))
        //onClassificado: (classificado) => dispatch(grupoClassificado(classificado))
    }
}


export default connect(null, mapDispatchToProps)(Lojas)
