import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../components/header'
import './styles.css'

class Inicio extends Component {
    
    render(){
        
        return(
            <div>
                <Header />
                <div className="product-filter">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title">
                                <h3>Espaço acervo - Administrador</h3>
                            </div>
                            <ul className="product-controls">
                            <Link to={{pathname: `/admin/produtos`}}><li id="itemMenu">Produtos</li></Link>
                            <Link to={{pathname: `/admin/familias`}}><li id="itemMenu">Famílias</li></Link>
                            <Link to={{pathname: `/admin/promocoes`}}><li id="itemMenu">Promoções</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}

export default Inicio